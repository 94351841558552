import { getContactListFail, getContactListLoading, getContactListSuccess, getDistrictListFail, getDistrictListLoading, getDistrictListSuccess, getDtaListFail, getDtaListLoading, getDtaListSuccess, getFormListFail, getFormListLoading, getFormListSuccess, getGalleryListFail, getGalleryListLoading, getGalleryListSuccess, getLatestInfoFail, getLatestInfoLoading, getLatestInfoSuccess, getNewsListFail, getNewsListLoading, getNewsListSuccess, getOwnerInfoFail, getOwnerInfoLoading, getOwnerInfoSuccess, getPrideListFail, getPrideListLoading, getPrideListSuccess, getVideoListFail, getVideoListLoading, getVideoListSuccess, getVisitiorsSuccess } from "../redux/slices/homeSlice"
import axiosConfig from "../axios_config/axios_instance"
import { getRegisterClubListFail, getRegisterClubListLoading, getRegisterClubListSuccess } from "../redux/slices/registerClubSlice"




// ------------------------------------------ HOME APIS -------------------------------------------------


export const getPrideList=()=>async(dispatch)=>{
    try {
        dispatch(getPrideListLoading())
        const  {data} = await axiosConfig.get("/ota-prides-list?page=0&page_size=1000")
        dispatch(getPrideListSuccess(data?.otaPridesDTOList))
    } catch (error) {
        dispatch(getPrideListFail())
    }
}   


export const getLatestInfo=()=>async(dispatch)=>{
    try {
        dispatch(getLatestInfoLoading())
        const  {data} = await axiosConfig.get("/notice-list?page=0&page_size=1000")
        dispatch(getLatestInfoSuccess(data?.ota_notices_list))
    } catch (error) {
        dispatch(getLatestInfoFail())
    }
}   


export const getGalleryList=()=>async(dispatch)=>{
    try {
        dispatch(getGalleryListLoading())
        const  {data} = await axiosConfig.get("/gallery-list?page=0&page_size=1000")
        dispatch(getGalleryListSuccess(data?.ota_image_list))
    } catch (error) {
        dispatch(getGalleryListFail())
    }
}   

export const getOwnerInfo=()=>async(dispatch)=>{
    try {
        dispatch(getOwnerInfoLoading())
        const  {data} = await axiosConfig.get("/get-owner-info?owner_id=1")
        dispatch(getOwnerInfoSuccess(data?.ownerInfoDTO))
    } catch (error) {
        dispatch(getOwnerInfoFail())
    }
}   


// ----------------------------------- DTA API -----------------------------------

export const getDistrictAssociationList=()=>async(dispatch)=>{
    try {
        dispatch(getDistrictAssociationList())
        const  {data} = await axiosConfig.get("/district-association-list?page=0&page_size=10")
        dispatch(getDistrictAssociationList(data?.districtAssociationDTOList))
    } catch (error) {
        dispatch(getDistrictAssociationList())
    }
}   


// ----------------------------------- FORM LIST API -----------------------------------

export const getFormList=()=>async(dispatch)=>{
    try {
        dispatch(getFormListLoading())
        const  {data} = await axiosConfig.get("/ota-form-list?page=0&page_size=1000")
        dispatch(getFormListSuccess(data?.ota_form_list))
    } catch (error) {
        dispatch(getFormListFail())
    }
}   



// ----------------------------------- DISTRICT LIST API -----------------------------------

export const getDistrictList=()=>async(dispatch)=>{
    try {
        dispatch(getDistrictListLoading())
        const  {data} = await axiosConfig.get("/city-list?page=0&page_size=100")
        dispatch(getDistrictListSuccess(data?.city_list))
    } catch (error) {
        dispatch(getDistrictListFail())
    }
}   





// ----------------------------------- CONTACT LIST API -----------------------------------

export const getContactList=()=>async(dispatch)=>{
    try {
        dispatch(getContactListLoading())
        const  {data} = await axiosConfig.get("/contact-us-list?page=0&page_size=10")
        dispatch(getContactListSuccess(data?.contactUsDTOList))
    } catch (error) {
        dispatch(getContactListFail())
    }
} 

//--------------------------------------- VIDEOS LIST API -----------------------------

export const getVideoList=()=>async(dispatch)=>{
    try {
        dispatch(getVideoListLoading())
        const  {data} = await axiosConfig.get("/video-list?page=0&page_size=10000")
        dispatch(getVideoListSuccess(data?.ota_videos_lisst))
    } catch (error) {
        dispatch(getVideoListFail())
    }
} 


//--------------------------------------- DTA LIST API -----------------------------

export const getRegisterClub=(payload)=>async(dispatch)=>{
    try {
        dispatch(getRegisterClubListLoading())
        const  {data} = await axiosConfig.get(`/ota-user-club-list?page=0&page_size=100&city_id=${payload}`)
        dispatch(getRegisterClubListSuccess(data?.ota_club_list))
    } catch (error) {
        dispatch(getRegisterClubListFail())
    }
} 







//--------------------------------------- R LIST -----------------------------

export const getDtaList=()=>async(dispatch)=>{
    try {
        dispatch(getDtaListLoading())
        const  {data} = await axiosConfig.get("/district-association-list?page=0&page_size=10000")
        dispatch(getDtaListSuccess(data?.districtAssociationDTOList))
    } catch (error) {
        dispatch(getDtaListFail())
    }
} 



// ------------------------------------ NEWS LIST -----------------------------------

export const getNewsList=()=>async(dispatch)=>{
    try {
        dispatch(getNewsListLoading())
        const {data} = await axiosConfig.get(`/news-image-list?page=0&page_size=5000`)
        dispatch(getNewsListSuccess(data?.news_image_list))
    } catch (error) {
        dispatch(getNewsListFail())
    }
}



//------------------------------- GET VISITORS -------------------------------

export const getVisitors=()=> async(dispatch)=>{
    try {
        const {data} = await axiosConfig.get(`/get-total-visitors`) 
        dispatch(getVisitiorsSuccess(data?.total_visitors))
    } catch (error) {
    }
}
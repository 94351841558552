import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBlackBeltRegistredPlayers, getColorBeltRegistredPlayers, updateBlackBeltRegistredPlayers, updateColorBeltRegistredPlayers } from '../../service/apiService'
import { BlackBeltHolderIdCardPrint, ColorBeltHolderIdCardPrint } from './ColorBeltCertificate'
import InfiniteScroll from 'react-infinite-scroll-component'
import {ReactComponent as SearchIcon} from "../../assets/icons/searchicon.svg"
const PlayerList = () => {
 const router=  useParams()
 const dispatch = useDispatch()
 const {councilMembers,colorBeltRegistredPlayers,blackBeltRegistredPlayers,colorBeltRegistredPlayersLoading,blackBeltRegistredPlayersLoading,promotionTestList,promotionTestListLoading,totalpromotionTestList,totalCouncilMember,totalcolorBeltRegistredPlayers,totalblackcolorBeltRegistredPlayers,medaLists,eventResults,totalEventResult,coachProfiles,TotalCoachProfiles,refreeProfiles,TotalRefreeProfiles,technicalCommitiesList,TotaltechnicalCommitiesList,calendarEventsList}=useSelector((state)=>state.about)
 const selectedDistrictRegistredPlayer = router?.status?.split("&")[1]
  const selectedTab = router?.status?.split("&")[0]
  const [colorBeltpage, setcolorBeltpage] = useState(1)
  const [blackBeltPage, setblackBeltPage] = useState(1)
  const [searchValue, setSearchValue] = useState("")
 useEffect(() => {
    if (selectedTab==="0") {
      dispatch(getColorBeltRegistredPlayers(1,10,selectedDistrictRegistredPlayer))
    } else {
      dispatch(getBlackBeltRegistredPlayers(1,10,selectedDistrictRegistredPlayer))
    }
  },[selectedTab,selectedDistrictRegistredPlayer,dispatch])

const loadMore = async () => {
  if (selectedTab === "0") {
    if (colorBeltRegistredPlayers?.length < totalcolorBeltRegistredPlayers && !colorBeltRegistredPlayersLoading) {
         dispatch(updateColorBeltRegistredPlayers(colorBeltpage + 1,10,selectedDistrictRegistredPlayer,searchValue))
        setcolorBeltpage(colorBeltpage+1)
    }
   else if (selectedTab === "1") {
      if (blackBeltRegistredPlayers?.length < totalblackcolorBeltRegistredPlayers && !blackBeltRegistredPlayersLoading) {
        dispatch(updateBlackBeltRegistredPlayers(blackBeltPage + 1,10,selectedDistrictRegistredPlayer,searchValue))
        setblackBeltPage(blackBeltPage + 1)
      }
    }
  }
  }
  const handleSearch = (e) => {
    setSearchValue(e.target.value)
    if (selectedTab==="0") {
      dispatch(getColorBeltRegistredPlayers(1, 10, selectedDistrictRegistredPlayer, e.target.value))
      setcolorBeltpage(1)
    } else {
      dispatch(getBlackBeltRegistredPlayers(1,10,selectedDistrictRegistredPlayer,e.target.value))
      setblackBeltPage(1)
    }
}
  return (
   <Template>
     <p className="text-[50px] max-md:text-[34px] text-center py-5   font-['Quicksand'] text-[#002642] font-bold">{selectedTab==="0"?"Color Belt Athletes":"Black Belt Holder"}</p>
 {selectedTab==="0"?   <div className='w-[40%] m-auto max-md:px-3 max-md:w-full'>
 <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input value={searchValue} onChange={handleSearch} type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Athelete Name"  />
    </div>
        
 </div>:<div className='w-[40%] m-auto max-md:w-full max-md:px-3'>
 <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
    <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
            </svg>
        </div>
        <input value={searchValue} onChange={handleSearch} type="search" id="default-search" class="block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Athelete Name"  />
    </div>
        
 </div>}
     {
        selectedTab==="0"?
     <div className="md:p-7 my-4  max-md:p-0 md:w-[100%] ">
          <div className="flex md:hidden flex-col gap-3 px-3">
          {colorBeltRegistredPlayers?.length ?                    <>           
            <InfiniteScroll
                dataLength={colorBeltRegistredPlayers?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden"}}
                next={loadMore}
                hasMore={true}
                loader={colorBeltRegistredPlayersLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                                >
        {
            colorBeltRegistredPlayers?.map((player,i)=>
    <div className={`flex gap-4 ${i%2===0?"bg-white":"bg-[#E7E7E7]"} w-full p-2 rounded-md shadow-md`}>
                    <div className='flex flex-col gap-1 w-full'>
                        <div className='flex justify-end items-end w-full'>
         <ColorBeltHolderIdCardPrint details={player}/>
                  </div>
                  <div className='flex gap-2'>
                    <div className='flex flex-col gap-1 w-[50%] items-start justify-start'>
                      <p className='text-[12px] text-[#002642] font-bold font-["Quicksand"]'>OTA ID No:</p>
                      <p className='text-[12px]  text-[#002642] font-bold font-["Quicksand"]'>Athlete Name:</p>
                      <p className='text-[12px] text-[#002642] w-[50%]  font-bold font-["Quicksand"]'>Gender:</p>
                      <p className='text-[12px] text-[#002642] w-[50%]  font-bold font-["Quicksand"]'>Club Name:</p>  
                    </div>
                    <div className='flex flex-col gap-1 w-[50%] items-start justify-start'>
                    <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{player?.id_number}</p>
                      <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{player?.athlete_name?.slice(0)}</p>
                      <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{ player?.gender}</p>
                        <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{ player?.club_name}</p>
                    </div>
                  </div>
    </div>
    {/* <div className='flex flex-col gap-1 w-[60%]'>
        <div className='flex justify-between w-full items-center'>
          <p className='text-[20px] text-[#444444] font-normal font-["Quicksand"]'>{player?.id_number}</p>
         <PrintSvg />

        </div>
        <p className='text-[20px] text-[#444444] font-normal font-["Quicksand"]'>{player?.gender}</p>
                        <p className='text-[20px] text-[#444444] font-normal font-["Quicksand"]'>{ player?.club_name}</p>
    </div> */}
    </div>
            )
        }
                  </InfiniteScroll>
                  </> : <div className='w-full h-[100px] flex justify-center items-center'>
                          <span className='text-sm text-black/60'>No Registered Players found</span>
        </div>}
            </div>
        <div className="flex max-md:hidden flex-col justify-start items-start self-stretch flex-grow overflow-hidden ">
          <div className="flex flex-col  items-start self-stretch flex-grow min-h-[50vh] justify-between w-full">
            {
             <>
             
            {colorBeltRegistredPlayers?.length ? (
              <>
                          <div className="flex flex-col w-full">
                          <InfiniteScroll
                dataLength={colorBeltRegistredPlayers?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden",width:"100%"}}
                next={loadMore}
                hasMore={true}
                loader={colorBeltRegistredPlayersLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                                >
                    <table className='w-full'>
                    <thead>
          <tr className="">
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
              Sr no
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
              OTA ID No.
            </th>
            
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3  ">
            Athlete Name
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-11 py-3 text-center w-0">
            Gender
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0">
            Club Name
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0 ">
            ID Card
            </th>
           
          </tr>
                    </thead>
                              <tbody className="">
          
                        {colorBeltRegistredPlayers?.map((category,i) => (
                           <tr   key={category.color_belt_id} className={`group/item  ${i%2===0?"bg-white":"bg-[#e7e7e7]"}    transition-all border-b border-black/5 cursor-pointer`}>    
                           <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">{i+1}</td>
                           <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">{category?.id_number}</td>
                          
                           <td className="   text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  leading-tight capitalize  text-center text-[#444444]">{category?.athlete_name}</td>
                           <td className=" text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap leading-tight capitalize p-4  text-[#444444] text-center">{category?.gender}</td>
                           <td className="  text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap text-center  leading-tight  capitalize text-[#444444] ">{category?.club_name}</td>
                           <td className="text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap leading-tight capitalize text-[#444444] items-center text-center">
                           <ColorBeltHolderIdCardPrint details={category} /></td>
                           </tr>
                        ))}
                      </tbody>
                    </table>
                                </InfiniteScroll>
                  </div>
              </>
            ) : (
              <div className="flex justify-center items-center h-[10vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Registered Player Found
                </span>
              </div>
            )}
            </>
            }
            {
                  colorBeltRegistredPlayersLoading &&<span className="text-lg text-[#808080]">Loading.....</span>
          }
          </div>
        </div>
      
      </div>:
          <div className="md:p-7 my-4  max-md:p-0 md:w-[100%] ">
            <div className='hidden max-md:flex flex-col gap-3 px-3'>
              {blackBeltRegistredPlayers?.length ? <>    
                <InfiniteScroll
                dataLength={blackBeltRegistredPlayers?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden"}}
                next={loadMore}
                hasMore={true}
                loader={blackBeltRegistredPlayersLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                >      
         {
            blackBeltRegistredPlayers?.map((player,i)=>
            <div className={`flex gap-4 ${i%2===0?"bg-white":"bg-[#E7E7E7]"} w-full p-2 rounded-md shadow-md`}>
                    <div className='flex flex-col gap-1 w-full'>
                        <div className='flex justify-end items-end w-full'>
         <BlackBeltHolderIdCardPrint details={player} />
                        </div>
                        <div className='flex gap-2'>
                    <div className='flex flex-col gap-1 w-[50%] items-start justify-start'>
                      <p className='text-[12px] text-[#002642] font-bold font-["Quicksand"]'>OTA ID No:</p>
                      <p className='text-[12px] text-[#002642] font-bold font-["Quicksand"]'>Athlete Name:</p>
                      <p className='text-[12px] text-[#002642]   font-bold font-["Quicksand"]'>Gender:</p>
                      <p className='text-[12px] text-[#002642] font-bold font-["Quicksand"]'>Dan Grade:</p>
                      <p className='text-[12px] text-[#002642] font-bold font-["Quicksand"]'>Kukkiwon Dan Cert  No:</p>
                      <p className='text-[12px] text-[#002642] font-bold font-["Quicksand"]'>Issuance Date:</p>
                   
                    </div>
                    <div className='flex flex-col gap-1 w-[50%] items-start justify-start'>
                    <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{player?.ota_id_number}</p>
                    <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{ player?.athlete_name?.slice(0)}</p>
                      <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{ player?.gender}</p>
                      <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{player?.dan_grade}</p>
                      <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{player?.kukkiwn_cert_no}</p>
                      <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{ player?.issuance_date}</p>
                    </div>
                  </div>
    </div>
    {/* <div className='flex flex-col gap-1 w-[60%]'>
        <div className='flex justify-between w-full items-center'>
          <p className='text-[20px] text-[#444444] font-normal font-["Quicksand"]'>{player?.id_number}</p>
         <PrintSvg />

        </div>
        <p className='text-[20px] text-[#444444] font-normal font-["Quicksand"]'>{player?.gender}</p>
                        <p className='text-[20px] text-[#444444] font-normal font-["Quicksand"]'>{ player?.club_name}</p>
    </div> */}
    </div>
            )
                  }
                  {
                  blackBeltRegistredPlayersLoading &&<span className="text-lg text-[#808080]">Loading.....</span>
          }
                  </InfiniteScroll>    
                  
                  </> : <div className='w-full h-[100px] flex justify-center items-center'>
                          <span className='text-sm text-black/60'>No Registered Players found</span>
        </div>}
            </div>
      <div className="flex max-md:hidden flex-col justify-start items-start self-stretch flex-grow overflow-hidden ">
        <div className="flex flex-col  items-start self-stretch flex-grow min-h-[50vh] justify-between w-full">
          {
            
           <>
           
          {blackBeltRegistredPlayers?.length ? (
            <>
                          <div className="flex flex-col w-full">
                          <InfiniteScroll
                dataLength={blackBeltRegistredPlayers?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden"}}
                next={loadMore}
                hasMore={true}
                loader={blackBeltRegistredPlayersLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                                >
                  <table>
                  <thead>
        <tr className="">
          <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
            Sr no
          </th>
          <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
            OTA ID No.
          </th>
          
          <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3  ">
          Athlete Name
          </th>
          <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-11 py-3 text-center w-0">
          Gender
          </th>
          <th className="border-b  border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0">
          Dan Grade
          </th>
          <th className="border-b  border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0">
          Kukkiwon Dan Cert  No.
          </th>
          <th className="border-b  border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0">
          Issuance Date
          </th>
          <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0 ">
          ID Card
          </th>
         
        </tr>
                  </thead>
                              <tbody className="">
                 
                      {blackBeltRegistredPlayers?.map((player,i) => (
                         <tr   key={player.black_belt_id} className={`group/item  ${i%2===0?"bg-white":"bg-[#e7e7e7]"}    transition-all border-b border-black/5 cursor-pointer`}>    
                         <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">{i+1}</td>
                         <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">{player.ota_id_number} </td>
                        
                          <td className="   text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  leading-tight capitalize  text-center text-[#444444]">{player.athlete_name}</td>
                         <td className=" text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap leading-tight capitalize p-4  text-[#444444] text-center">{player.gender}</td>
                         <td className="  text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap text-center  leading-tight  capitalize text-[#444444] ">{player.dan_grade}</td>
                         <td className="  text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  text-center leading-tight  capitalize text-[#444444] ">{player.kukkiwn_cert_no}</td>
                         <td className="  text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  text-center leading-tight  capitalize text-[#444444] ">{player.issuance_date}</td>
                         <td className="text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap leading-tight capitalize text-[#444444] items-center text-center">
                         <BlackBeltHolderIdCardPrint details={player} /></td>
                         </tr>
                      ))}
                                  
                    </tbody>
                              </table>
                              </InfiniteScroll>
                </div>
            </>
          ) : (
            <div className="flex justify-center items-center h-[10vh] w-full">
              <span className="text-center w-full text-xl text-[#7b8794]">
                No Registered Players Found
              </span>
            </div>
          )}
          </>
          }
                {
                  blackBeltRegistredPlayersLoading &&<span className="text-lg text-[#808080]">Loading.....</span>
          }
        </div>
      </div>
    
    </div>
     }
   </Template>
  )
}

export default PlayerList
import React, { useEffect } from 'react'
import {HashRouter as Router,Route,Routes} from "react-router-dom"
import * as routesKey from "../constants/routes"
import * as pages from "../pages"

const AppRouter=()=>{
    return(
        <Router>
       
    <Routes>
    <Route path={routesKey.HOME} element={<pages.Home />}  />
    <Route path={routesKey.LATESTINFO} element={<pages.LatestInformation />}  />
    <Route path={routesKey.CONTACT} element={<pages.Contact />}  />
    <Route path={routesKey.DTA} element={<pages.Dta />}  />
    <Route path={routesKey.REGISTER_CLUB} element={<pages.RegisterClub />}  />
    <Route path={routesKey.ABOUT} element={<pages.About />}  />
    <Route path={routesKey.VIDEOLIST} element={<pages.VideoList />}  />
    <Route path={routesKey.ONLINEREGISTRATION} element={<pages.OnlineRegistration />}  />
    <Route path={routesKey.COLORBELTSTRIP} element={<pages.ColorbeltStrip />}  />
    <Route path={routesKey.CHAMPIONSHIPSTRIP} element={<pages.ChampionshipStrip />}  />
    <Route path={routesKey.ATHLETEREGESTRATIONSTRIP} element={<pages.AthlateRegistrationStrip />}  />
    <Route path={routesKey.COMPETITION} element={<pages.Competition />}  />
    <Route path={routesKey.NEWSLIST} element={<pages.NewsList />}  />
    <Route path={routesKey.PLAYERLIST} element={<pages.PlayerList />}  />
    <Route path={routesKey.PROMOTIONTESTLIST} element={<pages.PromotionTestList />}  />
    <Route path={routesKey.EVENTLIST} element={<pages.EventList />}  />
    <Route path={routesKey.COACHESLIST} element={<pages.CoachesList />}  />
    <Route path={routesKey.REFREEPROFILES} element={<pages.RefereeList />}  />
    <Route path={routesKey.TECHNICALCOMMITIESLIST} element={<pages.TechnicalCommitiesList />}  />

        </Routes>
            </Router>
    )
}

export default AppRouter
import { Autoplay, Navigation, Pagination } from 'swiper';
import "./swiperCss.css"
import { useSelector } from 'react-redux';
import Slider from "react-slick"
import { useEffect } from 'react';
export const GallerySlider=()=>{
  const {galleryList} = useSelector(state=>state.home)
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true, 
    variableWidth: false, // Set to true to allow variable-width slides
    centerPadding: '0', //
  };
  var mobileSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0'
  };

  return (
    <>
      <div className='w-full md:block hidden'>
    <Slider {...settings} className='w-full md:block hidden'>
        {
              galleryList?.map((gallery,i) => (
               <div className='w-[33%] h-[600px] flex justify-center items-center slider_element bg-white rounded-[29px] shadow-xl py-10 '>
              <div className='p-2 h-full w-full '>
              <div className='h-full w-full border-[1.22px]  flex flex-col justify-center items-center rounded-[28px] border-[#154162] '>
                   <img alt='gallery_slide' src={gallery?.ota_image} className='h-full rounded-[10px] w-full'/>
                          </div>
              </div>
            </div>
            ))
           }
        </Slider>
      </div>
      <div className='w-full max-md:block hidden'>
    <Slider {...mobileSettings} className='w-full hidden max-md:block'>
        {
              galleryList?.map((gallery,i) => (
               <div className='w-full h-[540px]  bg-white rounded-[29px] shadow-xl '>
              <div className='p-2 h-full w-full '>
              <div className='h-full w-full border-[1.22px]  flex flex-col justify-center items-center rounded-[28px] border-[#154162] '>
                   <img alt='gallery_slide' src={gallery?.ota_image} className='h-full rounded-[10px] w-full'/>
                          </div>
              </div>
            </div>
            ))
           }
      </Slider>
      </div>
    </>
    )
  }

  export default GallerySlider;
import {createSlice} from "@reduxjs/toolkit"

const registerClubSlice=createSlice({
    name:"registerClub",
    initialState:{
      registerClubs:[],
      isLoading:false,
      championshipData:"",
      colorbeltPromotionData:"",
      athlateRegistrationData:""
    },
    reducers:{
        getRegisterClubListLoading:(state,{payload})=>{
              state.isLoading=false
        },
        getRegisterClubListSuccess:(state,{payload})=>{
              state.isLoading=true
              state.registerClubs=payload
        },
        getRegisterClubListFail:(state,{payload})=>{
              state.isLoading=true
        },
        setChamionshipRegisterFormData:(state,{payload})=>{
           state.championshipData=payload
        },
        setColorbeltPromotionFormData:(state,{payload})=>{
          state.colorbeltPromotionData=payload
        },
        setAthleteRegistrationFormData:(state,{payload})=>{
          state.athlateRegistrationData=payload
        },
    }
})

export default registerClubSlice.reducer
export const {getRegisterClubListFail,setAthleteRegistrationFormData,getRegisterClubListLoading,getRegisterClubListSuccess,setChamionshipRegisterFormData,setColorbeltPromotionFormData} = registerClubSlice.actions
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'

const SuccessModal = ({closeModal}) => {
    const handleClose=()=>{

    }
  return (
    <Fragment>
     <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
      <div class="relative transform overflow-hidden rounded-[20px] bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
          <button onClick={closeModal} className='flex justify-end w-full'> <svg width="45" height="45" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="33.5" cy="33.5" r="33.5" fill="#D9D9D9"/>
<path d="M23 22L45 44" stroke="#002642" stroke-width="2" stroke-linecap="round"/>
<path d="M45 22L23 44" stroke="#002642" stroke-width="2" stroke-linecap="round"/>
</svg>
</button>
          <div class="sm: justify-center mb-3 items-center flex flex-col w-full sm:items-start">
           <div className='flex justify-center mb-3 w-full'>
           <svg width="100px" height="100px" viewBox="0 0 147 147" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="73.5" cy="73.5" r="73.5" fill="#002642"/>
<path d="M41.8662 75.5411L59.7408 94.6099C60.0123 94.8996 60.4692 94.9087 60.7521 94.6302L105.113 50.9675" stroke="white" stroke-width="4.55067" stroke-linecap="round"/>
</svg>

           </div>
            <div class="mt-3 flex flex-col gap-4 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <p class="text-[32px] font-bold text-center leading-6 text-[#002642] font-['Quicksand']" id="modal-title">Thank You!</p>
              <p class="text-[24px] text-center font-medium leading-6 text-[#002642] font-['Quicksand']" id="modal-title">Your application was successfully submitted.</p>
              <p class="text-[18px] font-medium leading-6 text-[#002642] font-['Quicksand']" id="modal-title   text-center" style={{textAlign:"center"}}>Download & print your form submission strip </p>
             
            </div>
          </div>
        </div>
      
      </div>
    </div>
  </div>
</div>

    </Fragment>
  )
}

export default SuccessModal
import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import { useDispatch, useSelector } from 'react-redux'
import { getNewsList } from '../../service/api'

const NewsList = () => {
  const {prideList,latestInfos,videoList,galleryList,ownerInfo,newsList} = useSelector(state=>state.home)
  const [showNews, setshowNews] = useState(0)
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getNewsList())
  },[])

  return (
    <Template>
             
             <div className='p-7 max-md:p-5 flex flex-col gap-3'>
      <p className='text-[#002642] max-md:text-[34px]  text-[50px] font-bold  font-["Quicksand"] text-center'>News</p>
       <p className='text-[#444444] max-md:text-[14px] text-[24px] font-["Quicksand"] text-center'>Get top and latest taekwondo News - Read Breaking taekwondo News and taekwondo News Headlines</p>

<div className='flex justify-between max-md:h-[300px] h-[700px] items-center  w-full'>
  <div className='h-full w-[100%] rounded-'>
  <img alt='news' src={newsList[showNews]?.news_image} className='h-[100%] rounded-  w-full'/>
     
  </div>
 

</div>
<div className='w-full'>

<div className='w-full h-full flex gap-6 max-md:gap-2 flex-wrap'>
{
        newsList.map((news,i)=>
        <img onClick={()=>setshowNews(i)} key={i} alt='' src={news?.news_image}  className='h-[400px] max-md:h-[100px] cursor-pointer rounded-lg max-md:w-[31%] w-[32%]'/>
        )
      }
</div>
</div>

    </div>
    </Template>
  )
}

export default NewsList
import { Dialog, Transition } from "@headlessui/react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import YouTube from "react-youtube";

export const LiveVideoModal = ({openLiveModal,setOpenLiveModal,id}) => {

  const dispatch=useDispatch()
    const opts= {
      height: '100%',
      width: '100%',
      playerVars: {
        autoplay: 1,
      },
    };

  


  const closeModal = () => {
    setOpenLiveModal(false)
  }



    return (
      <>
      <Transition
          appear
          show={openLiveModal}
          as={Fragment}
          className="fixed inset-0 w-full  max-w-full mx-auto z-50 bg-black/10 backdrop-blur-sm"
        >
          <Dialog as="div" className=" z-50 " onClose={()=>{}}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div
                className=" inset-0 w-[65%] m-auto  bg-black/10  opacity-10"
                aria-hidden="true"
              />
            </Transition.Child>
            <div className="fixed inset-0">
              <div className="flex min-h-full items-center justify-center text-center w-full">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0 opacity-100 scale-100"
                  leave="transition ease-in duration-200 transform"
                  leaveFrom="translate-y-0 opacity-100 scale-100"
                  leaveTo="translate-y-full opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full md:max-w-[65%] max-md:max-w-full overflow-y-auto transform rounded-2xl  text-left align-middle shadow-xl transition-all  mx-4">
                    <div className="w-full flex flex-col gap-8">       
                    <svg onClick={closeModal} className="self-end cursor-pointer" width="30px" height="30px" viewBox="0 0 0.375 0.375" version="1.1" id="cross" xmlns="http://www.w3.org/2000/svg"><path d="M0.066 0.032 0.188 0.153l0.121 -0.121A0.023 0.023 0 0 1 0.325 0.025a0.025 0.025 0 0 1 0.025 0.025 0.023 0.023 0 0 1 -0.007 0.017L0.221 0.188l0.122 0.122A0.023 0.023 0 0 1 0.35 0.325a0.025 0.025 0 0 1 -0.025 0.025 0.023 0.023 0 0 1 -0.017 -0.007L0.188 0.222l-0.121 0.121A0.023 0.023 0 0 1 0.05 0.35a0.025 0.025 0 0 1 -0.025 -0.025 0.023 0.023 0 0 1 0.007 -0.017L0.154 0.188 0.032 0.065A0.023 0.023 0 0 1 0.025 0.05a0.025 0.025 0 0 1 0.025 -0.025c0.006 0 0.012 0.003 0.016 0.007z"/></svg>
                  <div className='w-full max-md:w-full h-[450px] max-md:h-[300px]'>                
      { <div className='flex w-[100%] h-[450px] max-md:h-[300px] ' >
     {  id?   <YouTube videoId={id} opts={opts} className='w-[100%] h-[450px] max-md:h-[300px] max-md:max-h-full'  />:null}
            </div>}
              </div>
              </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
   </>
    )
  }
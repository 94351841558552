import React from 'react'
import { useSelector } from 'react-redux'

const MobileView = () => {
 const {dtaList} =useSelector(state=>state.home)

  return (
    <div className='md:hidden'>
        {
            dtaList?.map((dta,i)=>
        <div className='py-4 ' key={i}>
                {/* {data map here} */}
                 <div className='flex flex-col gap-7 '>
                    <div className='flex w-full  flex-col gap-3 '>
                        <div className='flex gap-3 items-center'>
                            <img src={dta?.logo} alt='' className='h-[50px] w-[50px] rounded-full '/>
                        <p className='text-[#002642] font-["Quicksand"] font-bold text-[24px] '> {dta?.city_name}</p>
                            </div>
                            <p className='text-[#002642] text-[18px] font-["Quicksand"] font-bold'>{dta?.dta}</p>

                        
                        {/* secretery */}
                        <div className='flex justify-between items-center'>
{/* president */}
<div>
                            <p className='text-[#002642] font-["Quicksand"] text-[18px] font-bold'>President</p>
                            <p className='text-[#444444] font-["Quicksand"] text-[14px] font-normal'>{dta?.president}</p>
                        </div>
                        <div>
                            <p className='text-[#002642] font-["Quicksand"] text-[18px] font-bold'>Secretary</p>
                            <p className='text-[#444444] font-["Quicksand"] text-[14px] font-normal'>{dta?.secretary}</p>
                        </div>
                       
                        </div>
                        {/* address */}
                        <div>
                           <div className='flex gap-2 items-center'>
                           <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.602 17.5476C6.57025 17.527 6.55769 17.4953 6.53997 17.4681C6.26804 17.0524 5.99646 16.6362 5.72522 16.2198C4.65454 14.5706 3.58991 12.9175 2.55201 11.2484C2.14751 10.5976 1.75454 9.94006 1.36215 9.28183C1.10031 8.84198 0.926346 8.3724 0.802738 7.88109C0.658117 7.30885 0.603034 6.71813 0.639402 6.12944C0.711766 4.95336 1.09928 3.88692 1.8173 2.94469C2.64539 1.85798 3.72563 1.13497 5.058 0.775647C5.39757 0.685099 5.74485 0.62556 6.09546 0.597784C6.39114 0.57252 6.68833 0.569401 6.98449 0.588453C8.18528 0.668928 9.27029 1.06343 10.225 1.788C11.2957 2.60053 12.01 3.65147 12.3679 4.94082C12.4591 5.27302 12.5196 5.61269 12.5486 5.95566C12.5759 6.2656 12.5787 6.57717 12.5569 6.88754C12.5258 7.32772 12.4443 7.76301 12.3138 8.18506C12.2757 8.30912 12.2363 8.4329 12.1935 8.55551C12.1341 8.72377 12.06 8.8866 11.972 9.04215C11.6411 9.63085 11.2911 10.2085 10.937 10.7834C10.0159 12.2795 9.06841 13.7593 8.11439 15.2351C7.63581 15.9755 7.15516 16.7147 6.67244 17.4525C6.65147 17.4835 6.63449 17.5192 6.602 17.5476ZM9.2279 6.4751C9.2341 5.04156 8.04469 3.88007 6.60791 3.87584C5.17452 3.8719 3.97787 5.02218 3.9724 6.46067C3.96679 7.89611 5.15503 9.05862 6.58856 9.06504C8.03287 9.07131 9.22908 7.90631 9.2279 6.4751Z" fill="#002642"/>
</svg>


                           <p className='text-[#002642] font-["Quicksand"] text-[18px] font-bold'>Address</p>
                           </div>
                           <p className='text-[#444444] font-["Quicksand"] text-[14px] font-normal'>{dta?.address}</p>
                        </div>
                        {/* email / phone */}
                        <div className='flex justify-between items-center'>
                        <div>
                           <div className='flex gap- items-center'>
                           <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.5381 11.6432L1.45455 11.7422H1.58411H16.3276H16.4563L16.3737 11.6434L11.3295 5.60959L11.2908 5.56333L11.2447 5.60207L8.94957 7.52712L6.66003 5.66585L6.61418 5.62858L6.57606 5.67373C4.89306 7.66755 3.25271 9.61137 1.5381 11.6432Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.120388"/>
<path d="M8.91699 7.02641L8.95583 7.05919L8.99465 7.02639L16.8811 0.365077L17.0069 0.258898H16.8423H1.06335H0.89871L1.02453 0.365092L8.91699 7.02641Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.120388"/>
<path d="M16.8326 11.6848L16.939 11.8121V11.6462V0.869995V0.739735L16.8398 0.824153L11.5782 5.30156L11.5328 5.34021L11.571 5.38599L16.8326 11.6848Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.120388"/>
<path d="M6.31693 5.38204L6.35514 5.33623L6.30968 5.29761L1.03909 0.820214L0.939928 0.735972V0.866089V11.6623V11.8285L1.04635 11.7009L6.31693 5.38204Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.120388"/>
</svg>



                           <p className='text-[#002642] font-["Quicksand"] text-[18px] font-bold'>Email</p>
                           </div>
                           <p className='text-[#444444] font-["Quicksand"] text-[12px] font-normal'>{dta?.email}</p>
                        </div>
                        <div>
                           <div className='flex gap-2 items-center'>
                           <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.28749 4.27075C0.371061 3.84931 0.422674 3.42155 0.544344 3.01767C1.00829 1.4724 2.1875 0.638648 3.60145 0.83251C3.66386 0.845554 3.72341 0.872552 3.77666 0.911968C3.82992 0.951383 3.87583 1.00243 3.91177 1.06219C4.4853 2.1392 5.05064 3.22184 5.60778 4.31009C5.7995 4.68517 5.74665 5.14594 5.44678 5.50417C5.20774 5.78513 4.92201 6.02184 4.65716 6.27681C4.51706 6.41307 4.36896 6.5402 4.2393 6.68771C4.05496 6.89843 4.02484 7.13514 4.16188 7.4161C5.15797 9.45305 6.50985 11.0826 8.33736 12.1699C8.57885 12.3104 8.77181 12.302 8.94203 12.0885C9.17615 11.7963 9.38138 11.4739 9.61305 11.1753C9.76476 10.9707 9.93535 10.7854 10.1219 10.6226C10.261 10.4995 10.4296 10.4279 10.6053 10.4172C10.7809 10.4066 10.9553 10.4574 11.105 10.5628C11.9229 11.0917 12.7341 11.6333 13.5513 12.1636C13.8801 12.3743 14.0214 12.7255 14.1142 13.1182V13.6098C14.0958 13.6759 14.0724 13.7398 14.0589 13.8065C13.8555 14.8468 13.3443 15.604 12.4741 16.0099C11.2716 16.5719 10.0346 16.6997 8.78717 16.2368C7.14033 15.6243 5.70978 14.5644 4.41812 13.2699C2.86283 11.712 1.57178 9.9068 0.820866 7.67598C0.567695 6.92371 0.460161 6.10752 0.285645 5.32083L0.28749 4.27075Z" fill="#002642"/>
</svg>




                           <p className='text-[#002642] font-["Quicksand"] text-[18px] font-bold'>Phone</p>
                           </div>
                           <p className='text-[#444444] font-["Quicksand"] text-[12px] font-normal'>{dta?.phone_number} { `${dta?.alternative_phone_number && `/ ${dta?.alternative_phone_number}`}`}</p>
                        </div>
                        </div>
                    </div>
                   <img alt='' src={dta?.image} className='w-full h-[253px] bg-black rounded-lg'/>
                 </div>
            </div>
            )
        }
        </div>
  )
}

export default MobileView
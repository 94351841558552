import AppRouter from './router/AppRouter';
import { Provider } from 'react-redux';
import { store } from './redux/strore';
import './App.css';
import { Toaster } from 'react-hot-toast';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
function App() {
  return (
    <>
    <Provider store={store}>
    <AppRouter />
    </Provider>
    <Toaster />
    </>
  );
}

export default App;

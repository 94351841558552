import React, { useEffect } from 'react'
import Template from '../../components/Template';
import MobileView from './MobileView';
import { useDispatch, useSelector } from 'react-redux';
import { getDtaList } from '../../service/api';

const Dta = () => {
   const { dtaList } = useSelector(state => state.home)
   const dispatch = useDispatch()
   useEffect(() => {
      dispatch(getDtaList())
   }, [dispatch])

   return (
      <Template>
         {/* for desktop view */}
         <div className='p-7 max-md:p-5'>
            <p className='text-[50px] font-["Quicksand"] max-md:text-[32px] font-bold text-[#002642] text-center py-5 max-md:py-2'>District Taekwondo Association</p>
            <div className='max-md:hidden'>

               {
                  dtaList?.map((dta, i) =>
                     <>
                        {
                           i % 2 === 0 ? <>
                              {/* for left side image */}
                              <div className='py-8'>
                                 {/* {data map here} */}
                                 <div className='flex justify-between w-full  h-[432px]'>
                                    <img alt='' src={dta?.image} className='w-[49%] h-full bg-black rounded-lg' />

                                    <div className='flex w-[47%]  flex-col justify-between'>
                                       <div className='flex items-center gap-5'>
                                          <img src={dta?.logo} alt='' className='w-[95px] h-[95px] rounded-full ' />
                                          <p className='text-[#002642] font-["Quicksand"] font-bold text-[40px] '>{dta?.city_name}</p>
                                       </div>

                                       <p className='text-[#002642] text-[30px] font-["Quicksand"] font-bold'>{dta?.dta}</p>
                                        
                                       <div className='flex justify-between w-full items-center'>

                                          {/* president */}
                                          <div >
                                             <p className='text-[#002642] text-[30px] font-["Quicksand"] font-bold'>President</p>
                                             <p className='text-[#444444] text-[24px] font-["Quicksand"] font-normal'>{dta?.president}</p>
                                          </div>
                                          {/* secretery */}
                                          <div>

                                             <p className='text-[#002642] font-["Quicksand"] text-[30px] font-bold'>Secretary</p>
                                             <p className='text-[#444444] font-["Quicksand"] text-[24px] font-normal'>{dta?.secretary}</p>

                                          </div>
                                       </div>
                                       {/* address */}
                                       <div>
                                          <div className='flex gap-2 items-center'>
                                             <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9.50323 27C9.45271 26.9673 9.43275 26.9167 9.40455 26.8736C8.97194 26.2121 8.53987 25.5501 8.10835 24.8875C6.40499 22.2639 4.71127 19.6339 3.06007 16.9785C2.41655 15.9431 1.79136 14.8971 1.16711 13.8499C0.750549 13.1502 0.473782 12.4031 0.277133 11.6215C0.0470543 10.7111 -0.0405777 9.7713 0.0172809 8.83475C0.132404 6.96371 0.748903 5.2671 1.89121 3.7681C3.20863 2.03924 4.92719 0.888992 7.04687 0.317346C7.58709 0.173293 8.13958 0.078572 8.69737 0.0343822C9.16777 -0.0058099 9.64058 -0.0107724 10.1117 0.019538C12.0221 0.147567 13.7482 0.775187 15.2672 1.92791C16.9705 3.22057 18.1069 4.89252 18.6762 6.94377C18.8213 7.47226 18.9175 8.01264 18.9638 8.55828C19.0072 9.05137 19.0116 9.54705 18.977 10.0408C18.9275 10.7411 18.7977 11.4336 18.5902 12.105C18.5296 12.3024 18.4669 12.4993 18.3988 12.6944C18.3043 12.9621 18.1864 13.2211 18.0463 13.4686C17.5201 14.4052 16.9632 15.3241 16.3998 16.2388C14.9345 18.619 13.4271 20.9731 11.9093 23.321C11.1479 24.499 10.3833 25.6749 9.6153 26.8488C9.58194 26.8979 9.55492 26.9548 9.50323 27ZM13.6808 9.38467C13.6907 7.10403 11.7984 5.2562 9.51263 5.24947C7.23224 5.24321 5.32847 7.07319 5.31978 9.3617C5.31085 11.6453 7.20123 13.4948 9.48185 13.505C11.7796 13.515 13.6827 11.6616 13.6808 9.38467Z" fill="#002642" />
                                             </svg>

                                             <p className='text-[#002642] font-["Quicksand"] text-[30px] font-bold'>Address</p>
                                          </div>
                                          <p className='text-[#444444] font-["Quicksand"] text-[24px] font-normal'>{dta?.address}</p>
                                       </div>
                                       {/* email / phone */}
                                       <div className='flex  justify-between items-center'>
                                          <div>
                                             <div className='flex gap-2 items-center'>
                                                <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                   <path d="M1.85601 19.016L1.72308 19.1736H1.9292H25.3847H25.5896L25.4582 19.0164L17.4333 9.41709L17.3717 9.3435L17.2982 9.40514L13.647 12.4677L10.0045 9.50661L9.93159 9.44731L9.87095 9.51915C7.19345 12.6911 4.5838 15.7836 1.85601 19.016Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.191526" />
                                                   <path d="M13.595 11.6707L13.6568 11.7229L13.7186 11.6707L26.2653 1.07316L26.4652 0.904237H26.2035H1.10059H0.838651L1.03882 1.07318L13.595 11.6707Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.191526" />
                                                   <path d="M26.1884 19.0818L26.3577 19.2844V19.0204V1.87646V1.66923L26.1998 1.80353L17.8291 8.92667L17.7568 8.98817L17.8176 9.061L26.1884 19.0818Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.191526" />
                                                   <path d="M9.45856 9.05459L9.51935 8.98171L9.44702 8.92027L1.062 1.79713L0.904237 1.66311V1.87012V19.0459V19.3103L1.07354 19.1073L9.45856 9.05459Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.191526" />
                                                </svg>


                                                <p className='text-[#002642] font-["Quicksand"] text-[30px] font-bold'>Email</p>
                                             </div>
                                             <p className='text-[#444444] font-["Quicksand"] text-[24px] font-normal'>{dta?.email}</p>
                                          </div>
                                          <div>
                                             <div className='flex gap-2 items-center'>
                                                <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                   <path d="M0.00293568 5.514C0.13589 4.84353 0.218002 4.163 0.411567 3.52046C1.14966 1.06207 3.02568 -0.264348 5.27515 0.0440695C5.37443 0.06482 5.46917 0.107772 5.55389 0.170479C5.63861 0.233185 5.71165 0.314401 5.76884 0.409465C6.68127 2.12289 7.58068 3.84527 8.46704 5.57658C8.77205 6.1733 8.68796 6.90635 8.21089 7.47625C7.83061 7.92323 7.37603 8.29981 6.95469 8.70545C6.73179 8.92224 6.49619 9.12449 6.28991 9.35916C5.99663 9.69439 5.94873 10.071 6.16673 10.518C7.75143 13.7586 9.90215 16.3511 12.8095 18.0809C13.1937 18.3044 13.5007 18.291 13.7715 17.9513C14.144 17.4864 14.4705 16.9735 14.8391 16.4986C15.0804 16.173 15.3518 15.8781 15.6485 15.6191C15.8699 15.4234 16.1382 15.3095 16.4176 15.2925C16.6971 15.2755 16.9744 15.3563 17.2127 15.5241C18.5139 16.3656 19.8043 17.2271 21.1045 18.0708C21.6275 18.4061 21.8524 18.9648 22 19.5894V20.3717C21.9707 20.4767 21.9335 20.5784 21.912 20.6845C21.5884 22.3395 20.7751 23.5441 19.3908 24.19C17.4776 25.084 15.5097 25.2873 13.5252 24.5509C10.9052 23.5765 8.62931 21.8903 6.57439 19.8308C4.10007 17.3523 2.04612 14.4804 0.851489 10.9314C0.448716 9.73462 0.277639 8.43613 0 7.18458L0.00293568 5.514Z" fill="#002642" />
                                                </svg>



                                                <p className='text-[#002642] font-["Quicksand"] text-[30px] font-bold'>Phone</p>
                                             </div>
                                             <p className='text-[#444444] font-["Quicksand"] text-[24px] font-normal whitespace-nowrap'>{dta?.phone_number} {`${dta?.alternative_phone_number && `/ ${dta?.alternative_phone_number}`}`}</p>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                           </>
                              :
                              <>
                                 {/* for right side image */}

                                 <div className='py-8'>
                                    {/* {data map here} */}
                                    <div className='flex justify-between w-full  h-[432px]'>
                                       <div className='flex w-[45%]  flex-col justify-between'>
                                          <div className='flex items-center gap-5'>
                                             <img src={dta?.logo} alt='' className='w-[95px] h-[95px] rounded-full ' />
                                             <p className='text-[#002642] font-["Quicksand"] font-bold text-[40px] '>{dta?.city_name}</p>
                                          </div>

                                          <p className='text-[#002642] text-[30px] font-["Quicksand"] font-bold'>{dta?.dta}</p>

                                          <div className='flex justify-between  items-center'>

                                             {/* president */}
                                             <div >
                                                <p className='text-[#002642] text-[30px] font-["Quicksand"] font-bold'>President</p>
                                                <p className='text-[#444444] text-[24px] font-["Quicksand"] font-normal'>{dta?.president}</p>
                                             </div>
                                             {/* secretery */}
                                             <div>

                                                <p className='text-[#002642] font-["Quicksand"] text-[30px] font-bold'>Secretary</p>
                                                <p className='text-[#444444] font-["Quicksand"] text-[24px] font-normal'>{dta?.secretary}</p>

                                             </div>
                                          </div>
                                          {/* address */}
                                          <div>
                                             <div className='flex gap-2 items-center'>
                                                <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                   <path d="M9.50323 27C9.45271 26.9673 9.43275 26.9167 9.40455 26.8736C8.97194 26.2121 8.53987 25.5501 8.10835 24.8875C6.40499 22.2639 4.71127 19.6339 3.06007 16.9785C2.41655 15.9431 1.79136 14.8971 1.16711 13.8499C0.750549 13.1502 0.473782 12.4031 0.277133 11.6215C0.0470543 10.7111 -0.0405777 9.7713 0.0172809 8.83475C0.132404 6.96371 0.748903 5.2671 1.89121 3.7681C3.20863 2.03924 4.92719 0.888992 7.04687 0.317346C7.58709 0.173293 8.13958 0.078572 8.69737 0.0343822C9.16777 -0.0058099 9.64058 -0.0107724 10.1117 0.019538C12.0221 0.147567 13.7482 0.775187 15.2672 1.92791C16.9705 3.22057 18.1069 4.89252 18.6762 6.94377C18.8213 7.47226 18.9175 8.01264 18.9638 8.55828C19.0072 9.05137 19.0116 9.54705 18.977 10.0408C18.9275 10.7411 18.7977 11.4336 18.5902 12.105C18.5296 12.3024 18.4669 12.4993 18.3988 12.6944C18.3043 12.9621 18.1864 13.2211 18.0463 13.4686C17.5201 14.4052 16.9632 15.3241 16.3998 16.2388C14.9345 18.619 13.4271 20.9731 11.9093 23.321C11.1479 24.499 10.3833 25.6749 9.6153 26.8488C9.58194 26.8979 9.55492 26.9548 9.50323 27ZM13.6808 9.38467C13.6907 7.10403 11.7984 5.2562 9.51263 5.24947C7.23224 5.24321 5.32847 7.07319 5.31978 9.3617C5.31085 11.6453 7.20123 13.4948 9.48185 13.505C11.7796 13.515 13.6827 11.6616 13.6808 9.38467Z" fill="#002642" />
                                                </svg>

                                                <p className='text-[#002642] font-["Quicksand"] text-[30px] font-bold'>Address</p>
                                             </div>
                                             <p className='text-[#444444] font-["Quicksand"] text-[24px] font-normal'>{dta?.address}</p>
                                          </div>
                                          {/* email / phone */}
                                          <div className='flex justify-between w-full items-center'>
                                             <div>
                                                <div className='flex gap-2 items-center'>
                                                   <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M1.85601 19.016L1.72308 19.1736H1.9292H25.3847H25.5896L25.4582 19.0164L17.4333 9.41709L17.3717 9.3435L17.2982 9.40514L13.647 12.4677L10.0045 9.50661L9.93159 9.44731L9.87095 9.51915C7.19345 12.6911 4.5838 15.7836 1.85601 19.016Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.191526" />
                                                      <path d="M13.595 11.6707L13.6568 11.7229L13.7186 11.6707L26.2653 1.07316L26.4652 0.904237H26.2035H1.10059H0.838651L1.03882 1.07318L13.595 11.6707Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.191526" />
                                                      <path d="M26.1884 19.0818L26.3577 19.2844V19.0204V1.87646V1.66923L26.1998 1.80353L17.8291 8.92667L17.7568 8.98817L17.8176 9.061L26.1884 19.0818Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.191526" />
                                                      <path d="M9.45856 9.05459L9.51935 8.98171L9.44702 8.92027L1.062 1.79713L0.904237 1.66311V1.87012V19.0459V19.3103L1.07354 19.1073L9.45856 9.05459Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.191526" />
                                                   </svg>


                                                   <p className='text-[#002642] font-["Quicksand"] text-[30px] font-bold'>Email</p>
                                                </div>
                                                <p className='text-[#444444] font-["Quicksand"] text-[24px] font-normal'>{dta?.email}</p>
                                             </div>
                                             <div>
                                                <div className='flex gap-2 items-center'>
                                                   <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path d="M0.00293568 5.514C0.13589 4.84353 0.218002 4.163 0.411567 3.52046C1.14966 1.06207 3.02568 -0.264348 5.27515 0.0440695C5.37443 0.06482 5.46917 0.107772 5.55389 0.170479C5.63861 0.233185 5.71165 0.314401 5.76884 0.409465C6.68127 2.12289 7.58068 3.84527 8.46704 5.57658C8.77205 6.1733 8.68796 6.90635 8.21089 7.47625C7.83061 7.92323 7.37603 8.29981 6.95469 8.70545C6.73179 8.92224 6.49619 9.12449 6.28991 9.35916C5.99663 9.69439 5.94873 10.071 6.16673 10.518C7.75143 13.7586 9.90215 16.3511 12.8095 18.0809C13.1937 18.3044 13.5007 18.291 13.7715 17.9513C14.144 17.4864 14.4705 16.9735 14.8391 16.4986C15.0804 16.173 15.3518 15.8781 15.6485 15.6191C15.8699 15.4234 16.1382 15.3095 16.4176 15.2925C16.6971 15.2755 16.9744 15.3563 17.2127 15.5241C18.5139 16.3656 19.8043 17.2271 21.1045 18.0708C21.6275 18.4061 21.8524 18.9648 22 19.5894V20.3717C21.9707 20.4767 21.9335 20.5784 21.912 20.6845C21.5884 22.3395 20.7751 23.5441 19.3908 24.19C17.4776 25.084 15.5097 25.2873 13.5252 24.5509C10.9052 23.5765 8.62931 21.8903 6.57439 19.8308C4.10007 17.3523 2.04612 14.4804 0.851489 10.9314C0.448716 9.73462 0.277639 8.43613 0 7.18458L0.00293568 5.514Z" fill="#002642" />
                                                   </svg>



                                                   <p className='text-[#002642] font-["Quicksand"] text-[30px] font-bold'>Phone</p>
                                                </div>
                                                <p className='text-[#444444] font-["Quicksand"] text-[24px] font-normal whitespace-nowrap'>{dta?.phone_number} {`${dta?.alternative_phone_number && `/ ${dta?.alternative_phone_number}`}`}</p>
                                             </div>
                                          </div>
                                       </div>
                                       <img alt='' src={dta?.image} className='w-[49%] h-full bg-black rounded-lg' />
                                    </div>
                                 </div>
                              </>
                        }

                     </>
                  )
               }
            </div>
            <div className='md:hidden'>
               <MobileView />
            </div>
         </div>

         {/* for mobile view */}


      </Template>
   )
}

export default Dta;
import React from 'react'
import Template from '../../components/Template'
import {ReactComponent as Location} from "../../assets/icons/location_icon.svg"
import {ReactComponent as Email} from "../../assets/icons/email_icon.svg"
import {ReactComponent as Phone} from "../../assets/icons/phone_icon.svg"
import {ReactComponent as Hour} from "../../assets/icons/time_icon.svg"
import Banner from "../../assets/Taekwondo Images/1.jpg"
import { useSelector } from 'react-redux'

const MobileView = () => {
const {contactList} = useSelector(state=>state.home)

  return (

         <div className='w-full my-7'>
        <div className='relative h-60'>
          <img alt='' src={Banner} className='w-full h-full'/>
          
          <div className='absolute  top-10  w-full'>
            <div className='mx-7'>

          <p className='text-white text-[32px] font-bold text-center bottom-0 font-["Quicksand"]'>For Any Enquiry: Get In Touch</p>
            </div>
          <div className=' py-5 mt-10 flex flex-col justify-center items-center mx-auto'>
            {/* location card */}
        <div className='w-[284px] relative  h-[219px] bg-white  shadow-lg rounded-md'>
            <div className=' absolute bottom-[265px]  left-20 '>

             <div className='h-28 w-28 justify-center items-center flex rounded-full bg-white shadow-lg absolute'>
             <svg width="39" height="53" viewBox="0 0 39 53" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.5322 52.9311C19.4324 52.867 19.3929 52.7679 19.3372 52.6834C18.4822 51.387 17.6283 50.0896 16.7754 48.7912C13.4089 43.6494 10.0614 38.4953 6.79794 33.2913C5.52609 31.2622 4.29046 29.2122 3.05668 27.16C2.23339 25.7886 1.68639 24.3245 1.29773 22.7927C0.842998 21.0086 0.669802 19.1668 0.784154 17.3314C1.01169 13.6646 2.23014 10.3396 4.4878 7.40194C7.09156 4.01378 10.4881 1.75955 14.6775 0.63926C15.7452 0.356949 16.8371 0.171317 17.9395 0.0847152C18.8692 0.00594791 19.8037 -0.00377746 20.7349 0.0556239C24.5105 0.306531 27.9221 1.53652 30.9241 3.7956C34.2906 6.32891 36.5365 9.60555 37.6618 13.6255C37.9485 14.6612 38.1388 15.7203 38.2302 16.7896C38.3159 17.7559 38.3246 18.7274 38.2562 19.695C38.1584 21.0674 37.902 22.4246 37.4919 23.7404C37.3721 24.1273 37.2481 24.5132 37.1134 24.8954C36.9267 25.42 36.6937 25.9277 36.4169 26.4127C35.3768 28.2481 34.2762 30.049 33.1627 31.8417C30.2666 36.5063 27.2873 41.1199 24.2876 45.7212C22.7828 48.0297 21.2715 50.3342 19.7537 52.6348C19.6878 52.7311 19.6344 52.8425 19.5322 52.9311ZM27.7888 18.4091C27.8083 13.9396 24.0685 10.3183 19.5508 10.3051C15.0438 10.2928 11.2812 13.8792 11.264 18.3641C11.2464 22.8395 14.9826 26.4641 19.49 26.4841C24.0313 26.5036 27.7925 22.8714 27.7888 18.4091Z" fill="#002642"/>
</svg>

             </div>
            </div>
            

            <div className='pt-16 flex flex-col '>
                   <p className='font-bold text-center text-[42px] text-[#002642] font-["Quicksand"]'>Address</p>
                   <p className='text-[#444444] text-[14px] font-bold text-center font-["Quicksand"] pb-3'>At- Flat No. C/103, Kalpana sagar complex, 3sipasarubali, baliapanda PIN- 752001, post/Dist.- Puri</p>
                   </div>

        </div>
        </div>

          </div>
        </div>
        <div className='mx-auto flex flex-col items-center justify-center  mt-52 '>
            
            {/* email card */}
        <div className=' pt-14 '>
        <div className='w-[284px] relative  h-[189px] bg-white  shadow-lg rounded-md'>
            <div className=' absolute bottom-60 left-20 '>

             <div className='h-28 w-28 justify-center items-center flex rounded-full bg-white shadow-lg absolute'>
             <svg width="52" height="38" viewBox="0 0 52 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.38433 36.1428L3.08822 36.4937H3.54736H48.8066H49.263L48.9703 36.1436L33.4856 17.621L33.3486 17.4571L33.1849 17.5944L26.1575 23.4887L19.1474 17.7899L18.9849 17.6578L18.8498 17.8178C13.6834 23.9384 8.64783 29.9055 3.38433 36.1428Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.426647"/>
<path d="M26.0388 22.0101L26.1765 22.1262L26.3141 22.01L50.524 1.56116L50.9695 1.18487H50.3863H1.94824H1.36475L1.81065 1.56121L26.0388 22.0101Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.426647"/>
<path d="M50.3361 36.3067L50.7131 36.7581V36.17V3.08936V2.62772L50.3615 2.92689L34.2094 16.6716L34.0484 16.8086L34.1839 16.9708L50.3361 36.3067Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.426647"/>
<path d="M18.0983 16.9587L18.2337 16.7964L18.0726 16.6595L1.89299 2.91481L1.54156 2.61627V3.07739V36.2196V36.8084L1.9187 36.3562L18.0983 16.9587Z" fill="#002642" stroke="#F5F5F5" stroke-width="0.426647"/>
</svg>


             </div>
            </div>
            <div className=' pt-20 flex flex-col '>
                   <p className='font-bold  text-center text-[42px] text-[#002642] font-["Quicksand"]'>Email</p>
                   <p className='text-[#444444] text-[14px] font-bold text-center font-["Quicksand"] pb-3'>info@odishataekwondoassociation.com</p>
                   </div>
        </div>
        </div>

         {/* phone card */}
         <div className=' mt-16 '>
        <div className='w-[284px] relative  h-[189px] bg-white  shadow-lg rounded-md'>
            <div className=' absolute bottom-60 left-20 '>

             <div className='h-28 w-28 justify-center items-center flex rounded-full bg-white shadow-lg absolute'>
             <svg width="40" height="44" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.074051 10.0686C0.309708 8.90916 0.455248 7.73232 0.798337 6.62118C2.10658 2.36985 5.43176 0.0760641 9.41887 0.609413C9.59485 0.645297 9.76277 0.719574 9.91293 0.828014C10.0631 0.936453 10.1926 1.0769 10.2939 1.2413C11.9112 4.20434 13.5053 7.18287 15.0764 10.1768C15.617 11.2088 15.468 12.4764 14.6224 13.462C13.9483 14.2349 13.1426 14.8862 12.3958 15.5876C12.0007 15.9625 11.5831 16.3123 11.2175 16.7181C10.6977 17.2978 10.6128 17.949 10.9992 18.722C13.808 24.326 17.6201 28.8092 22.7733 31.8006C23.4543 32.1871 23.9984 32.164 24.4784 31.5765C25.1386 30.7726 25.7173 29.8856 26.3706 29.0643C26.7984 28.5013 27.2794 27.9914 27.8053 27.5435C28.1977 27.2051 28.6732 27.0081 29.1685 26.9787C29.6638 26.9494 30.1554 27.0891 30.5777 27.3793C32.884 28.8344 35.1713 30.3243 37.4759 31.7833C38.4029 32.363 38.8014 33.3292 39.0631 34.4094V35.7621C39.0111 35.9438 38.9453 36.1196 38.9072 36.3032C38.3336 39.1651 36.8919 41.2483 34.4383 42.3652C31.0473 43.9111 27.5593 44.2628 24.0417 42.9894C19.3979 41.3043 15.364 38.3883 11.7217 34.8268C7.33608 30.5407 3.69553 25.5744 1.57808 19.437C0.864182 17.3674 0.560954 15.1219 0.0688477 12.9576L0.074051 10.0686Z" fill="#002642"/>
</svg>


             </div>
            </div>
            <div className='pt-20 flex flex-col'>
                   <p className='font-bold text-center text-[42px] text-[#002642] font-["Quicksand"]'>Phone</p>
                   <p className='text-[#444444] text-[14px] font-bold text-center font-["Quicksand"] '>9338449446 / 7008130771</p>
                   </div>
        </div>
        </div>
         {/* opening time card */}
         <div className=' mt-20 '>
        <div className='w-[284px] relative  h-[225px] bg-white  shadow-lg rounded-md'>
            <div className=' absolute bottom-72 left-20 '>

             <div className='h-28 w-28 justify-center items-center flex rounded-full bg-white shadow-lg absolute'>
             <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.424 0.533203C10.4865 0.533203 0.807617 10.212 0.807617 22.1496C0.807617 34.0871 10.4865 43.766 22.424 43.766C34.3615 43.766 44.0404 34.0871 44.0404 22.1496C44.0404 10.212 34.3615 0.533203 22.424 0.533203ZM32.4116 24.77H21.6167L21.6079 24.8097C20.6241 24.8097 19.8036 24.0995 19.7065 23.1422C19.7021 23.0804 19.6845 23.0142 19.6845 22.9481V9.25916C19.6845 8.20922 20.5359 7.35339 21.5858 7.35339C22.6357 7.35339 23.4872 8.20481 23.4872 9.25916V20.9673H32.4116C33.4616 20.9673 34.3174 21.8187 34.3174 22.8687C34.3174 23.9186 33.466 24.77 32.4116 24.77Z" fill="#002642"/>
</svg>



             </div>
            </div>
            <div className='pt-14 flex flex-col '>
                   <p className='font-bold text-center text-[42px] font-["Quicksand"] text-[#002642]'>Open Hour</p>
                   <p className='text-[#444444] text-[14px] font-bold font-["Quicksand"] text-center pb-3'>Monday - Saturday <br/> 09 AM - 05 PM <br/>Sunday : Closed</p>
                   </div>
        </div>
        </div>

        {/* ASSOSIATION MEMBER */}
        <div>
          <p className='text-[32px] font-["Quicksand"] py-5 font-bold text-center'>OTA OFFICE BEARERS</p>
          {/* image card of member */}
          {
            contactList?.length?
          <div className='flex flex-col gap-5 mx-5'>
          {
            contactList?.map((v,i)=>
          <div key={i} className='w-full h-[400px] bg-white rounded-[29px] shadow-xl '>
            <div className='p-2 h-full w-full '>
            <div className='h-full w-full border-[1.22px]  flex flex-col justify-center items-center rounded-[28px] border-[#154162] '>
             

                 <img alt='' src={v?.image} className='h-[205px] rounded-[10px] w-[280px]'/>
                 <div className='items-center w-full mt-7 flex flex-col gap-3'>

                 <p className='font-bold text-[27px] text-center font-["Quicksand"]'>{v?.name}</p>
                 <p className='font-medium text-center text-[24px] font-["Quicksand"]'>{v?.post}</p>
                 </div>
                
            </div>
            </div>
          </div>
            )
        }
          </div>:
          <div className='text-center py-11 text-[24px] font-medium font-["Quicksand"]'> No Data</div>
          }

        </div>
        </div>
       </div>

    
  )
}

export default MobileView
import React, { useEffect, useRef, useState } from 'react'
import Template from '../../components/Template'
import Logo from "../../assets/Taekwondo Images/Images/Logo.png"
import {ReactComponent as PrintButton} from "../../assets/icons/print_button.svg"
import SuccessModal from './SuccessModal'
import { useSelector } from 'react-redux'
import html2canvas from 'html2canvas'
import moment from 'moment'
const ColorbeltStrip = () => {
    const [openSuccessmodal, setopenSuccessmodal] = useState(false)
    const {colorbeltPromotionData} = useSelector(state=>state.registerClub)
    const ref = useRef();
    useEffect(()=>{
        setopenSuccessmodal(true)
    }, [])
    const handlePrint = () => {
        if (colorbeltPromotionData?.heading) {   
            html2canvas(ref.current).then(function(canvas) {
                const link = document.createElement('a');
                link.href = canvas.toDataURL('image/png');
                link.download =  `${colorbeltPromotionData?.heading}_test_certificate.png`;
                link.click();
            });
        }
    }
  return (
    <Template>
        {
            openSuccessmodal&&
        <SuccessModal closeModal={()=>setopenSuccessmodal(false)}/>
        }
        <div className='max-md:p-5 p-7' ref={ref}>

        <div className=' w-full flex flex-col gap-3 justify-center items-center'>
            <img src={Logo} alt='logo' className='h-28 w-40 max-md:h-20 max-md:w-28 object-cover'/>
            <p className='text-[50px] max-md:text-[24px] font-medium text-[#002642] font-["Quicksand"] text-center'>{colorbeltPromotionData?.heading}</p>
        </div>
<div className='flex max-md:gap-4 max-md:items-center max-md:flex-col md:justify-between max-md:px-0 max-md:py-5 px-11 py-10 bg-'>

        {/* details */}
       <div className='flex items-center gap-5'>

        <div className='flex flex-col gap-2'>
            {/* <p className='text-[34px] max-md:text-[12px] font-bold text-[#002642] font-["Quicksand"] '>Date :</p> */}
            <p className='text-[34px] max-md:text-[12px] font-bold text-[#002642] font-["Quicksand"] '>OTA ID No. :</p>
            <p className='text-[34px] max-md:text-[12px] font-bold text-[#002642] font-["Quicksand"] '>Name:</p>
            <p className='text-[34px] max-md:text-[12px] font-bold text-[#002642] font-["Quicksand"] capitalize'>Gender:</p>
            <p className='text-[34px] max-md:text-[12px] font-bold text-[#002642] font-["Quicksand"] '>DOB:</p>
            <p className='text-[34px] max-md:text-[12px] font-bold text-[#002642] font-["Quicksand"] '>Present Belt :</p>
            <p className='text-[34px] max-md:text-[12px] font-bold text-[#002642] font-["Quicksand"] '>Applied Belt :</p>
            <p className='text-[34px] max-md:text-[12px] font-bold text-[#002642] font-["Quicksand"] '>District Name :</p>
            <p className='text-[34px] max-md:text-[12px] font-bold text-[#002642] font-["Quicksand"] '>OTA Club Name :</p>
           
        </div>
        <div className='flex flex-col gap-2'>
            {/* <p className='text-[34px] max-md:text-[12px] font-normal text-[#444444] font-["Quicksand"] capitalize'>{moment().format('DD-MM-YYYY hh:mm A')}</p> */}
            <p className='text-[34px] max-md:text-[12px] font-normal text-[#444444] font-["Quicksand"] capitalize'>{colorbeltPromotionData?.ota_Id}</p>
            <p className='text-[34px] max-md:text-[12px] font-normal text-[#444444] font-["Quicksand"] capitalize'>{colorbeltPromotionData?.name}</p>
            <p className='text-[34px] max-md:text-[12px] font-normal text-[#444444] font-["Quicksand"] capitalize'>{colorbeltPromotionData?.gender}</p>
            <p className='text-[34px] max-md:text-[12px] font-normal text-[#444444] font-["Quicksand"] capitalize'>{colorbeltPromotionData?.dob}</p>
            <p className='text-[34px] max-md:text-[12px] font-normal text-[#444444] font-["Quicksand"] capitalize'>{colorbeltPromotionData?.present_belt}</p>
            <p className='text-[34px] max-md:text-[12px] font-normal text-[#444444] font-["Quicksand"] capitalize'>{colorbeltPromotionData?.active_belt}</p>
            <p className='text-[34px] max-md:text-[12px] font-normal text-[#444444] font-["Quicksand"] capitalize'>{colorbeltPromotionData?.city}</p>
            <p className='text-[34px] max-md:text-[12px] font-normal text-[#444444] font-["Quicksand"] capitalize'>{colorbeltPromotionData?.club_name}</p>
           
        </div>
       </div>
</div>
<p className='text-[50px] max-md:text-[24px] font-medium text-[#002642] font-["Quicksand"] text-center'>OTA : Online Registration System Record</p>

<p className='text-[34px] text-center max-md:text-[12px] font-normal text-[#444444] font-["Quicksand"] capitalize'>{moment().format('DD-MM-YYYY hh:mm A')}</p>

        </div>
       <div className='flex justify-center items-center w-full '>
         <PrintButton className='w-32 h-20 cursor-pointer' onClick={handlePrint}/>
       </div>
    </Template>
  )
}

export default ColorbeltStrip
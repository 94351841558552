import React, { useEffect, useState } from 'react'
import Footer from './common/Footer'
import Header from './common/Header'
import { Link } from 'react-router-dom'
import Logo from "../assets/Taekwondo Images/Images/Logo.png"
import { ReactComponent as Bar } from "../assets/icons/threeBar_icon.svg"
import * as routesKey from "../constants/routes"
import { useSelector } from 'react-redux'
import { downloadImage } from './helper/downloadImage'


const Template = ({ children }) => {
  const [sideBarOpen, setSideBarOpen] = useState(false)
  const [isDropDownShow, setIsDropDownShow] = useState("")
  const { formList, districtList } = useSelector(state => state.home)
  const navItem = [
    {
      name: "Home",
      path: routesKey.HOME,
      id: 1,
      isIconShow: false
    },
    {
      name: "About OTA",
      path: routesKey.ABOUT,
      id: 2,
      isIconShow: false
    },
    {
      name: "District Taekwondo Association",
      path: routesKey.DTA,
      id: 3,
      isIconShow: false
    },
    {
      name: "Registered Club",
      path: "",
      id: 4,
      isIconShow: true
    },
    {
      name: "Forms",
      path: "",
      id: 5,
      isIconShow: true
    },
    {
      name: "Online Registration",
      path: routesKey.ONLINEREGISTRATION,
      id: 6,
      isIconShow: false
    },
    {
      name: "Competition",
      path: routesKey.COMPETITION,
      id: 8,
      isIconShow: false
    },
    {
      name: "Contacts",
      path: routesKey.CONTACT,
      id: 7,
      isIconShow: false
    },
  ]

  useEffect(() => {
    if (document.body.style.overflow === "auto" && sideBarOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [sideBarOpen])

  const handleClick = (id) => {
    if (id === isDropDownShow) {
      setIsDropDownShow("")
    } else {
      setIsDropDownShow(id)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  async function handleDownload(link) {
    downloadImage(link)
  }

  return (
    <div className='max-md:w-full overflow-hidden'>
      <div>
        <Header />
        <div className='md:hidden'>
          <div className='mt-3'>
            <p className="text-center  text-[20px] font-['Quicksand'] font-semibold  text-[#002642]">
              ODISHA TAEKWONDO ASSOCIATION
            </p>
          </div>
          <div className='bg-[#002642] w-full '>
            <div className='flex justify-between items-center  mx-5'>
              <Link to={'/'}>
                <img alt='' src={Logo} className='w-18 h-12 my-3' />
              </Link>
              <button onClick={() => setSideBarOpen(!sideBarOpen)}>
                {
                  sideBarOpen ?
                    <>
                      <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="18" cy="18" r="18" fill="#F5F5F5" />
                        <path d="M11 11L24.6846 24.6846" stroke="#002642" stroke-width="1.5" stroke-linecap="round" />
                        <path d="M11 25L24.6846 11.3154" stroke="#002642" stroke-width="1.5" stroke-linecap="round" />
                      </svg>


                    </> :
                    <Bar />
                }
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-[#F5F5F5]   max-md:min-h-0 min-h-[218px]'>

        {children}
      </div>
      <Footer />
      {
        sideBarOpen &&
        <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="700" className='md:hidden   fixed top-[105px] right-0 h-[70%] shadow-2xl overflow-auto shadow-black rounded-b-[30px] w-full bg-[#fff] z-50'>
          <div className='flex flex-col mt-5'>
            {/* <div  className='flex justify-end pr-5'>
              <button
             
                onClick={()=>setSideBarOpen(false)}
                className='text-white text-2xl focus:outline-none'
              >
                ✕
              </button>
            </div> */}
            <div className='flex flex-col gap-4'>
              {
                navItem?.map((v) =>
                  <>
                    <Link to={v?.path} onClick={() => handleClick(v?.id)} className='flex justify-center items-center gap-2 nav_bar'>
                      <div data-aos="fade-left" data-aos-easing="linear" data-aos-duration="1200" className='text-[#002642] nav_item text-center hover:text-sky-900 hover:font-bold font-["Quicksand"] text-[22px] font-normal self-start '>
                        {v?.name}
                      </div>
                      <div>
                        {

                          v?.isIconShow &&
                          (
                            v?.isIconShow && isDropDownShow === v?.id ?

                              <svg width="17" height="11" viewBox="0 0 17 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 9.5L8.5 2L1 9.5" stroke="#002642" stroke-width="1.5" stroke-linecap="round" />
                              </svg>
                              :
                              <svg width="17" height="10" viewBox="0 0 17 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L8.5 8.5L16 1" stroke="#002642" stroke-width="1.5" stroke-linecap="round" />
                              </svg>


                          )
                        }
                      </div>
                    </Link>
                    {/* form list */}
                    {
                      isDropDownShow === 5 && v?.id === 5 ?
                        <div className='flex flex-col  gap-3 '>
                          {
                            formList?.map((form, i) =>
                              <div key={i} onClick={() => handleDownload(form?.ota_form)} className='w-full flex flex-col justify-center  px-11 p-1 bg-white border-b-[1px] border-[#b5b5b5]'>
                                <div className='flex items-center   gap-3'>
                                  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1589 11.4333H14.3179V12.2865C14.3179 13.2293 13.565 14 12.629 14H3.3709C2.4417 14 1.68884 13.2362 1.68884 12.2865V11.4333H0.84103C0.373037 11.4333 0 11.0479 0 10.58V5.45336C0 4.97854 0.37982 4.60007 0.84103 4.60007H1.68206V1.70658C1.68206 0.76383 2.4417 0 3.3709 0H10.1059H10.9469L14.3179 3.42003V4.27332V4.60007H15.1589C15.6269 4.60007 15.9999 4.98542 15.9999 5.45336V10.58C16.0067 11.0548 15.6269 11.4333 15.1589 11.4333ZM2.52987 12.2865C2.52987 12.7614 2.90969 13.1398 3.3709 13.1398H12.6358C13.1038 13.1398 13.4768 12.7545 13.4768 12.2865V11.4333H2.52987V12.2865ZM10.1059 7.88936C10.1059 7.00166 9.79393 6.38234 9.2988 5.9901C8.83759 5.62539 8.2543 5.45336 7.34544 5.45336C6.80284 5.45336 6.28737 5.49464 5.88721 5.55658V10.5043C6.15851 10.5387 6.55189 10.58 7.10806 10.58C8.03726 10.58 8.80368 10.3735 9.30558 9.93312C9.77358 9.52024 10.1059 8.85275 10.1059 7.88936ZM1.68206 5.55658V10.58H2.73335V8.75641C2.8283 8.77017 2.95717 8.77705 3.09282 8.77705C3.71681 8.77705 4.25262 8.6119 4.61888 8.2403C4.89696 7.95129 5.05296 7.53152 5.05296 7.02919C5.05296 6.53373 4.84948 6.10708 4.55106 5.85247C4.23906 5.5841 3.77107 5.44647 3.11995 5.44647C2.46883 5.45336 2.0144 5.49464 1.68206 5.55658ZM13.4768 4.27332H11.788C10.8656 4.27332 10.1059 3.50949 10.1059 2.56675V0.853288H3.3709C2.90291 0.853288 2.52987 1.23864 2.52987 1.70658V4.59319H13.4836L13.4768 4.27332ZM14.3179 5.45336H10.9469V10.58H12.1949V8.51556H14.1754V7.57281H12.1949V6.40298H14.3111V5.45336H14.3179ZM4.00167 7.07047C4.00167 7.59346 3.65577 7.90312 3.08604 7.90312C2.93004 7.90312 2.82152 7.89624 2.73335 7.87559V6.36169C2.80795 6.34105 2.95717 6.32041 3.17421 6.32041C3.70324 6.32041 4.00167 6.60254 4.00167 7.07047ZM6.97919 6.36858C7.06736 6.34793 7.22336 6.32041 7.46753 6.32041C8.39673 6.32041 8.97324 6.87092 8.96646 7.91688C8.96646 9.12112 8.32891 9.68539 7.35223 9.67851C7.21658 9.67851 7.06736 9.67851 6.97919 9.65786V6.36858Z" fill="#002642" />
                                  </svg>
                                  <p className='text-[18px] w-[100%] text-[#002642] font-["Quicksand"] font-normal line-clamp-1'>{form?.description}</p>
                                </div>
                              </div>
                            )}

                        </div> : ""
                    }
                    {/* city list */}

                    {
                      isDropDownShow === 4 && v?.id === 4 ?
                        <div className='w-[80%] justify-between flex mx-auto '>
                          <div className='flex flex-col'>
                            {
                              districtList?.slice(0, districtList?.length / 2)?.map((v, i) =>
                                <Link onClick={() => setSideBarOpen(false)} to={`/register-club/${v?.city_id}&${v.city_name}`} className='flex items-center gap-4'>
                                  <img alt='' src={v?.city_image} className=' h-8 w-8 rounded-full' />
                                  <p className='text-[#002642] text-[14px] font-medium font-[#Quicksand"] '>{v?.city_name}</p>
                                </Link>
                              )
                            }
                          </div>
                          <div className='w-1 border-l-[1px] border-[#c5c5c5]'></div>
                          <div className='flex flex-col'>

                            {
                              districtList?.slice(districtList?.length / 2, districtList?.length)?.map((v, i) =>
                                <Link onClick={() => setSideBarOpen(false)} to={`/register-club/${v?.city_id}&${v.city_name}`} className='flex items-center gap-4'>
                                  <img alt='' src={v?.city_image} className=' h-8 w-8 rounded-full' />
                                  <p className='text-[#002642] text-[14px] font-medium font-[#Quicksand"] '>{v?.city_name}</p>
                                </Link>
                              )
                            }
                          </div>



                        </div>
                        : ""
                    }
                  </>
                )
              }
            </div>


          </div>
        </div>
      }

    </div>
  )
}

export default Template
import axiosConfig from "../axios_config/axios_instance"
import { getBlackBeltRegistredPlayerFail, getBlackBeltRegistredPlayerRequest, getBlackBeltRegistredPlayerSuccess, getCalendarEventListFail, getCalendarEventListRequest, getCalendarEventListSuccess, getCoachesProfilesFail, getCoachesProfilesRequest, getCoachesProfilesSuccess, getColorBeltRegistredPlayerFail, getColorBeltRegistredPlayerRequest, getColorBeltRegistredPlayerSuccess, getCouncilMembersFail, getCouncilMembersRequest, getCouncilMembersSuccess, getEventResultFail, getEventResultRequest, getEventResultSuccess, getMedalListFail, getMedalistRequest, getMedalistSuccess, getPromotionTestListFail, getPromotionTestListRequest, getPromotionTestListSuccess, getRefreeProfilesFail, getRefreeProfilesRequest, getRefreeProfilesSuccess, getTechnicalCommitiesListFail, getTechnicalCommitiesListRequest, getTechnicalCommitiesListSuccess, updateBlackBeltRegistredPlayerSuccess, updateCoachesProfilesSuccess, updateColorBeltRegistredPlayerSuccess, updateEventResultSuccess, updatePromotionTestListSuccess, updateRefreeProfilesSuccess, updateTechnicalCommitiesListSuccess } from "../redux/slices/aboutSlice"

export const getCouncilMemberList = (page, page_size) => async (dispatch) => {
    try {
        dispatch(getCouncilMembersRequest())
        const {data}=await axiosConfig.get(`/executive-council-members?page=${page-1}&page_size=${page_size}`)
        dispatch(getCouncilMembersSuccess(data?.data))
    } catch (error) {
        dispatch(getCouncilMembersFail())
        
    }
}

export const getColorBeltRegistredPlayers = (page,page_size,city_id,athlete_name) => async (dispatch) => {
    try {
        let url=`/color-belts?page=${page-1}&page_size=${page_size}`
        if (city_id) {
            url=`/color-belts?page=${page-1}&page_size=${page_size}&city_id=${city_id}`
        }
        if (athlete_name) {
            url=url+`&athlete_name=${athlete_name}`
        }
        dispatch(getColorBeltRegistredPlayerRequest())
        const { data } = await axiosConfig.get(url);
        dispatch(getColorBeltRegistredPlayerSuccess(data?.data))
    } catch (error) {
        dispatch(getColorBeltRegistredPlayerFail())
    }
}
export const updateColorBeltRegistredPlayers = (page,page_size,city_id,athlete_name) => async (dispatch) => {
    try {
        let url=`/color-belts?page=${page-1}&page_size=${page_size}`
        if (city_id) {
            url=`/color-belts?page=${page-1}&page_size=${page_size}&city_id=${city_id}`
        }
        if (athlete_name) {
            url=url+`&athlete_name=${athlete_name}`
        }
        dispatch(getColorBeltRegistredPlayerRequest())
        const { data } = await axiosConfig.get(url);
        dispatch(updateColorBeltRegistredPlayerSuccess(data?.data))
    } catch (error) {
        dispatch(getColorBeltRegistredPlayerFail())
    }
}

export const getBlackBeltRegistredPlayers = (page,page_size,city_id,athlete_name) => async (dispatch) => {
    try {
        let url=`/black-belts?page=${page-1}&page_size=${page_size}`
        if (city_id) {
            url=`/black-belts?page=${page-1}&page_size=${page_size}&city_id=${city_id}`
        }
        if (athlete_name) {
            url=url+`&athlete_name=${athlete_name}`
        }
        dispatch(getBlackBeltRegistredPlayerRequest())
        const { data } = await axiosConfig.get(url);
        dispatch(getBlackBeltRegistredPlayerSuccess(data?.data))
    } catch (error) {
        dispatch(getBlackBeltRegistredPlayerFail())
    }
}
export const updateBlackBeltRegistredPlayers = (page,page_size,city_id,athlete_name) => async (dispatch) => {
    try {
        let url=`/black-belts?page=${page-1}&page_size=${page_size}`
        if (city_id) {
            url=`/black-belts?page=${page-1}&page_size=${page_size}&city_id=${city_id}`
        }
        if (athlete_name) {
            url=url+`&athlete_name=${athlete_name}`
        }
        dispatch(getBlackBeltRegistredPlayerRequest())
        const { data } = await axiosConfig.get(url);
        dispatch(updateBlackBeltRegistredPlayerSuccess(data?.data))
    } catch (error) {
        dispatch(getBlackBeltRegistredPlayerFail())
    }
}
export const getColorBeltPromotionTestList = (page,page_size,city_id) => async (dispatch) => {
    try {
        let url=`/colour-belt-promotion-tests?page=${page-1}&page_size=${page_size}`
        if (city_id) {
            url=`/colour-belt-promotion-tests?page=${page-1}&page_size=${page_size}&city_id=${city_id}`
        }
        dispatch(getPromotionTestListRequest())
        const { data } = await axiosConfig.get(url);
        dispatch(getPromotionTestListSuccess(data?.data))
    } catch (error) {
        dispatch(getPromotionTestListFail())
    }
}
export const updateColorBeltPromotionTestList = (page,page_size,city_id) => async (dispatch) => {
    try {
        let url=`/colour-belt-promotion-tests?page=${page-1}&page_size=${page_size}`
        if (city_id) {
            url=`/colour-belt-promotion-tests?page=${page-1}&page_size=${page_size}&city_id=${city_id}`
        }
        dispatch(getPromotionTestListRequest())
        const { data } = await axiosConfig.get(url);
        dispatch(updatePromotionTestListSuccess(data?.data))
    } catch (error) {
        dispatch(getPromotionTestListFail())
    }
}

export const getMedalists = (page, page_size, type) => async (dispatch) => {
    try {
        dispatch(getMedalistRequest())
        const {data}=await axiosConfig.get(`/ota-medalist-list?page=${page-1}&page_size=${page_size}&type=${type}`)
        dispatch(getMedalistSuccess(data?.otaMedalistDTOList))
    } catch (error) {
        dispatch(getMedalListFail())
    }
}
export const getEventsResults = (page, page_size, type) => async (dispatch) => {
    try {
        dispatch(getEventResultRequest())
        const {data}=await axiosConfig.get(`/event-results?page=${page-1}&page_size=${page_size}&type=${type}`)
        dispatch(getEventResultSuccess(data?.data))
    } catch (error) {
        dispatch(getEventResultFail())
    }
}
export const updateEventsResults = (page, page_size, type) => async (dispatch) => {
    try {
        dispatch(getEventResultRequest())
        const {data}=await axiosConfig.get(`/event-results?page=${page-1}&page_size=${page_size}&type=${type}`)
        dispatch(updateEventResultSuccess(data?.data))
    } catch (error) {
        dispatch(getEventResultFail())
    }
}
export const getCoachProfiles = (page, page_size, status) => async (dispatch) => {
    try {
        dispatch(getCoachesProfilesRequest())
        const {data}=await axiosConfig.get(`/coach-profiles?page=${page-1}&page_size=${page_size}&status=${status}`)
        dispatch(getCoachesProfilesSuccess(data?.data))
    } catch (error) {
        dispatch(getCoachesProfilesFail())
    }
}
export const updateCoachProfiles = (page, page_size, status) => async (dispatch) => {
    try {
        dispatch(getCoachesProfilesRequest())
        const {data}=await axiosConfig.get(`/coach-profiles?page=${page-1}&page_size=${page_size}&status=${status}`)
        dispatch(updateCoachesProfilesSuccess(data?.data))
    } catch (error) {
        dispatch(getCoachesProfilesFail())
    }
}
export const getRefreeeProfiles = (page, page_size, status) => async (dispatch) => {
    try {
        dispatch(getRefreeProfilesRequest())
        const {data}=await axiosConfig.get(`/referee-profiles?page=${page-1}&page_size=${page_size}&status=${status}`)
        dispatch(getRefreeProfilesSuccess(data?.data))
    } catch (error) {
        dispatch(getRefreeProfilesFail())
    }
}
export const updateRefreeeProfiles = (page, page_size, status) => async (dispatch) => {
    try {
        dispatch(getRefreeProfilesRequest())
        const {data}=await axiosConfig.get(`/referee-profiles?page=${page-1}&page_size=${page_size}&status=${status}`)
        dispatch(updateRefreeProfilesSuccess(data?.data))
    } catch (error) {
        dispatch(getRefreeProfilesFail())
    }
}
export const getTechnicalCommitiesList = (page, page_size, status) => async (dispatch) => {
    try {
        dispatch(getTechnicalCommitiesListRequest())
        const {data}=await axiosConfig.get(`/technical-committees?page=${page-1}&page_size=${page_size}&status=${status}`)
        dispatch(getTechnicalCommitiesListSuccess(data?.data))
    } catch (error) {
        dispatch(getTechnicalCommitiesListFail())
    }
}
export const updateTechnicalCommitiesList = (page, page_size, status) => async (dispatch) => {
    try {
        dispatch(getTechnicalCommitiesListRequest())
        const {data}=await axiosConfig.get(`/technical-committees?page=${page-1}&page_size=${page_size}&status=${status}`)
        dispatch(updateTechnicalCommitiesListSuccess(data?.data))
    } catch (error) {
        dispatch(getTechnicalCommitiesListFail())
    }
}

export const getCalendarEvents = (type) =>async (dispatch) => {
    try {
        dispatch(getCalendarEventListRequest())
        const {data}=await axiosConfig.get(`/ota-event-calender-list?event_calender_type=${type}&page=0&page_size=10`)
        dispatch(getCalendarEventListSuccess(data?.otaEventCalenderDTOList))
    } catch (error) {
        dispatch(getCalendarEventListFail())
    }
}
import React, { useCallback, useEffect, useRef, useState } from 'react'
// Import Swiper styles
import ReactangleFrame from "../../assets/Taekwondo Images/Images/Rectangle Frame.png"
import BackFrame from "../../assets/Taekwondo Images/Images/backframe.png"
import "swiper/swiper-bundle.min.css";
import { ReactComponent as RightNavigation } from "../../assets/icons/right_navigation.svg"
import { ReactComponent as PdfIcon } from "../../assets/icons/pdf_icon.svg"
import { ReactComponent as DateIcon } from "../../assets/icons/date_icon.svg"
import { ReactComponent as JoinNow } from "../../assets/icons/join_btn.svg"
import TopBanner from "../../assets/Taekwondo Images/Images/1.1 .png"
import BannerAvtar from "../../assets/Taekwondo Images/Images/1.png"
import OwnerBG from "../../assets/Taekwondo Images/Images/owner bg.jpg"
import OwnerImg from "../../assets/Taekwondo Images/Images/mm bhanja.png"
import PrideImg1 from "../../assets/Taekwondo Images/Images/Our Pride 1.png"
import Otaimg from "../../assets/Taekwondo Images/Images/2.png"
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Autoplay } from "swiper"
// import { Autoplay, Navigation, Pagination, Virtual } from 'swiper/modules'
import 'swiper/swiper-bundle.css';
import MobileView from './MobileView';
import Template from '../../components/Template';
import Footer from '../../components/common/Footer';
import Header from '../../components/common/Header';
import { ReactComponent as PauseIcon } from "../../assets/icons/pause_icon.svg"
import VideoImg1 from "../../assets/Taekwondo Images/Images/Video main.png"
import { Autoplay, Navigation, Pagination } from 'swiper';
import { Link } from 'react-router-dom';
import * as routesKey from "../../constants/routes"
import { useDispatch, useSelector } from 'react-redux';
import { getGalleryList, getLatestInfo, getNewsList, getOwnerInfo, getPrideList, getVideoList } from '../../service/api';
import GallerySlider from './GallerySlider';
import moment from 'moment';
import axios from 'axios';
import { downloadImage } from '../../components/helper/downloadImage';
import axiosConfig from '../../axios_config/axios_instance';
import { v4 as uuidv4 } from 'uuid';
import Logo from "../../assets/Taekwondo Images/Images/Logo.png"

const Home = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDropdown, setShowDropdown] = useState("")
  const [bannerList, setBannerList] = useState([])
  const handleSlideChange = (index) => {
    setCurrentIndex(index);
  };
  const { prideList, latestInfos, videoList, galleryList, ownerInfo, newsList } = useSelector(state => state.home)

  const dispatch = useDispatch()

  const getImageFromVideo = (uri) => {
    return `https://img.youtube.com/vi/${uri?.split("=")[1]}/maxresdefault.jpg`;
  }
  const handleImageError = (e, uri) => {
    let url = `https://img.youtube.com/vi/${uri?.split("=")[1]}/mqdefault.jpg`;
    e.target.onerror = null;
    e.target.src = url;

  }

  const newsItems = [
    {
      key: 'important annoucement!',
    },
    {
      key: 'important annoucement!',
    },
    {
      key: 'important annoucement!',
    },

  ];
  const [items, setItems] = useState(newsItems);
  const [animationRunning, setAnimationRunning] = useState(false);
  const wrapperRef = useRef();
  const indexRef = useRef();

  const handleRefUpdate = useCallback((node) => {
    if (node !== null && items.length > 0) {
      indexRef.current = node.firstChild;
      wrapperRef.current = node;
      document.documentElement.style.setProperty(
        '--animationDistance',
        `${0 - indexRef.current.offsetWidth}px`
      );
      document.documentElement.style.setProperty(
        '--animationDuration',
        `${Math.round(indexRef.current.offsetWidth / 33)}s`
      );
      wrapperRef.current.classList.add('moving');
    }
  }, [items]);

  const handleLoop = () => {
    wrapperRef.current.classList.remove('moving');
    wrapperRef.current.style.animation = 'none';
    const t = wrapperRef.current.offsetHeight; /* trigger reflow */
    wrapperRef.current.style.animation = null;
    shiftNext([...items]);
  };

  const shiftNext = (copy) => {
    const firstitem = copy.shift();
    copy.splice(copy.length, 0, firstitem);
    setItems(copy);
  };

  const handleAnimationEnd = () => {
    handleLoop();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleLoop();
    }, 3000); // Adjust the interval as needed (e.g., 3000 milliseconds for 3 seconds)

    return () => clearInterval(interval);
  }, []);

  const redirectToYouTube = () => {
    window.location.href = 'https://www.youtube.com/watch?v=Ot-rBhiIUKs'; // Replace with the desired YouTube URL
  };

  useEffect(() => {
    dispatch(getPrideList())
    dispatch(getLatestInfo())
    dispatch(getGalleryList())
    dispatch(getOwnerInfo())
    dispatch(getNewsList())
  }, [dispatch])


  useEffect(() => {
    dispatch(getVideoList())
  }, [dispatch])

  //visitors api

  useEffect(()=>{
     const saveVisitor= async()=>{

      try {
        const system_id= localStorage.getItem("system_id")
        if(system_id){
         return;
        }else{
          const uniqueId = uuidv4().split('-').pop().slice(0, 6);
          localStorage.setItem("system_id",uniqueId)
          const {data} = await axiosConfig.post(`/save-visitors?system_id=${uniqueId}`)
        }
      } catch (error) {
        
      }
     }
     saveVisitor()
  },[])


  useEffect(()=>{
    const getBanners=async()=>{
      try {
        const {data} = await axiosConfig.get(`/template-list?page=0&page_size=100`)
        setBannerList(data?.template_image_list)
      } catch (error) {
        
      }
    }
    getBanners()
  },[])

  return (



    <Template>
      <div className='max-md:hidden'>

        {/*  banner */}

        <div className='p-7 '>
          <div className="relative h-[500px] flex flex-col justify-between">

          <>
      <Swiper navigation={true} autoplay={{
            delay: 4000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }} modules={[Navigation,Autoplay]} className="mySwiper w-full h-full">
        {
          bannerList?.map((banner,i)=>
          <SwiperSlide key={i} className=' h-[100%] w-full'>
            <img alt='banner' src={banner?.template_image} className='w-full h-full object-contain'/>
          </SwiperSlide>
          )
        }
        
      </Swiper>
    </>


            {/* <img
              src={TopBanner}
              alt="Description"
              className="w-full "
            />
            <div className="absolute top-0 left-0 flex  text-white m-12">
              <div className='w-[55%] flex flex-col  gap-10'>

                <h2 className="text-[66px] font-bold  font-['Quicksand']">Train Yourself Like  a Warrior</h2>
                <p className='text-[26px] font-normal font-["Quicksand"] text-white'>Enhance your  physical and mental well - being with pur holistic martial arts training</p>
                <Link to={routesKey.CONTACT} className='bg-white rounded-xl flex justify-center font-bold text-[31px] font-["Quicksand"] text-[#002642] w-[168px] h-[60px]  items-center text-center'>
                  Join Now
                </Link>
              </div>
              <div className='w-[45%]'>
                <img alt="" src={BannerAvtar} className='h-[80%] bottom-56 w-full' />
              </div>
            </div> */}
             {/* auto scroller */}
       
          </div>
          <div className='pt-7 my-12'>

<div className=' w-full   overflow-hidden rounded-md shadow-md  bg-sky-200 '>
  <div className='marquee min-w-full py-3 '>

    <div className='min-w-full flex gap-14'>

      <p className='text-[30px] font-["Quicksand"]'>Welcome to Odisha Taekwondo Association</p>
      <p className='text-[30px] font-["Quicksand"]'>Welcome to Odisha Taekwondo Association</p>
      <p className='text-[30px] font-["Quicksand"]'>Welcome to Odisha Taekwondo Association</p>
    </div>

  </div>
</div>
</div>
        </div>
       

       
        <div className="overflow-hidden bg-gray-800  text-white">
          <div className="relative">
            <div
              className="absolute top-0 bottom-0 left-0 flex moving"
              ref={wrapperRef}
              onAnimationEnd={handleAnimationEnd}
            >
              {items.map((obj, index) => (
                <div key={index} className="inline-block px-4 ">{obj.key}</div>
              ))}
            </div>
          </div>
        </div>


        {/* auto scroller bottom */}
        <div className='py-5'>
          <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:items-stretch">
            <div className="flex flex-col  justify-center  items-center w-[20%] max-md:ml-0 max-md:w-full">
              <img alt='' src={Logo} />

            </div>
            <div className="flex flex-col items-stretch ml-5 w-[80%] max-md:ml-0 max-md:w-full">
              <div className="flex flex-col items-stretch self-stretch px-5 my-auto max-md:mt-10 max-md:max-w-full">
                <div className="text-[50px] font-bold font-['Quicksand'] text-[#002642] max-md:max-w-full max-md:text-4xl">
                  Odisha Taekwondo <br />
                  Association
                </div>
                <div className="mt-1 text-2xl font-['Quicksand'] text-neutral-700 max-md:mt-10 max-md:max-w-full">
                  Odisha Taekwondo Association (OTA) is the Apex body for running & managing TAEKWONDO in the State of Odisha .OTA is also promoting the Para Taekwondo in the State. OTA leads the most complete and reachable combat sport. Taekwondo  evolves on a solid base, mixing the traditional and the modern. The values recognized by practitioners and partners are the strength of our sport. They are distilled from those found in our society: the search for pleasure, surpassing oneself, perseverance, moral and physical strength, and respect for others.
                </div>
              </div>
            </div>
          </div>
          <Link to={routesKey.ABOUT} className='w-full cursor-pointer justify-end items-center gap-5 flex pr-7 text-2xl font-bold text-sky-950'>
            <div className=' text-2xl font-bold text-sky-950 font-["Quicksand"]'>Read More</div>
            <div className=''><RightNavigation /></div>
          </Link>
        </div>

        {/* our pride */}
        <div className='m-7'>
          <div className="flex flex-col items-center px-5">
            <div className="text-5xl font-bold whitespace-nowrap font-['Quicksand']  text-sky-950 max-md:text-4xl">
              Our Prides
            </div>
            <div className="mt-12 text-2xl text-center font-['Quicksand']  text-neutral-700 max-md:mt-10 max-md:max-w-full">
            Successful Taekwondo students exhibit discipline, respect, and perseverance. They demonstrate mastery of techniques, excel in competitions, and embody the tenets of Taekwondo both on and off the mat.
            </div>
            <>
              {/* <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 6500,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 4,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="team-slider"
        >
         {
            [1,7]?.map((team) => (
              <SwiperSlide className="single-team">
                <div className='w-[316px] h-[356px] bg-white rounded-[29px] shadow-xl '>
            <div className='p-2 h-full w-full '>
            <div className='h-full w-full border-[1.22px]  flex flex-col justify-center items-center rounded-[28px] border-[#154162] '>
             

                 <img alt='' src="https://media.istockphoto.com/id/1334828664/photo/indian-architect.jpg?s=612x612&w=0&k=20&c=W7fOG_JIbP6WEcSMR3lOjUjSRLEFXHdtkOkUUz0ztT0=" className='h-[205px] rounded-[10px] w-[280px]'/>
                 <div className='items-center w-full mt-7 flex flex-col gap-3'>

                 <p className='font-bold text-[27px] text-center font-["Quicksand"]'>Mr. Dhabalesh Mahapatra</p>
                 <p className='font-medium text-center text-[24px] font-["Quicksand"]'>President</p>
                 </div>
                
            </div>
            </div>
          </div>
          </SwiperSlide>
          ))
         }
        </Swiper> */}
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                autoplay={{
                  delay: 4000,
                  disableOnInteraction: true,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  576: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1200: {
                    slidesPerView: 4,
                  },
                  1500: {
                    slidesPerView: 4,
                  },
                }}
                centeredSlides={false}
                modules={[Pagination, Autoplay]}
                className="team-slider"
              >
                {
                  prideList?.map((pride, i) => (
                    <SwiperSlide className="single-team" key={i}>
                      <label
                        key={pride.otaPridesId}
                        className="flex flex-col relative justify-center items-center shadow-lg gap-1 rounded-lg p-6 group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"
                      >
                        {/* Add background image to the card */}
                        <div className="absolute top-6 left-0 p-1 w-full h-full bg-cover bg-center rounded-lg">

                          <img
                            alt=''
                            src={ReactangleFrame}
                          />
                        </div>
                        <div className="absolute top-14 left-2 w-full h-full bg-cover bg-center rounded-lg">
                          <img
                            src={BackFrame}
                            alt=''
                          />
                        </div>
                        <img src={pride?.pride_image} alt="pattern_image" className="h-[200px] w-[200px] relative z-10" />
                        <span className="text-base font-semibold">{pride?.pride_name}</span>
                        <span className="text-base font-semibold">{pride?.description}</span>
                      </label>
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </>


          </div>
        </div>
        {/* latest information */}

        <div className='m-7 py-9'>
          <div className='w-full max-h-[800px]    bg-white shadow-xl'>
            <div className='text-[50px] font-["Quicksand"] font-bold text-center text-[#002642] '>
              Latest Information
            </div>
            <div className='my-7 min-h-[600px] max-h-[600px]  '>
              {
                latestInfos?.length ?
                  <>
                    {
                      latestInfos?.slice(0, 4).map((v, i) =>
                        <div className='border-b-2' key={i}>
                          <div className='flex gap-5 m-5'>
                            <div className=' '>
                              <CalenderIcon month={moment(v?.date).format("MMM")} date={moment(v?.date).format("DD")} />
                            </div>
                            <div>
                              <p onClick={() => downloadImage(v?.ota_notices_pdf)} className='text-2xl  text-[#444444] font-["Quicksand"] inline-block'>{`${v?.description} `} <PdfIcon className='cursor-pointer inline-block' /></p>
                              {/* <div className='flex items-center gap-3'>
              <p className='text-2xl text-[#444444] font-["Quicksand"]'>ullamcorper urna hendrerit.</p>
              <PdfIcon className='cursor-pointer'/>
          </div> */}

                            </div>
                          </div>

                        </div>
                      )
                    }
                  </> :
                  <div className='flex mt-32 justify-center items-center text-[32px] font-["Quicksand"] font-medium '>
                    No Data
                  </div>
              }
            </div>
          </div>
          {
            latestInfos?.length > 4 ?
              <Link to={routesKey.LATESTINFO} className='w-full cursor-pointer justify-center items-center gap-5 flex pr-7 text-2xl font-bold text-sky-950'>
                <div className=' text-2xl font-bold text-sky-950 font-["Quicksand"]'>View More</div>
                <div className=''><RightNavigation /></div>
              </Link> : ""
          }

        </div>

        {/* our gallery */}
        <div className='m-7 '>

          <div className="flex flex-col items-center px-5">
            <div className="text-5xl font-bold font-['Quicksand']  whitespace-nowrap text-sky-950 max-md:text-4xl">
              Our Gallery
            </div>
            <div className="mt-10 text-2xl font-['Quicksand']  text-center text-neutral-700 max-md:max-w-full">
            Explore our dynamic Taekwondo gallery showcasing the artistry, discipline, and athleticism of our practitioners. Witness powerful kicks, precise forms, and inspiring moments captured in stunning imagery.
            </div>
            {/* <div className="body">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 3,
            },
            1500: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="team-slider"
        >
          <SwiperSlide className="swiper-slide">Slide 1</SwiperSlide>
          <SwiperSlide className="swiper-slide">Slide 2</SwiperSlide>
          <SwiperSlide className="swiper-slide">Slide 3</SwiperSlide>
          <SwiperSlide className="swiper-slide">Slide 4</SwiperSlide>
        </Swiper>
          <SwiperSlide className="swiper-slide">Slide 5</SwiperSlide>
        </div> */}
            {/* <div className="swiper-button-prev"></div> */}


            {/* <div className="swiper-button-next"></div> */}

            {/* <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 3,
            },
            1500: {
              slidesPerView: 3,
            },
          }}
          modules={[Pagination, Autoplay]}
          className="team-slider image_Slider_container"
          onSlideChange={(val)=>console.log("Value",val)}
        >
         {
            [1,2,4,5,6,6,7]?.map((team) => (
              <SwiperSlide className="single-team">
             <div className='w-[316px] h-[356px] bg-white rounded-[29px] shadow-xl '>
            <div className='p-2 h-full w-full '>
            <div className='h-full w-full border-[1.22px]  flex flex-col justify-center items-center rounded-[28px] border-[#154162] '>
             

                 <img alt='' src="https://media.istockphoto.com/id/1334828664/photo/indian-architect.jpg?s=612x612&w=0&k=20&c=W7fOG_JIbP6WEcSMR3lOjUjSRLEFXHdtkOkUUz0ztT0=" className='h-[205px] rounded-[10px] w-[280px]'/>
                 <div className='items-center w-full mt-7 flex flex-col gap-3'>

                 <p className='font-bold text-[27px] text-center font-["Quicksand"]'>Mr. Dhabalesh Mahapatra</p>
                 <p className='font-medium text-center text-[24px] font-["Quicksand"]'>President</p>
                 </div>
                
            </div>
            </div>
          </div>
          </SwiperSlide>
          ))
         }
        </Swiper> */}

            <GallerySlider />
          </div>
        </div>
        {/* mastery showcase */}

        <div className='m-7'>
          <div className='flex justify-between items-center py-5'>
            <p className='text-[50px] font-["Quicksand"] font-bold'>Taekwondo Mastery Showcase</p>
            {
              // videoList?.length>6?
              true ?
                <Link to={routesKey.VIDEOLIST} className='flex gap-2 items-center'>
                  <p className='text-[20px] cursor-pointer font-["Quicksand"] font-bold'>View More</p>
                  <RightNavigation />
                </Link> : ""
            }
          </div>
          {/* video image */}
          <div className='w-full flex justify-between h-[698px]'>
            {/* left side */}
            <div className='h-full flex flex-col justify-between w-[69%]'>
              {/*left main img */}
              <div className='relative rounded-md w-full h-[66%]'>

                <img alt='' src={getImageFromVideo(videoList[0]?.video_link)} className='w-full h-full' onError={(e) => handleImageError(e, videoList[0]?.video_link)} />
                <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
                  <Link to={routesKey.VIDEOLIST}>

                    <PauseIcon className='cursor-pointer' />
                  </Link>
                </div>
                <div className='absolute w-full bottom-0 left-0 '>
                  <div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] mb-4 min-h-7'>
                    <div className='px-7 py-3'>
                      <p className='text-[28px] font-medium text-white font-["Quicksand"]'>{videoList[0]?.description?.slice(0, 80)}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* left bottom img */}
              <div className='w-full flex justify-between h-[32%]'>
                {
                  videoList?.slice(4, 6).map((v, i) =>
                    <div className='relative rounded-md w-[49%] h-full'>

                      <img alt='' src={getImageFromVideo(v?.video_link)} className='w-full h-full' onError={(e) => handleImageError(e, v?.video_link)} />
                      <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
                        <Link to={routesKey.VIDEOLIST}>

                          <PauseIcon className='cursor-pointer' />
                        </Link>
                      </div>
                      <div className='absolute w-full bottom-0 left-0 '>
                        <div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] mb-2 min-h-7'>
                          <div className='px-4 py-2'>
                            <p className='text-[22px] font-normal text-white font-["Quicksand"]'>{v?.description?.slice(0, 55)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>

            </div>

            {/* right side */}
            <div className='h-full flex flex-col justify-between w-[30%]'>
              {
                videoList?.slice(1, 4)?.map((v, i) =>
                  <div key={i} className='relative rounded-md h-[32%] w-full'>

                    <img alt='' src={getImageFromVideo(v?.video_link)} className='w-full h-[100%]' onError={(e) => handleImageError(e, v?.video_link)} />
                    <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
                      <Link to={routesKey.VIDEOLIST}>

                        <PauseIcon className='cursor-pointer' />
                      </Link>
                    </div>
                    <div className='absolute w-full bottom-0 left-0 '>
                      <div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] mb-2 min-h-7'>
                        <div className='px-4 py-2'>
                          <p className='text-[22px] font-normal text-white font-["Quicksand"]'>{v?.description?.slice(0, 55)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>

        {/* NEWS LIST*/}
        <div className='m-7 flex flex-col gap-3'>
          <p className='text-[#002642] text-[50px] font-bold  font-["Quicksand"] text-center'>News</p>
          <p className='text-[#444444] text-[24px] font-["Quicksand"] text-center'>Get top and latest taekwondo News - Read Breaking taekwondo News and taekwondo News Headlines</p>

          <div className='flex justify-between h-[700px] items-center  w-full'>
            <div className='h-full w-[79%] rounded-'>
              <img alt='news' src={newsList[0]?.news_image} className='h-[100%] rounded-  w-full' />

            </div>
            <div className='w-[20%] p-4 h-full bg-[#002642] rounded-lg'>
              <div className='flex w-full h-full flex-col justify-between'>
                {
                  newsList?.slice(1, 4)?.map((news, i) =>
                    <img alt='' src={news?.news_image} className='h-[33%] rounded-lg w-full' />
                  )
                }
              </div>
            </div>

          </div>
          {
            newsList?.length > 4 ?
              <Link to={routesKey.NEWSLIST} className='w-full cursor-pointer justify-center items-center gap-5 flex text-2xl font-bold text-sky-950'>
                <div className=' text-2xl font-bold text-sky-950 font-["Quicksand"]'>View More</div>
                <div className=''><RightNavigation /></div>
              </Link> : ""
          }
        </div>


      </div>
      {/* mobile view */}
      <div className='md:hidden'>

        <MobileView bannerList={bannerList} />
      </div>

    </Template>




  )
}

export default Home



export const CalenderIcon = ({ month, date }) => {
  return (
    <div className='h-[100px] w-[107px] border-[1px] rounded-[6px] border-[#002642]'>
      <div className='h-[50%] w-full rounded-t-[6px] bg-[#002642]'>
        <p className='font-["Quicksand"] font-bold text-[30px] text-center text-white'>{month}</p>
      </div>
      <div className='h-[50%] w-full rounded-b-[6px] bg-[#fff]'>
        <p className='font-["Quicksand"] font-bold text-[30px] text-center text-[#002642]'>{date}</p>

      </div>
    </div>
  )
}
export const MobileCalenderIcon = ({ month, date }) => {
  return (
    <div className='h-[40px] w-[40px] border-[1px] rounded-[6px] border-[#002642]'>
      <div className='h-[20px] w-full rounded-t-[6px] bg-[#002642]'>
        <p className='font-["Quicksand"] font-bold text-[14px] text-center text-white'>{month}</p>
      </div>
      <div className='h-[20px] w-full rounded-b-[6px] bg-[#fff]'>
        <p className='font-["Quicksand"] font-bold text-[14px] text-center text-[#002642]'>{date}</p>
      </div>
    </div>
  )
}
import React, { useEffect } from 'react'
import Template from '../../components/Template'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { CalenderIcon } from './index'
import { ReactComponent as PdfIcon } from "../../assets/icons/pdf_icon.svg"
import { Link } from 'react-router-dom'
import { getLatestInfo } from '../../service/api'
import { downloadImage } from '../../components/helper/downloadImage'
import { ReactComponent as DateIcon } from "../../assets/icons/date_icon.svg"

const LatestInformation = () => {
  const { latestInfos } = useSelector(state => state.home)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLatestInfo())
  }, [dispatch])
  return (
    <Template>
      <div className='p-7 max-md:hidden py-9'>
        <div className='w-full    '>
          <div className='text-[50px] font-["Quicksand"] font-bold text-center text-[#002642] '>
            Latest Information
          </div>
          <div className='my-7  '>
            {
              latestInfos?.length ?
                <>
                  {
                    latestInfos?.map((v, i) =>
                      <div className='border-b-2' key={i}>
                        <div className='flex gap-5 m-5'>
                          <div className=' '>
                            <CalenderIcon month={moment(v?.date).format("MMM")} date={moment(v?.date).format("DD")} />
                          </div>
                          <div>
                            <p className='text-2xl  text-[#444444] font-["Quicksand"]'>{`${v?.description} `} <PdfIcon onClick={() => downloadImage(v?.ota_notices_pdf)} className='cursor-pointer' /></p>
                            {/* <div className='flex items-center gap-3'>
              <p className='text-2xl text-[#444444] font-["Quicksand"]'>ullamcorper urna hendrerit.</p>
              <PdfIcon className='cursor-pointer'/>
          </div> */}

                          </div>
                        </div>

                      </div>
                    )
                  }
                </> :
                <div className='flex py-32 justify-center items-center text-[32px] font-["Quicksand"] font-medium '>
                  No Data
                </div>
            }
          </div>
        </div>


      </div>


      {/* mobile view */}
      <div className='m-5 md:hidden'>
        <div className='w-full max-h-[800px]    bg-white shadow-md shadow-slate-400 rounded-sm'>
          <div className='text-[33px] text-center font-["Quicksand"] text-[#002642] font-medium'>
            Latest Information
          </div>
          <div className='my-7 max-h-[600px]  overflow-auto'>
            {
              latestInfos?.length ?
                <>
                  {
                    latestInfos?.slice(0, 4)?.map((v, i) =>
                      <div className='border-b-2' key={i}>
                        <div className='flex gap-2 m-5'>
                          <div className='w-42 flex justify-center items-center h-46 max-md:h-10 max-md:w-10'>
                            <MobileCalenderIcon month={moment(v?.date).format("MMM")} date={moment(v?.date).format("DD")} />
                          </div>
                          <div>
                            <p className='text-base text-[#444444] font-["Quicksand"] inline-block break-all'>{v?.description} <PdfIcon onClick={() => downloadImage(v?.ota_notices_pdf)} className='cursor-pointer inline-block' /></p>
                          </div>
                        </div>

                      </div>
                    )
                  }
                </> : <div className='text-[24px] text-center my-auto font-["Quicksand"] text-[#002642] font-medium'> No Data</div>
            }
          </div>
        </div>


      </div>
    </Template>
  )
}

export default LatestInformation

export const MobileCalenderIcon = ({ month, date }) => {
  return (
    <div className='h-[40px] w-[40px] border-[1px] rounded-[6px] border-[#002642]'>
      <div className='h-[20px] w-full rounded-t-[6px] bg-[#002642]'>
        <p className='font-["Quicksand"] font-bold text-[15px] text-center text-white'>{month}</p>
      </div>
      <div className='h-[20px] w-full rounded-b-[6px] bg-[#fff]'>
        <p className='font-["Quicksand"] font-bold text-[15px] text-center text-[#002642]'>{date}</p>

      </div>
    </div>
  )
}
import React, { useEffect } from 'react'
import Template from '../../components/Template'
import Banner from "../../assets/Taekwondo Images/1.jpg"
import President from "../../assets/Taekwondo Images/President.png"
import {ReactComponent as Location} from "../../assets/icons/location_icon.svg"
import {ReactComponent as Email} from "../../assets/icons/email_icon.svg"
import {ReactComponent as Phone} from "../../assets/icons/phone_icon.svg"
import {ReactComponent as Hour} from "../../assets/icons/time_icon.svg"
import MobileView from './MobileView'
import { useDispatch, useSelector } from 'react-redux'
import { getContactList } from '../../service/api'
const Contact = () => {
const dispatch = useDispatch()
   useEffect(()=>{
     dispatch(getContactList())
   },[dispatch])
const {contactList} = useSelector(state=>state.home)

  return (
   <Template>
      <div className='max-md:hidden'>

       <div className='w-full my-7'>
        <div className='relative h-80'>
          <img alt='' src={Banner} className='w-full h-full'/>
          <div className='absolute'>
          </div>
          <div className='absolute  top-0 mt-20 w-full'>
          <p className='text-white text-[50px] font-bold text-center bottom-10 font-["Quicksand"]'>For Any Enquiry: Get In Touch</p>

            <div className=' mt-24 mx-14  flex justify-between'>
               <div className='w-[24%] h-[298px] relative bg-white rounded-md shadow-xl'>
                   <div className='rounded-full absolute w-24 h-24 ml-28  bottom-[249px] bg-white flex items-center  shadow-xl'>
                         <div className='h-full w-full justify-center items-center flex'>
                            <Location/> 
                         </div>
                   </div>
                   <div className='mt-14 flex flex-col gap-9'>
                   <p className='font-bold text-center text-[50px] text-[#002642] font-["Quicksand"]'>Address</p>
                   <p className='text-[#444444] text-[18px] font-bold text-center font-["Quicksand"] pb-3'>At- Flat No. C/103, Kalpana sagar complex, 3sipasarubali, baliapanda PIN- 752001, post/Dist.- Puri</p>
                   </div>

               </div>
               <div className='w-[24%] h-[298px] relative bg-white rounded-md shadow-xl'>
               <div className='rounded-full absolute w-24 h-24 ml-28  bottom-[249px] bg-white flex items-center  shadow-xl'>
                         <div className='h-full w-full justify-center items-center flex'>
                            <Email/> 
                         </div>
                   </div>
                         <div className='mt-14 flex flex-col gap-9'>
                   <p className='font-bold text-center text-[50px] text-[#002642] font-["Quicksand"]'>Email</p>
                   <p className='text-[#444444] text-[15px] font-bold text-center font-["Quicksand"] pb-3'>info@odishataekwondoassociation.com</p>
                   </div>
               </div>
               <div className='w-[24%] h-[298px] relative bg-white rounded-md shadow-xl'>
               <div className='rounded-full absolute w-24 h-24 ml-28  bottom-[249px] bg-white flex items-center  shadow-xl'>
                         <div className='h-full w-full justify-center items-center flex'>
                            <Phone/> 
                         </div>
                   </div>
                   <div className='mt-14 flex flex-col gap-9'>
                   <p className='font-bold text-center text-[50px] text-[#002642] font-["Quicksand"]'>Phone</p>
                   <p className='text-[#444444] text-[18px] font-bold text-center font-["Quicksand"] '>9338449446 / 7008130771</p>
                   </div>
               </div>
               <div className='w-[24%] h-[298px] relative bg-white rounded-md shadow-xl'>
               <div className='rounded-full absolute w-24 h-24 ml-28  bottom-[249px] bg-white flex items-center  shadow-xl'>
                         <div className='h-full w-full justify-center items-center flex'>
                            <Hour/> 
                         </div>
                   </div>
                   <div className='mt-14 flex flex-col gap-9'>
                   <p className='font-bold text-center text-[50px] font-["Quicksand"] text-[#002642]'>Open Hour</p>
                   <p className='text-[#444444] text-[18px] font-bold font-["Quicksand"] text-center pb-3'>Monday - Saturday <br/> 09 AM - 05 PM <br/>Sunday : Closed</p>
                   </div>
               </div>
            </div>

          </div>
        </div>
       </div>
       {/* member list */}
       <div className='py-7 pt-60'>
          <p className='text-5xl font-["Quicksand"] font-bold text-center'>OTA OFFICE BEARERS</p>
       </div>
       {/* image card */}
       {
         contactList?.length?
       <div className='pb-28 py-16 mx-24 flex justify-between'>
        {
            contactList?.map((v,i)=>
          <div key={i} className='w-[30%] h-[435px] bg-white rounded-[29px] shadow-xl '>
            <div className='p-2 h-full w-full '>
            <div className='h-full w-full border-[1.22px]  flex flex-col justify-center items-center rounded-[28px] border-[#154162] '>
             

                 <img alt='' src={v?.image} className='h-[205px] rounded-[10px] w-[280px]'/>
                 <div className='items-center w-full mt-7 flex flex-col gap-3'>

                 <p className='font-bold text-[27px] text-center font-["Quicksand"]'>{v?.name}</p>
                 <p className='font-medium text-center text-[24px] font-["Quicksand"]'>{v?.post}</p>
                 </div>
                
            </div>
            </div>
          </div>
            )
        }
       </div>:
       <p className='text-center py-11 text-[32px] font-medium font-["Quicksand"]'>No Data</p>
       }
      </div>
      <div className='md:hidden'>
         <MobileView/>
      </div>
   </Template>
  )
}

export default Contact
import React, { useRef } from 'react'
import {ReactComponent as PrintIcon} from "../../assets/icons/about_print.svg"
// import {ReactComponent as PrintSvg} from "../../assets/icons/MobilePrintIcon.svg"
import bgImg2 from "../../assets/Taekwondo Images/Images/color_belt_certificate_ss.jpg"
import backImage from "../../assets/Taekwondo Images/Images/certificate_back.png"
import blackbeltbackimage from "../../assets/Taekwondo Images/Images/black_belt_back_id_Card.png"
import blackbeltfront from "../../assets/Taekwondo Images/Images/black_belt_id_card.png"
import bgImg from "../../assets/Taekwondo Images/Images/certificate.png"
import userImage from "../../assets/Taekwondo Images/Images/user_demo.png"
import { MobilePrintSvg, PrintSvg } from './MobileView'
import jsPDF from 'jspdf'
import { getCapitalIstLetter } from '../../components/helper/utils'
const ColorBeltCertificate = ({ details }) => {
    const canvasRef2 = useRef();
    const handleDownload2 = (district,certificateNo,name,clubName,date,beltname,ota_id_no) => {
        const canvas = canvasRef2.current;
        const context = canvas.getContext('2d');
      
        // Load your certificate image - Replace with the actual path or URL
        const backgroundImage = new window.Image();
        backgroundImage.onload = async () => {
          canvas.width = backgroundImage.width;
          canvas.height = backgroundImage.height;
          // Draw the background image on the canvas
          context.drawImage(backgroundImage, 0, 0);
          //District Name
          context.fillStyle = "blue";
          context.font = `bold 40px Arial`; // Set font size for each text
          context.fillText(String(district)?.toUpperCase(), 450, 430);
          //Certificate Number
          context.fillStyle = "black";
          context.font = `bold 18px Arial`; // Set font size for each text
          context.fillText(certificateNo, 160, 530);
          //Student Name
          context.fillStyle = "#FF007F";
          context.font = `italic 30px Arial`; // Set font size for each text
          context.fillText(String(name)?.toUpperCase(), 290, 620);
          //Club Name
          context.fillStyle = "blue";
          context.font = `bold 30px Arial`; // Set font size for each text
          context.fillText(String(clubName)?.toUpperCase(), 310, 670);
          //Date
          context.fillStyle = "blue";
          context.font = `bold 20px Arial`; // Set font size for each text
          context.fillText(date, 170, 940);
          //Belt
          context.fillStyle = "#013220";
          context.font = `bold 28px Arial`; // Set font size for each text
          context.fillText(String(beltname)?.toUpperCase(), 567, 940);
          //OTA-ID-NO
          context.fillStyle = "#8B0000";
          context.font = `bold 28px Arial`; // Set font size for each text
          context.fillText(String(ota_id_no)?.toUpperCase(), 525, 1000);
            // Trigger download
            const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
          link.download = `${name}-certificate.png`;
           link.click();
        };
      
        // Replace this path with the actual path or URL to your background image
        backgroundImage.src = bgImg2;
    }
    
    const handleDownload = () => {
        handleDownload2(details?.city_name,details?.certificate_no,details?.player_name,details?.ota_club_name,details?.exam_date,details?.player_present_belt,details?.id)
    }
  return (
      <>
      <PrintIcon className="w-full max-md:hidden flex justify-center" onClick={handleDownload} />
      <div className='hidden max-md:block'>
          <MobilePrintSvg className=" md:hidden  " onClick={ handleDownload} />
      </div>
          <canvas ref={canvasRef2}  style={{display:"none"}}/>
    </>
  )
}

export default ColorBeltCertificate

export const ColorBeltHolderIdCardPrint = ({ details }) => {
    const canvasRef = useRef();
    const handleDownload = (imageLink,ota_id,name,date,gender,dist,fm) => {
      const arr=[
        { text: ota_id?ota_id:"", x: 900, y: 675, fontSize: 100,color:"blue" },
        { text:name?getCapitalIstLetter(name):"", x: 900, y: 930, fontSize: 100,color:"blue" },
        { text: date?date:"", x: 900, y: 1185, fontSize: 100,color:"blue" },
        { text: gender?getCapitalIstLetter(gender):"" ,x: 900, y: 1445, fontSize: 100,color:"blue" },
        { text:fm?getCapitalIstLetter(fm):"", x: 900, y: 1700, fontSize: 100,color:"blue" },
        { text: dist?getCapitalIstLetter(dist):"", x: 900, y: 1960, fontSize: 100,color:"blue" },
        ]
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
    // Set willReadFrequently to true for better performance
    // context.imageSmoothingEnabled = true;
    // context.imageSmoothingQuality = 'high';
    // context.willReadFrequently = true;
      // Load your certificate image - Replace with the actual path or URL
      const backgroundImage = new window.Image();
      backgroundImage.onload = async () => {
        canvas.width = backgroundImage.width;
        canvas.height = backgroundImage.height;
    
        // Draw the background image on the canvas
        context.drawImage(backgroundImage, 0, 0);
    
        // Load and draw the top image on the canvas
        const topImage = new Image();
        topImage.onload =async () => {
          // Adjust the parameters to position the top image
          const x = 2730; // Adjust this value based on your requirements
          const y = 520; // Adjust this value based on your requirements
          const width = 970;
          const height = 1120;
    
          context.drawImage(topImage, x, y, width, height);
    
          // Draw text on the canvas
          arr.forEach(({ text, x, y, fontSize, color }) => {
            context.fillStyle = color;
            context.font = `bold ${fontSize}px Arial`; // Set font size for each text
            context.fillText(text, x, y);
          });
       // Convert canvas to data URL
       const dataUrl = canvas.toDataURL('image/png');
       // Create PDF using jsPDF
       const pdf = new jsPDF('landscape', 'px', [canvas.width, canvas.height]);
       pdf.addImage(dataUrl, 'PNG', 0, 0, canvas.width, canvas.height,undefined,'FAST');
       pdf.addPage();
       pdf.addImage(backImage, 'PNG', 0, 0,canvas.width, canvas.height,undefined,'FAST');
       // Save or download the PDF
       pdf.save(`${name}_id_card.pdf`);
        };
        if (imageLink) { 
          const base64Url =await imageUrlToBase64(imageLink);
          // Replace this path with the actual path or URL to your top image
          topImage.src = base64Url;
        } else {
          topImage.src = userImage;
        }
      };
    
      // Replace this path with the actual path or URL to your background image
      backgroundImage.src = bgImg;
    };
    async function imageUrlToBase64(imageUrl) {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
    
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error('Error converting image URL to base64:', error);
        throw error;
      }
    }
    const handleDownloadCertificate = () => {
        handleDownload(details?.card,details?.id_number,details?.athlete_name,details?.dob,details?.gender,details?.city,details?.father_name)
    }

    return(
        <>
            <canvas ref={canvasRef} style={{display:"none"}} />
            <div className='max-md:hidden'>
            <PrintIcon className="w-full flex justify-center" onClick={handleDownloadCertificate}/>
        </div>
        <div className='md:hidden'>
          <MobilePrintSvg onClick={handleDownloadCertificate} />
        </div>
        </>
    )
}
export const BlackBeltHolderIdCardPrint = ({ details }) => {
  const canvasRef = useRef();
  
    const handleDownload = (imageLink,ota_id,name,date,gender,dist,fm) => {
       const arr=[
        { text: ota_id?ota_id:"", x: 900, y: 675, fontSize: 100,color:"blue" },
        { text:name?getCapitalIstLetter(name):"", x: 900, y: 930, fontSize: 100,color:"blue" },
        { text: date?date:"", x: 900, y: 1185, fontSize: 100,color:"blue" },
        { text: gender?getCapitalIstLetter(gender):"" ,x: 900, y: 1445, fontSize: 100,color:"blue" },
        { text:fm?getCapitalIstLetter(fm):"", x: 900, y: 1700, fontSize: 100,color:"blue" },
        { text: dist?getCapitalIstLetter(dist):"", x: 900, y: 1960, fontSize: 100,color:"blue" },
        ]
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
    // Set willReadFrequently to true for better performance
    // context.imageSmoothingEnabled = true;
    // context.imageSmoothingQuality = 'high';
    // context.willReadFrequently = true;
      // Load your certificate image - Replace with the actual path or URL
      const backgroundImage = new window.Image();
      backgroundImage.onload = async () => {
        canvas.width = backgroundImage.width;
        canvas.height = backgroundImage.height;
    
        // Draw the background image on the canvas
        context.drawImage(backgroundImage, 0, 0);
    
        // Load and draw the top image on the canvas
        const topImage = new Image();
        topImage.onload =async () => {
          // Adjust the parameters to position the top image
          const x = 2730; // Adjust this value based on your requirements
          const y = 520; // Adjust this value based on your requirements
          const width = 970;
          const height = 1120;
    
          context.drawImage(topImage, x, y, width, height);
    
          // Draw text on the canvas
          arr.forEach(({ text, x, y, fontSize, color }) => {
            context.fillStyle = color;
            context.font = `bold ${fontSize}px Arial`; // Set font size for each text
            context.fillText(text, x, y);
          });
       // Convert canvas to data URL
       const dataUrl = canvas.toDataURL('image/png');
       // Create PDF using jsPDF
       const pdf = new jsPDF('landscape', 'px', [canvas.width, canvas.height]);
       pdf.addImage(dataUrl, 'PNG', 0, 0, canvas.width, canvas.height,undefined,'FAST');
       pdf.addPage();
       pdf.addImage(blackbeltbackimage, 'PNG', 0, 0,canvas.width, canvas.height,undefined,'FAST');
       // Save or download the PDF
       pdf.save(`${name}_id_card.pdf`);
        };
        if (imageLink) { 
          const base64Url =await imageUrlToBase64(imageLink);
          // Replace this path with the actual path or URL to your top image
          topImage.src = base64Url;
        } else {
          topImage.src = userImage;
        }
      };
    
      // Replace this path with the actual path or URL to your background image
      backgroundImage.src = blackbeltfront;
  };
  
    async function imageUrlToBase64(imageUrl) {
      try {
        const response = await fetch(imageUrl);
        const blob = await response.blob();
    
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.error('Error converting image URL to base64:', error);
        throw error;
      }
  }
  
    const handleDownloadCertificate = () => {
         handleDownload(details?.card,details?.ota_id_number,details?.athlete_name,details?.dob,details?.gender,details?.city,details?.father_name)
    }

    return(
        <>
        <canvas ref={canvasRef} style={{ display: "none" }} />
        <div className='max-md:hidden'>
            <PrintIcon className="w-full flex justify-center" onClick={handleDownloadCertificate}/>
        </div>
        <div className='md:hidden'>
          <MobilePrintSvg onClick={handleDownloadCertificate} />
        </div>
        </>
    )
}
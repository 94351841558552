import React, { useEffect } from 'react'
import logo from "../../assets/Taekwondo Images/Images/Logo.png"
import VideoImg1 from "../../assets/Taekwondo Images/Images/Video main.png"
import MobBanner from "../../assets/Taekwondo Images/Images/mobile_banner.jpeg"
import ReactangleFrame from "../../assets/Taekwondo Images/Images/Rectangle Frame.png"
import BackFrame from "../../assets/Taekwondo Images/Images/backframe.png"
import { ReactComponent as Bar } from "../../assets/icons/threeBar_icon.svg"
import { ReactComponent as RightNavigation } from "../../assets/icons/right_navigation.svg"
import { ReactComponent as PdfIcon } from "../../assets/icons/pdf_icon.svg"
import { ReactComponent as DateIcon } from "../../assets/icons/date_icon.svg"
import { ReactComponent as PauseIcon } from "../../assets/icons/pause_icon.svg"
import { ReactComponent as Facebook } from "../../assets/icons/facebook_icon.svg"
import { ReactComponent as Instagram } from "../../assets/icons/instagram_icon.svg"
import { ReactComponent as X } from "../../assets/icons/x_icon.svg"
import { ReactComponent as Lady1 } from "../../assets/icons/home_lady.svg"
import OwnerImg from "../../assets/Taekwondo Images/Images/mm bhanja.png"
import OwnerBG from "../../assets/Taekwondo Images/Images/owner bg.jpg"
import { Link } from 'react-router-dom'
import * as routesKey from "../../constants/routes"
import { useDispatch, useSelector } from 'react-redux'
import GallerySlider from './GallerySlider'
import { SwiperSlide, Swiper } from 'swiper/react'
import { Autoplay, Pagination,Navigation } from 'swiper'
import { getVideoList } from '../../service/api'
import { downloadImage } from '../../components/helper/downloadImage'
import { MobileCalenderIcon } from './LatestInformation'
import moment from 'moment'



const MobileView = ({bannerList}) => {

  const { videoList, prideList, newsList, latestInfos } = useSelector(state => state.home)
  const redirectToYouTube = (uri) => {
    window.location.href = uri; // Replace with the desired YouTube URL
  };
  const dispatch = useDispatch()
  const getImageFromVideo = (uri) => {
    return `https://img.youtube.com/vi/${uri?.split("=")[1]}/maxresdefault.jpg`;
  }
  const handleImageError = (e, uri) => {
    let url = `https://img.youtube.com/vi/${uri?.split("=")[1]}/mqdefault.jpg`;
    e.target.onerror = null;
    e.target.src = url;

  }
  useEffect(() => {
    dispatch(getVideoList())
  }, [dispatch])
  return (
    <div>

      {/* banner */}
      <div  className='m-5'>
      <div className='h-[200px] w-full '>
      <Swiper navigation={true} autoplay={{
            delay: 4000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }} modules={[Navigation,Autoplay]} className="mySwiper w-full h-full">
        {
          bannerList?.map((banner,i)=>
          <SwiperSlide key={i} className=' h-[100%] w-full'>
            <img alt='banner' src={banner?.template_image} className='w-full h-full object-contain'/>
          </SwiperSlide>
          )
        }
        
      </Swiper>
    </div>
        {/* <Link to={routesKey.CONTACT}>
         <img alt='' src={MobBanner} className='  h-[screen]' />
        </Link> */}
      </div>
      {/* JoinNow Button */}
      {/* <Link to={routesKey.CONTACT} className='absolute bg-white left-14 px-5 py-1 rounded-md bottom-[56.5%] z-10'>
        <p className='text-[11px] font-["Quicksand"] font-bold text-black'>Join Now</p>
      </Link> */}
      {/* auto runner */}
      <div className='mx-5 my-2'>

        <div className=' w-full   overflow-hidden rounded-sm shadow-md   bg-sky-200 '>
          <div className='marquee min-w-full py-1 '>

            <div className='min-w-full overflow-hidden flex gap-8'>
              {
                [1,2, 2].map((v) =>
                  <p className='text-[11px] font-["Quicksand"]' key={v}>Welcome to Odisha Taekwondo Association</p>
                )
              }

            </div>

          </div>
        </div>
      </div>

      {/* association */}
      <div className='m-5 py-7'>

        <div className='w-full  flex flex-col justify-center items-center'>
          <img alt='LOGO' src={logo}/>
          <p className='text-[#002642] font-["Quicksand"] my-5 font-bold text-center text-[38px] '>
            Odisha Taekwondo Association
          </p>
          <div>
            <p className='text-[#444444] text-[18px] text-center font-["Quicksand"]'>
              Welcome to Odisha Taekwondo Association! Our premier organization is dedicated to promoting taekwondo through top-quality instruction and training for individuals of all ages and skill levels. Our certified instructors provide group classes, private lessons, and specialized competition training in a supportive and welcoming environment. At OTA, we believe taekwondo is more than just a martial art, it's a way of life that promotes discipline, focus, and respect. Join us today to reach your full potential and achieve your physical and mental goals through the practice of taekwondo.
            </p>
          </div>
          <Link to={routesKey.ABOUT} className='w-full cursor-pointer justify-end items-center gap-2 flex  text-sky-950'>
            <div className=' text-base font-medium font-["Quicksand"] text-sky-950'>Read More</div>
            <div className=''><RightNavigation /></div>
          </Link>
        </div>
      </div>

      {/* ourPRIDE */}
      <div>
        <p className='text-[#002642] font-["Quicksand"] my-5 font-bold text-center text-[38px] '>Our Pride</p>
        <p className='text-[#444444] text-[18px] text-center my-5 mx-5 font-["Quicksand"]'>Successful Taekwondo students exhibit discipline, respect, and perseverance. They demonstrate mastery of techniques, excel in competitions, and embody the tenets of Taekwondo both on and off the mat.</p>
        <>
          <Swiper
            slidesPerView={1}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              576: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 4,
              },
              1500: {
                slidesPerView: 4,
              },
            }}
            centeredSlides={false}
            modules={[Pagination, Autoplay]}
            className="team-slider"
          >
            {
              prideList?.map((pride, i) => (
                <SwiperSlide className="single-team" key={i}>
                  <label
                    key={pride.otaPridesId}
                    className="flex flex-col relative justify-center items-center shadow-lg gap-1 rounded-lg p-6 group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"
                  >
                    {/* Add background image to the card */}
                    <div className="absolute top-6 left-0 p-1 w-full h-full bg-cover bg-center rounded-lg">

                      <img
                        alt=''
                        src={ReactangleFrame}
                      />
                    </div>
                    <div className="absolute top-14 left-2 w-full h-full bg-cover bg-center rounded-lg">
                      <img
                        src={BackFrame}
                        alt=''
                      />
                    </div>
                    <img src={pride?.pride_image} alt="pattern_image" className="h-[200px] w-[200px] relative z-10" />
                    <span className="text-base font-semibold">{pride?.pride_name}</span>
                    <span className="text-base font-semibold">{pride?.description}</span>
                  </label>
                </SwiperSlide>
              ))
            }
          </Swiper>
        </>
      </div>

      {/* latest information */}

      <div className='m-5'>
        <div className='w-full max-h-[800px]    bg-white shadow-md shadow-slate-400 rounded-sm'>
          <div className='text-[33px] text-center font-["Quicksand"] text-[#002642] font-medium'>
            Latest Information
          </div>
          <div className='my-7 max-h-[600px]  overflow-auto'>
            {
              latestInfos?.length ?
                <>
                  {
                    latestInfos?.slice(0, 4)?.map((v, i) =>
                      <div className='border-b-2' key={i}>
                        <div className='flex gap-2 m-5'>
                          <div className='w-42 flex justify-center items-center h-46 max-md:h-10 max-md:w-10'>
                            <MobileCalenderIcon month={moment(v?.date).format("MMM")} date={moment(v?.date).format("DD")} />
                          </div>
                          <div>
                            <p className='text-base text-[#444444] font-["Quicksand"] inline-block break-all'>{v?.description?.slice(0, 40)} <PdfIcon onClick={() => downloadImage(v?.ota_notices_pdf)} className='cursor-pointer inline-block' /></p>
                          </div>
                        </div>

                      </div>
                    )
                  }
                </> : <div className='text-[24px] text-center my-auto font-["Quicksand"] text-[#002642] font-medium'> No Data</div>
            }
          </div>
        </div>
        {
          latestInfos?.length > 4 ?
            <Link to={routesKey.LATESTINFO} className='w-full cursor-pointer justify-center items-center gap-3 flex text-2xl font-bold text-sky-950'>
              <div className=' text-base font-bold font-["Quicksand"] text-sky-950'>Read More</div>
              <div className=''><RightNavigation /></div>
            </Link> : ""
        }

      </div>

      {/* GALLERY */}
      <div>

        <p className='text-[#002642] font-["Quicksand"] my-5 font-bold text-center text-[38px] '>Our Gallery</p>
        <p className='text-[#444444] text-[18px] text-center my-5 mx-5 font-["Quicksand"]'>Explore our dynamic Taekwondo gallery showcasing the artistry, discipline, and athleticism of our practitioners. Witness powerful kicks, precise forms, and inspiring moments captured in stunning imagery.</p>

        <GallerySlider />
      </div>

      {/* video list */}
      <div>
        <p className='text-[#002642] font-["Quicksand"] my-5 font-bold text-center text-[35px] '>
          Taekwondo Mastery Showcase
        </p>
        {
          videoList?.map((v, i) =>
            <div className='py-3 mx-5' key={i}>

              <div className='relative rounded-md w-full max-md:h-[176px] '>

                <img alt='' src={getImageFromVideo(v?.video_link)} onError={(e) => handleImageError(e, v?.video_link)} className='w-full h-full' />
                <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
                  <button onClick={() => redirectToYouTube(v?.video_link)}>

                    <PauseIcon className='cursor-pointer' />
                  </button>
                </div>
                <div className='absolute w-full bottom-0 left-0 '>
                  <div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] max-md:mb-1 mb-4 max-md:min-h-0 min-h-7'>
                    <div className='max-md:py-1 max-md:px-4'>
                      <p className=' max-md:text-[10px] font-medium text-white font-["Quicksand"]'>{v?.description?.slice(0, 55)}{v?.description?.length > 55 ? "..." : ""}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        <div>
          {
            videoList?.length > 3 ?
              <Link to={routesKey.VIDEOLIST} className='w-full cursor-pointer justify-center items-center gap-2 flex  text-sky-950'>
                <div className=' text-base font-bold text-sky-950 font-["Quicksand"]'>Read More</div>
                <div className=''><RightNavigation /></div>
              </Link> : ""
          }
        </div>

      </div>

      <div className='m-5 flex flex-col gap-3'>
        <p className='text-[#002642] text-[32px] font-bold  font-["Quicksand"] text-center'>News</p>
        <p className='text-[#444444] text-[18px] font-["Quicksand"] text-center'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc auctor odio vel turpis lacinia, vitae
          vestibulum odio accumsan. Aliquam id justo eu justo scelerisque luctus. In hac</p>

        <div className='flex flex-col justify-between h-[500px] items-center  w-full'>
          <div className='h-[74%] w-[100%] rounded-'>
            <img alt='news' src={newsList[0]?.news_image} className='h-[100%] rounded-  w-full' />

          </div>
          <div className='h-[25%] p-2 w-full bg-[#002642] rounded-lg'>
            <div className='flex w-full h-full  justify-between'>
              {
                newsList?.slice(1, 4)?.map((news, i) =>
                  <img alt='' src={news?.news_image} className='w-[33%] rounded-lg h-full' />
                )
              }
            </div>
          </div>

        </div>
        {
          newsList?.length > 4 ?
            <Link to={`home/newslist`} className='w-full cursor-pointer justify-center items-center gap-5 flex text-2xl font-bold text-sky-950'>
              <div className=' text-2xl font-bold text-sky-950 font-["Quicksand"]'>View More</div>
              <div className=''><RightNavigation /></div>
            </Link> : ""
        }
      </div>


      {/* footer */}

    </div>


  )
}

export default MobileView
import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import { useParams } from 'react-router-dom'
import { getEventsResults, updateEventsResults } from '../../service/apiService';
import { useDispatch, useSelector } from 'react-redux';
import { downloadImage } from '../../components/helper/downloadImage';
import InfiniteScroll from 'react-infinite-scroll-component';

const EventList = () => {
    const { status } = useParams();
    const dispatch = useDispatch();
    const { councilMembers, colorBeltRegistredPlayers, blackBeltRegistredPlayers, colorBeltRegistredPlayersLoading, blackBeltRegistredPlayersLoading, promotionTestList, promotionTestListLoading, totalpromotionTestList, totalCouncilMember, totalcolorBeltRegistredPlayers, totalblackcolorBeltRegistredPlayers, medaLists, eventResults, totalEventResult, coachProfiles, TotalCoachProfiles, refreeProfiles, TotalRefreeProfiles, technicalCommitiesList, TotaltechnicalCommitiesList, calendarEventsList, eventResultLoading } = useSelector((state) => state.about)
    const [page, setpage] = useState(1)
    useEffect(() => {
        if (Number(status) === 1) {
          dispatch(getEventsResults(1,10,"international"))
        } else if (Number(status) === 2) {
          dispatch(getEventsResults(1,10,"national"))
        } else if (Number(status) === 3) {
          dispatch(getEventsResults(1,10,"state"))
        }
    }, [status])
    const loadMore = async () => {
            if (eventResults?.length < totalEventResult && !eventResultLoading) {
                if (Number(status) === 1) {
                    dispatch(updateEventsResults(page+1,10,"international"))
                  } else if (Number(status) === 2) {
                    dispatch(updateEventsResults(page+1,10,"national"))
                  } else if (Number(status) === 3) {
                    dispatch(updateEventsResults(page+1,10,"state"))
                  }
              setpage(page+1)
          }
        }
  return (
      <Template>
          <p className="text-[50px] max-md:text-[34px] text-center py-5   font-['Quicksand'] text-[#002642] font-bold">{Number(status) === 1 ? "International" : Number(status) === 2 ? "National" : "State"} Event Result</p>
          <div className='md:p-7 my-4  max-md:py-4 md:w-[100%] '>
              <div className="flex md:hidden flex-col gap-3 px-3">
              <InfiniteScroll
                dataLength={eventResults?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden"}}
                next={loadMore}
                hasMore={true}
                loader={eventResultLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                  >
                      
          {eventResults?.length ?           <>
        {
            eventResults.map((v,i)=>
        <button onClick={()=>downloadImage(v?.card)} key={i} className="flex gap-5  py-4">
        <svg width="35" height="29" viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={()=>downloadImage(v.card)}>
<path d="M44.9574 33.554H42.4631V36.033C42.4631 38.7719 40.2303 41.011 37.4544 41.011H9.99723C7.24146 41.011 5.00867 38.7919 5.00867 36.033V33.554H2.49428C1.10633 33.554 0 32.4345 0 31.075V16.1811C0 14.8017 1.12645 13.7021 2.49428 13.7021H4.98856V5.29587C4.98856 2.55699 7.24146 0.337891 9.99723 0.337891H29.9716H32.4659L42.4631 10.2738V12.7528V13.7021H44.9574C46.3453 13.7021 47.4517 14.8216 47.4517 16.1811V31.075C47.4718 32.4545 46.3453 33.554 44.9574 33.554ZM7.50295 36.033C7.50295 37.4124 8.6294 38.512 9.99723 38.512H37.4745C38.8625 38.512 39.9688 37.3924 39.9688 36.033V33.554H7.50295V36.033ZM29.9716 23.2582C29.9716 20.6793 29.0463 18.88 27.5779 17.7405C26.2101 16.6809 24.4801 16.1811 21.7847 16.1811C20.1755 16.1811 18.6468 16.301 17.46 16.481V30.8551C18.2646 30.9551 19.4312 31.075 21.0807 31.075C23.8365 31.075 26.1095 30.4753 27.598 29.1958C28.9859 27.9963 29.9716 26.0571 29.9716 23.2582ZM4.98856 16.481V31.075H8.10641V25.7772C8.38802 25.8172 8.77021 25.8372 9.17251 25.8372C11.0231 25.8372 12.6122 25.3573 13.6984 24.2778C14.5231 23.4381 14.9858 22.2186 14.9858 20.7592C14.9858 19.3198 14.3823 18.0803 13.4973 17.3406C12.572 16.5609 11.184 16.1611 9.25297 16.1611C7.32192 16.1811 5.9742 16.301 4.98856 16.481ZM39.9688 12.7528H34.9601C32.2245 12.7528 29.9716 10.5337 29.9716 7.79485V2.81688H9.99723C8.60929 2.81688 7.50295 3.93642 7.50295 5.29587V13.6821H39.9889L39.9688 12.7528ZM42.4631 16.1811H32.4659V31.075H36.1671V25.0775H42.0407V22.3386H36.1671V18.94H42.443V16.1811H42.4631ZM11.8679 20.8792C11.8679 22.3986 10.8421 23.2982 9.1524 23.2982C8.68975 23.2982 8.36791 23.2782 8.10641 23.2182V18.82C8.32768 18.76 8.77021 18.7001 9.41389 18.7001C10.9829 18.7001 11.8679 19.5197 11.8679 20.8792ZM20.6985 18.84C20.96 18.78 21.4226 18.7001 22.1468 18.7001C24.9026 18.7001 26.6124 20.2994 26.5922 23.3382C26.5922 26.8367 24.7014 28.4761 21.8048 28.4561C21.4025 28.4561 20.96 28.4561 20.6985 28.3961V18.84Z" fill="#002642"/>
          </svg>
          <p className="text-[12px] font-['Quicksand'] font-normal text-[#444444] w-[85%] text-left">{v?.event_name}) [{v.year}]</p>
        </button>
            )
                }
                </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Events found
                </span>
                      </div>}
                      </InfiniteScroll>
          </div>
          <div className="flex max-md:hidden flex-col justify-start items-start self-stretch flex-grow overflow-hidden ">
                  <div className="flex flex-col  items-start self-stretch flex-grow min-h-[50vh] justify-between w-full py-3">
                  <InfiniteScroll
                dataLength={eventResults?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden"}}
                next={loadMore}
                hasMore={true}
                loader={eventResultLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                      >
          {eventResults?.length ?           <>
        {
            eventResults.map((v,i)=>
        <div key={i} className="flex gap-5 py-4" onClick={()=>downloadImage(v?.card)}>
        <svg width="48" height="42" viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg" >
<path d="M44.9574 33.554H42.4631V36.033C42.4631 38.7719 40.2303 41.011 37.4544 41.011H9.99723C7.24146 41.011 5.00867 38.7919 5.00867 36.033V33.554H2.49428C1.10633 33.554 0 32.4345 0 31.075V16.1811C0 14.8017 1.12645 13.7021 2.49428 13.7021H4.98856V5.29587C4.98856 2.55699 7.24146 0.337891 9.99723 0.337891H29.9716H32.4659L42.4631 10.2738V12.7528V13.7021H44.9574C46.3453 13.7021 47.4517 14.8216 47.4517 16.1811V31.075C47.4718 32.4545 46.3453 33.554 44.9574 33.554ZM7.50295 36.033C7.50295 37.4124 8.6294 38.512 9.99723 38.512H37.4745C38.8625 38.512 39.9688 37.3924 39.9688 36.033V33.554H7.50295V36.033ZM29.9716 23.2582C29.9716 20.6793 29.0463 18.88 27.5779 17.7405C26.2101 16.6809 24.4801 16.1811 21.7847 16.1811C20.1755 16.1811 18.6468 16.301 17.46 16.481V30.8551C18.2646 30.9551 19.4312 31.075 21.0807 31.075C23.8365 31.075 26.1095 30.4753 27.598 29.1958C28.9859 27.9963 29.9716 26.0571 29.9716 23.2582ZM4.98856 16.481V31.075H8.10641V25.7772C8.38802 25.8172 8.77021 25.8372 9.17251 25.8372C11.0231 25.8372 12.6122 25.3573 13.6984 24.2778C14.5231 23.4381 14.9858 22.2186 14.9858 20.7592C14.9858 19.3198 14.3823 18.0803 13.4973 17.3406C12.572 16.5609 11.184 16.1611 9.25297 16.1611C7.32192 16.1811 5.9742 16.301 4.98856 16.481ZM39.9688 12.7528H34.9601C32.2245 12.7528 29.9716 10.5337 29.9716 7.79485V2.81688H9.99723C8.60929 2.81688 7.50295 3.93642 7.50295 5.29587V13.6821H39.9889L39.9688 12.7528ZM42.4631 16.1811H32.4659V31.075H36.1671V25.0775H42.0407V22.3386H36.1671V18.94H42.443V16.1811H42.4631ZM11.8679 20.8792C11.8679 22.3986 10.8421 23.2982 9.1524 23.2982C8.68975 23.2982 8.36791 23.2782 8.10641 23.2182V18.82C8.32768 18.76 8.77021 18.7001 9.41389 18.7001C10.9829 18.7001 11.8679 19.5197 11.8679 20.8792ZM20.6985 18.84C20.96 18.78 21.4226 18.7001 22.1468 18.7001C24.9026 18.7001 26.6124 20.2994 26.5922 23.3382C26.5922 26.8367 24.7014 28.4761 21.8048 28.4561C21.4025 28.4561 20.96 28.4561 20.6985 28.3961V18.84Z" fill="#002642"/>
          </svg>
          <p className="text-[32px] font-['Quicksand'] font-normal text-[#444444]">{v?.event_name}) [{v.year}]</p>
        </div>
            )
                }
                </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Events found
                </span>
                          </div>}
                      </InfiniteScroll>
          </div>
          </div>
          </div>
    </Template>
  )
}

export default EventList
export const HOME="/"
export const LATESTINFO="/home/latest-info"
export const CONTACT="/contact"
export const DTA="/dta"
export const ABOUT="/about"
export const PLAYERLIST="/about/playerlist/:status"
export const NEWSLIST="/home/newslist"
export const REGISTER_CLUB="/register-club/:city_id"
export const VIDEOLIST="/home/videos"
export const ONLINEREGISTRATION="/online-registration"
export const CHAMPIONSHIPSTRIP="/online-registration/championship-strip"
export const ATHLETEREGESTRATIONSTRIP="/online-registration/athlete-strip"
export const COLORBELTSTRIP="/online-registration/colorbelt-strip"
export const COMPETITION = "/competition"
export const PROMOTIONTESTLIST="/promotion-test-list/:city"
export const EVENTLIST="/event-list/:status"
export const COACHESLIST="/coaches-list/:status"
export const REFREEPROFILES="/refree-list/:status"
export const TECHNICALCOMMITIESLIST="/technical-commitee-list/:status"

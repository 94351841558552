import { Fragment, useEffect, useState } from 'react'
import { Combobox, Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useSelector } from 'react-redux'
import logo from "../../assets/Taekwondo Images/Images/Logo.png"
const people = [
  { name: 'Wade Cooper' },
  { name: 'Arlene Mccoy' },
  { name: 'Devon Webb' },
  { name: 'Tom Cook' },
  { name: 'Tanya Fox' },
  { name: 'Hellen Schmidt' },
  { name: 'Hellen Schmidt' },
  { name: 'Hellen Schmidt' },
  { name: 'Hellen Schmidt' },
  { name: 'Hellen Schmidt' },
]

export default function SelectBox({setValue}) {
    const [selected, setSelected] = useState({city_id:"",city_name:"All",city_image:logo})
    const [query, setQuery] = useState("")
    const { districtList } = useSelector((state) => state.home);
    const filteredCity =
    query === ''
      ? [{city_id:"",city_name:"All",city_image:logo},...districtList]
      : [{city_id:"",city_name:"All",city_image:logo},...districtList]?.filter((city) =>
          city?.city_name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
            )
    useEffect(() => {
        setValue(selected.city_id)
    }, [selected])

  return (
      <div className="w-72">
                <Combobox value={selected} onChange={setSelected} >
        <div className="relative mt-1">
          <div className="relative w-full cursor-default  rounded-lg bg-transparent text-left  focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm max-md:py-2 md:text-[20px]">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm md:text-[20px] leading-5 text-gray-900 focus:ring-0 focus:outline-none bg-transparent"
              displayValue={(city) => city.city_name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
            <div className="custom-icon">
     <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="22.5" cy="23.1279" r="22.5" fill="#002947" fill-opacity="0.74"/>
<path d="M14 20.6279L21.8146 27.9829C22.1997 28.3453 22.8003 28.3453 23.1854 27.9829L31 20.6279" stroke="white" stroke-width="2" stroke-linecap="round"/>
</svg>

    </div>
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 z-50 ring-black/5 focus:outline-none sm:text-sm">
              {filteredCity?.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredCity?.map((city) => (
                  <Combobox.Option
                    key={city.city_id}
                    className={({ active }) =>
                      `relative text-[20px] cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-teal-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={city}
                  >
                    {({ selected, active }) => (
                            <div className='flex gap-2'>
                                <img src={city?.city_image} alt='city_image' className='w-8 h-8 rounded-full'  />
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {city.city_name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </div>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>

    </div>
  )
}

//       <Listbox value={selected} onChange={setSelected}>
//         <div className="relative mt-1">
//           <Listbox.Button className="relative w-full cursor-default rounded-lg bg-transparent py-2 pl-3 pr-10 text-left  focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm md:text-[20px]">
//             <span className="block truncate">{selected.name}</span>
//                       <div className="custom-icon">
//       <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
// <circle cx="22.5" cy="23.1279" r="22.5" fill="#002947" fill-opacity="0.74"/>
// <path d="M14 20.6279L21.8146 27.9829C22.1997 28.3453 22.8003 28.3453 23.1854 27.9829L31 20.6279" stroke="white" stroke-width="2" stroke-linecap="round"/>
// </svg>

//       </div>
//           </Listbox.Button>
//           <Transition
//             as={Fragment}
//             leave="transition ease-in duration-100"
//             leaveFrom="opacity-100"
//             leaveTo="opacity-0"
//           >
//             <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 z-40 ring-black/5 focus:outline-none sm:text-sm">
//               {people.map((person, personIdx) => (
//                 <Listbox.Option
//                   key={personIdx}
//                   className={({ active }) =>
//                     `relative cursor-default md:text-[20px] select-none py-2 pl-10 pr-4 ${
//                       active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
//                     }`
//                   }
//                   value={person}
//                 >
//                   {({ selected }) => (
//                     <>
//                       <span
//                         className={`block truncate ${
//                           selected ? 'font-medium' : 'font-normal'
//                         }`}
//                       >
//                         {person.name}
//                       </span>
//                       {selected ? (
//                         <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
//                           <CheckIcon className="h-5 w-5" aria-hidden="true" />
//                         </span>
//                       ) : null}
//                     </>
//                   )}
//                 </Listbox.Option>
//               ))}
//             </Listbox.Options>
//           </Transition>
//         </div>
//       </Listbox>
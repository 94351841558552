import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import axiosConfig from '../../axios_config/axios_instance'
import { downloadImage } from '../../components/helper/downloadImage'

const Competition = () => {
 const [competitionList, setCompetitionList] = useState([])

 useEffect(()=>{
     const getCompetitionList= async()=>{
         try {
             const {data} =await axiosConfig.get(`/get-competition`)
             setCompetitionList(data?.competitionDTOList)
              
         } catch (error) {
             
         }
     }
     getCompetitionList()
 },[])
  return (
    <Template>
        <div className='p-0'>
        <p className='text-[50px] max-md:text-[32px] max-md:py-2 py-7 text-[#002642] text-center font-["Quicksand"] font-bold'>Competition</p>
        <div className='py-2'>
            {
                competitionList?.length?
<>
        {
            competitionList?.map((compt,i)=>
    <div onClick={()=>downloadImage(compt?.pdf)} className={`flex justify-between items-center border-b-[1px] border-[#c5c5c5] max-md:py-2 max-md:px-3 px-10 p-2 ${i===competitionList?.length-1?"border-none":""}`}>
        <div className='flex items-center gap-5 w-[95%]'>
        <img alt='' src={compt?.logo} className='w-[117px] h-[117px] max-md:w-[33px] max-md:h-[33px] object-cover rounded-full ' />
        <p className='text-[32px] break-all max-md:text-[16px] text-[#000] font-["Quicksand"] font-medium'>{compt?.heading}</p>
        </div>
        <div  className=' cursor-pointer w-[52px] max-md:w-[59px] max-md:h-[16px] ' >
        <svg width="100%" height="100%" viewBox="0 0 59 52" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M55.8984 42.4664H52.7971V45.6357C52.7971 49.1374 50.0209 52 46.5695 52H12.4302C9.00376 52 6.2276 49.1629 6.2276 45.6357V42.4664H3.1013C1.37558 42.4664 0 41.035 0 39.297V20.2553C0 18.4917 1.40059 17.086 3.1013 17.086H6.20259V6.33871C6.20259 2.83708 9.00376 0 12.4302 0H37.2656H40.3669L52.7971 12.703V15.8723V17.086H55.8984C57.6241 17.086 58.9997 18.5173 58.9997 20.2553V39.297C59.0247 41.0606 57.6241 42.4664 55.8984 42.4664ZM9.3289 45.6357C9.3289 47.3993 10.7295 48.8051 12.4302 48.8051H46.5945C48.3202 48.8051 49.6958 47.3738 49.6958 45.6357V42.4664H9.3289V45.6357ZM37.2656 29.3033C37.2656 26.0062 36.1151 23.7058 34.2893 22.2489C32.5886 20.8943 30.4377 20.2553 27.0863 20.2553C25.0855 20.2553 23.1847 20.4087 21.7091 20.6387V39.0159C22.7095 39.1437 24.1601 39.297 26.211 39.297C29.6374 39.297 32.4636 38.5302 34.3143 36.8944C36.0401 35.3609 37.2656 32.8816 37.2656 29.3033ZM6.20259 20.6387V39.297H10.0792V32.5238C10.4294 32.5749 10.9046 32.6005 11.4048 32.6005C13.7057 32.6005 15.6816 31.987 17.0321 30.6068C18.0575 29.5334 18.6328 27.9742 18.6328 26.1084C18.6328 24.2681 17.8825 22.6835 16.782 21.7378C15.6315 20.7409 13.9058 20.2298 11.5048 20.2298C9.10381 20.2553 7.42811 20.4087 6.20259 20.6387ZM49.6958 15.8723H43.4682C40.0668 15.8723 37.2656 13.0353 37.2656 9.53363V3.16935H12.4302C10.7045 3.16935 9.3289 4.60068 9.3289 6.33871V17.0604H49.7208L49.6958 15.8723ZM52.7971 20.2553H40.3669V39.297H44.9688V31.6292H52.2719V28.1276H44.9688V23.7825H52.7721V20.2553H52.7971ZM14.7562 26.2618C14.7562 28.2043 13.4806 29.3544 11.3798 29.3544C10.8045 29.3544 10.4044 29.3289 10.0792 29.2522V23.6292C10.3543 23.5525 10.9046 23.4758 11.7049 23.4758C13.6557 23.4758 14.7562 24.5237 14.7562 26.2618ZM25.7358 23.6547C26.0609 23.578 26.6361 23.4758 27.5365 23.4758C30.9629 23.4758 33.0888 25.5205 33.0638 29.4056C33.0638 33.8784 30.7128 35.9743 27.1113 35.9487C26.6111 35.9487 26.0609 35.9487 25.7358 35.8721V23.6547Z" fill="#002642"/>
</svg>

        </div>

    </div>
            )
        }
</>:
<div className='text-[42px] py-7 text-center max-md:text-[16px] text-[#444] font-["Quicksand"] font-medium'>No Competitions Found</div>
            }
        </div>

        </div>
</Template>
  )
}

export default Competition
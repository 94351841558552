import React, { useEffect, useState } from 'react' 
import Template from '../../components/Template'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getColorBeltPromotionTestList, updateColorBeltPromotionTestList } from '../../service/apiService'
import ColorBeltCertificate from './ColorBeltCertificate'
import InfiniteScroll from 'react-infinite-scroll-component'

const ColorBeltPromotionList = () => {
    const { councilMembers, colorBeltRegistredPlayers, blackBeltRegistredPlayers, colorBeltRegistredPlayersLoading, blackBeltRegistredPlayersLoading, promotionTestList, promotionTestListLoading, totalpromotionTestList, totalCouncilMember, totalcolorBeltRegistredPlayers, totalblackcolorBeltRegistredPlayers, medaLists, eventResults, totalEventResult, coachProfiles, TotalCoachProfiles, refreeProfiles, TotalRefreeProfiles, technicalCommitiesList, TotaltechnicalCommitiesList, calendarEventsList } = useSelector((state) => state.about)
    const [page, setpage] = useState(1)
    const { city } = useParams();
    const dispatch = useDispatch();
    useEffect(() => {
        if (city && city !== "all") {
            dispatch(getColorBeltPromotionTestList(1,10,city))
        } else {
            dispatch(getColorBeltPromotionTestList(1,10,""))
        }
    }, [city])
    const loadMore = async () => {
        if (city) {
          if (promotionTestList?.length < totalpromotionTestList && !promotionTestListLoading) {
                dispatch(updateColorBeltPromotionTestList(page + 1,10,city!=="all"?city:""))
              setpage(page+1)
          }
        }
        }
  return (
      <Template>
          <p className="text-[50px] max-md:text-[34px] text-center py-5   font-['Quicksand'] text-[#002642] font-bold">Color Belt Promotion List</p>
          <div className="md:p-7 my-4  max-md:p-0 md:w-[100%] ">
        <div className="flex md:hidden flex-col gap-3 px-3">
          {promotionTestList?.length ? <> 
            <InfiniteScroll
                dataLength={promotionTestList?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden"}}
                next={loadMore}
                hasMore={true}
                loader={promotionTestListLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
            >  
        {
            promotionTestList?.map((player,i)=>
            <div className={`flex gap-4 ${i%2===0?"bg-white":"bg-[#E7E7E7]"} w-full p-2 rounded-md shadow-md`}>
                    <div className='flex flex-col gap-1 w-full'>
                        <div className='flex justify-end items-end w-full'>
                        <ColorBeltCertificate details={player}/>
                        </div>
                  {/* new ui */}
                  <div className='flex gap-2'>
                    <div className='flex flex-col gap-1 w-[50%] items-start justify-start'>
                      <p className='text-[12px] text-[#002642] font-bold font-["Quicksand"]'>Athlete Name:</p>
                      <p className='text-[12px] text-[#002642]   font-bold font-["Quicksand"]'>Present Belt:</p>
                      <p className='text-[12px] text-[#002642] font-bold font-["Quicksand"]'>Dan Exam Date:</p>
                      <p className='text-[12px] text-[#002642] font-bold font-["Quicksand"]'>Club Name:</p>
                   
                    </div>
                    <div className='flex flex-col gap-1 w-[50%] items-start justify-start'>
                    <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{player?.player_name}</p>
                    <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{ player?.player_present_belt}</p>
                      <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{ player?.exam_date}</p>
                      <p className='text-[12px] text-[#444444] font-normal font-["Quicksand"]'>{player?.ota_club_name}</p>
                    </div>
                  </div>
    </div>
    </div>
            )
              }
              </InfiniteScroll>
                  </> : <div className='w-full h-[100px] flex justify-center items-center'>
                          <span className='text-sm text-black/60'>No Registered Players found</span>
        </div>}
              </div>
              <div className="flex max-md:hidden flex-col justify-start items-start self-stretch flex-grow overflow-hidden ">
                  <div className="flex flex-col  items-start self-stretch flex-grow min-h-[50vh] justify-between w-full">
                  {promotionTestList?.length ?  
                    <InfiniteScroll
                dataLength={promotionTestList?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden"}}
                next={loadMore}
                hasMore={true}
                loader={promotionTestListLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                                >
                          <table>
                    <thead>
          <tr className="">
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
              Sr no
            </th>
            
            
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3  ">
            Athlete Name
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
            Present Belt
            </th>
            
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0">
            Club Name
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-11 py-3 text-center w-0">
            Exam Date
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0 ">
            Certificate
            </th>
           
          </tr>
                    </thead>
                      <tbody className="">
                        {promotionTestList?.map((promotion,i) => (
                           <tr   key={promotion.id} className={`group/item  ${i%2===0?"bg-white":"bg-[#e7e7e7]"}    transition-all border-b border-black/5 cursor-pointer`}>    
                           <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">0{i+1}</td>
                           
                          
                            <td className="   text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  leading-tight capitalize  text-center text-[#444444]">{ promotion?.player_name}</td>
                           <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">{promotion?.player_present_belt}</td>
                           
                            <td className="  text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  leading-tight  capitalize text-[#444444]  text-center">{ promotion?.ota_club_name}</td>
                           <td className=" text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap leading-tight capitalize p-4  text-[#444444] text-center">{promotion?.exam_date}</td>
                           <td className="text-primary  font-normal font-['Quicksand'] text-[24px] leading-tight capitalize text-[#444444] mx-auto my-auto items-center text-center">
                           <ColorBeltCertificate details={promotion} />
                           </td>
                           </tr>
                        ))}
                      </tbody>
                          </table>
                          </InfiniteScroll>
                          : <div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No promotion test available
                </span>
              </div>}
          </div>
          </div>
          </div>
    </Template>
  )
}

export default ColorBeltPromotionList
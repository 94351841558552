import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import { getTechnicalCommitiesList, updateTechnicalCommitiesList } from '../../service/apiService'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

const TechnicalCommitiesList = () => {
    const { status } = useParams();
    const dispatch = useDispatch();
    const {technicalCommitiesList, TotaltechnicalCommitiesList, technicalCommitiesListLoading } = useSelector((state) => state.about)
    const [page, setpage] = useState(1)
    useEffect(() => {
        dispatch(getTechnicalCommitiesList(1,5,status))
      },[status])

      const loadMore = async () => {
          if (technicalCommitiesList?.length < TotaltechnicalCommitiesList && !technicalCommitiesListLoading) {
                dispatch(updateTechnicalCommitiesList(page + 1,5,status))
              setpage(page+1)
          }
        }
  return (
      <Template >
          <p className="text-[50px] max-md:text-[34px] text-center py-5   font-['Quicksand'] text-[#002642] font-bold">{status==="0"?"International ":status==="1"?"National ":"State " }Technical Commitee List</p>
          <div className="md:p-7 my-4  max-md:p-0 md:w-[100%] ">
              <div className="flex md:hidden flex-col gap-3 px-3">
                  {technicalCommitiesList?.length ? <>
                    <InfiniteScroll
                dataLength={technicalCommitiesList?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden"}}
                next={loadMore}
                hasMore={true}
                loader={technicalCommitiesListLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                      >
                          
                          {
    technicalCommitiesList.map((commitee,i)=>
        <div key={i} className="bg-white my-3 rounded-lg shadow-lg py-2 gap-4 items-center flex w-full">
            <div className="border-dashed h-32 w-[40%] rounded-r-full border-l-0 border-2 border-[#002642] ">
              <img alt="va" src={commitee?.image} className="h-full w-full rounded-r-full"/>.
            </div>
            <div>
            <div className="  flex gap-4 min-h-28 border-l-0">
                <div className="ml-5  flex flex-col justify-around ">
              <div className='flex w-full  gap-2'>
                <p className="text-[#000000]  text-[12px] font-bold font-['Quicksand'] whitespace-nowrap ">Name : </p>
                <p className="text-[#1a1a1a]  text-[12px] font-medium font-['Quicksand'] w-full self-start ">{commitee?.name}</p>
                </div>
              <div className='flex w-full gap-2'>
                <p className="text-[#000000]  text-[12px] font-bold font-['Quicksand'] whitespace-nowrap ">Post : </p>
                <p className="text-[#1a1a1a]  text-[12px] font-medium font-['Quicksand'] w-full self-start ">{commitee?.post}</p>
                </div>
              <div className='flex w-full gap-2'>
                <p className="text-[#000000]  text-[12px] font-bold font-['Quicksand'] whitespace-nowrap  ">Dist : </p>
                <p className="text-[#1a1a1a]  text-[12px] font-medium font-['Quicksand'] w-full self-start ">{commitee?.city_name}</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    )
              }
                          </InfiniteScroll>
                          
            </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Technical commitieees found
                </span>
              </div>}
              </div>
              <div className="flex max-md:hidden flex-col justify-start items-start self-stretch flex-grow overflow-hidden w-full">
                  <div className="flex flex-col  items-start self-stretch flex-grow min-h-[50vh] justify-between w-full">
                      {technicalCommitiesList?.length ? <>
                          <InfiniteScroll
                            
                dataLength={technicalCommitiesList?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden",width:"100%"}}
                next={loadMore}
                hasMore={true}
                loader={technicalCommitiesListLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                 
                        >
                              
                              {
    technicalCommitiesList.map((commitee)=>
        <div className="bg-white my-3 rounded-lg shadow-lg py-2 gap-4 flex w-full">
            <div className="border-dashed h-52 w-[20%] rounded-r-full border-l-0 border-2 border-[#002642] ">
              <img alt="va" src={commitee?.image} className="h-full w-full rounded-r-full"/>.
            </div>
            <div>
            <div className="  flex gap-7 min-h-48 border-l-0">
                <div className="ml-5  flex flex-col justify-around ">
                    <p className="text-[#000000]  text-[30px] font-bold font-['Quicksand'] ">Name : </p>
                    <p className="text-[#000]  text-[30px] font-bold font-['Quicksand'] ">Post : </p>
                    <p className="text-[#000]  text-[30px] font-bold font-['Quicksand'] ">District : </p>
                </div>
                <div className="flex flex-col justify-around ">
                    <p className="text-[#1a1a1a]  text-[30px] font-medium capitalize font-['Quicksand'] ">{commitee?.name}</p>
                    <p className="text-[#1a1a1a]  text-[30px] font-medium capitalize font-['Quicksand'] ">{commitee?.post}</p>
                    <p className="text-[#1a1a1a]  text-[30px] font-medium capitalize font-['Quicksand'] ">{commitee?.city_name}</p>
                </div>
            </div>
            </div>
        </div>
    )
              }
                      </InfiniteScroll>
                          
            </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Technical Commitee found
                </span>
              </div>}
        </div>
        </div>
        </div>
    </Template>
  )
}

export default TechnicalCommitiesList
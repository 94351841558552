import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import {ReactComponent as PauseIcon} from "../../assets/icons/pause_icon.svg"
import VideoImg1 from "../../assets/Taekwondo Images/Images/Video main.png"
import YouTube from 'react-youtube'
import { useDispatch, useSelector } from 'react-redux'
import { getVideoList } from '../../service/api'
import { LiveVideoModal } from './YoutubeVideoModal'

const VideoList = () => {
  const [videoId, setvideoId] = useState("")
  const [openModal, setopenModal] = useState(false);
  const {videoList} = useSelector(state=>state.home)
  const dispatch = useDispatch()
  const redirectToYouTube = (uri) => {
    setvideoId(uri?.split("=")[1])
    setopenModal(true);
        // window.location.href = 'https://www.youtube.com/watch?v=Ot-rBhiIUKs'; // Replace with the desired YouTube URL
      };
      const opts= {
        height: '400',
        width: '80%',
        playerVars: {
          // https://developers.google.com/youtube/player_parameters
          autoplay: 1,
        },
      };
      useEffect(()=>{
        dispatch(getVideoList())
      },[dispatch])

      const onReadyLiveVideo = (e) => {
        if (e.target) {
          if (true) { 
            e.target.cueVideoById("675u3rCXoPY",1);
            e.target.playVideo();
          }
        }
      }

  const getImageFromVideo = (uri) => {
        console.log("url-----------",uri);
        return `https://img.youtube.com/vi/${uri?.split("=")[1]}/maxresdefault.jpg`;
      }
      const handleImageError = (e,uri) => {
        let url = `https://img.youtube.com/vi/${uri?.split("=")[1]}/mqdefault.jpg`;
        e.target.onerror = null;
        e.target.src = url;
      
      }

      var pairwise = (arr) => {
        var pairs = []
        for ( var i = 0; i < arr.length; i+=2 ) {
            pairs.push([ arr[ i ], arr[ i + 1 ] ])
        }
        return pairs;
  }
  
  return (
    <Template>
        <div className='mx-7 py-7 max-md:mx-4 max-md:py-5'>
          {
            videoId&&
          <div>
            
            <>
  
       
       {videoId&& <LiveVideoModal  openLiveModal={openModal} setOpenLiveModal={setopenModal} id={videoId} setvideoId={setvideoId}/>}
  
            </>
            
            

          </div>
          }


          {/* video play */}

         



            <p className='text-[42px] max-md:text-[30px] max-md:text-center font-bold font-["Quicksand"] py-3'> Taekwondo Mastery Showcase</p>
            {/* for left big image */}

            <div className='max-md:hidden'>

            {
                pairwise(videoList)?.map((v,i)=><>
                {
           i%2===0?
         <LeftBigScreen list={v} getImageFromVideo={getImageFromVideo} handleImageError={handleImageError} redirectToYouTube={redirectToYouTube} />
         :
     <RightBigScreen list={v} getImageFromVideo={getImageFromVideo} handleImageError={handleImageError} redirectToYouTube={redirectToYouTube} />
                }
                </>
                )
            }
            </div>

            {/* mobile view grid */}
            <div className='md:hidden'>
            {
       videoId?
       
       
            <div >
            {
                pairwise(videoList)?.map((v,i)=><>
                {
           i%2===0?
        <MobileLeftBigScreen  list={v} getImageFromVideo={getImageFromVideo} handleImageError={handleImageError} redirectToYouTube={redirectToYouTube} />
         :
       <MobileRightBigScreen getImageFromVideo={getImageFromVideo} handleImageError={handleImageError} list={v} redirectToYouTube={redirectToYouTube} />
                }
                </>
                )
            }
            </div>:
            <>
            {
             videoList?.map((v,i)=>
           <div className='py-3 max-md:mx-1 mx-5' key={i}>
     
           <div className='relative rounded-md w-full max-md:h-[176px] '>
     
     <img alt='' src={getImageFromVideo(v?.video_link)} onError={(e)=>handleImageError(e,v?.video_link)} className='w-full h-full'/>
     <div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
     <button onClick={()=>redirectToYouTube(v?.video_link)}>
     
     <PauseIcon  className='cursor-pointer'/>
     </button>
     </div>
     <div className='absolute w-full bottom-0 left-0 '>
     <div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] max-md:mb-1 mb-4 max-md:min-h-0 min-h-7'>
     <div className='max-md:py-1 max-md:px-4'>
     <p className=' max-md:text-[10px] font-medium text-white font-["Quicksand"]'>{v?.description?.slice(0,55)}{v?.description?.length>55?"...":""}</p>
     </div>
     </div>
     </div>
          </div>
          </div>
           )
         }
            </>
            }
            
            </div>
        </div>
    </Template>
  )
}

export default VideoList

const YoutubeVideo=({videoId})=>{
  const opts= {
    height: '400',
    width: '80%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return(
    <YouTube videoId={videoId} opts={opts}  className='w-[100%] m-auto flex justify-center items-center' /> 
  )
}



const LeftBigScreen = ({ list,getImageFromVideo,handleImageError,redirectToYouTube}) => {
  return (
  <div className='my-5'>

        <div className='w-full  flex justify-between max-md:h-[120px] h-[415px]'>
      {list[0]&&  <div className='relative rounded-md w-[65%] h-[100%]'>

<img alt='' src={getImageFromVideo(list[0]?.video_link)} onError={(e)=>handleImageError(e,list[0]?.video_link)}  className='w-full h-full'/>
<div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
    <button onClick={()=>redirectToYouTube(list[0]?.video_link)}>

    <PauseIcon  className='cursor-pointer'/>
    </button>
  </div>
<div className='absolute w-full bottom-0 left-0 '>
  <div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] max-md:mb-1 mb-4 max-md:min-h-0 min-h-7'>
  <div className='px-7 py-3 max-md:py-1 max-md:px-1'>
   <p className='text-[28px] max-md:text-[7px] font-medium text-white font-["Quicksand"]'>{list[0]?.description?.slice(0,55)}{list[0]?.description?.length>55?"...":""}</p>
  </div>
</div>
</div>
         </div>}
      {(list&& list[1] )&&  <div className='relative rounded-md w-[34%] h-[100%]'>

<img alt='' src={getImageFromVideo(list[1]?.video_link)} onError={(e)=>handleImageError(e,list[1]?.video_link)} className='w-full h-full'/>
<div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
<button onClick={()=>redirectToYouTube(list[1]?.video_link)}>

<PauseIcon  className='cursor-pointer'/>
</button>
  </div>
<div className='absolute w-full bottom-0 left-0 '>
  <div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] max-md:mb-1 mb-4 max-md:min-h-0 min-h-7'>
  <div className='px-7 py-3 max-md:py-1 max-md:px-1'>
   <p className='text-[22px] font-normal max-md:text-[6px] text-white font-["Quicksand"]'>{list[1]?.description?.slice(0,55)}{list[1]?.description?.length>55?"...":""}</p>
  </div>
</div>
</div>
         </div>}
      </div>
      
           </div>
)
}

const RightBigScreen = ({ list,getImageFromVideo,handleImageError,redirectToYouTube }) => {
  return (
    <div className='my-5'>

        <div className='w-full  flex justify-between max-md:h-[120px] h-[415px]'>
      {(list&&list[0])  &&<div className='relative rounded-md w-[34%] h-[100%]'>

<img alt='' src={getImageFromVideo(list[0]?.video_link)} onError={(e)=>handleImageError(e,list[0]?.video_link)} className='w-full h-full'/>
<div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
<button onClick={()=>redirectToYouTube(list[0]?.video_link)}>

<PauseIcon  className='cursor-pointer'/>
</button>
  </div>
<div className='absolute w-full bottom-0 left-0 '>
  <div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] max-md:mb-1 mb-4 max-md:min-h-0 min-h-7'>
  <div className='px-7 py-3 max-md:py-1 max-md:px-1'>
   <p className='text-[22px] font-normal max-md:text-[6px] text-white font-["Quicksand"]'>{list[0]?.description?.slice(0,55)}{list[0]?.description?.length>55?"...":""}</p>
  </div>
</div>
</div>
         </div>}
       {(list&& list[1])&& <div className='relative rounded-md w-[65%] h-[100%]'>

<img alt='' src={getImageFromVideo(list[1]?.video_link)} onError={(e)=>handleImageError(e,list[1]?.video_link)} className='w-full h-full'/>
<div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
<button onClick={()=>redirectToYouTube(list[1]?.video_link)}>

<PauseIcon  className='cursor-pointer'/>
</button>
  </div>
<div className='absolute w-full bottom-0 left-0 '>
  <div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] max-md:mb-1 mb-4 max-md:min-h-0 min-h-7'>
  <div className='px-7 py-3 max-md:py-1 max-md:px-1'>
   <p className='text-[28px] max-md:text-[7px] font-medium text-white font-["Quicksand"]'>{list[1]?.description?.slice(0,55)}{list[1]?.description?.length>55?"...":""}</p>
  </div>
</div>
</div>
         </div>}
        
         </div>
           </div>
)
}
const MobileLeftBigScreen = ({list,getImageFromVideo,handleImageError,redirectToYouTube}) => {
  return (
    <div className='my-5'>
    <div className='w-full  flex justify-between max-md:h-[120px] h-[415px]'>
  {(list&&list[0])&&  <div className='relative rounded-md w-[65%] h-[100%]'>
<img alt='' src={getImageFromVideo(list[0]?.video_link)} onError={(e)=>handleImageError(e,list[0]?.video_link)} className='w-full h-full'/>
<div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
<button onClick={()=>redirectToYouTube(list[0]?.video_link)}>

<PauseIcon  className='cursor-pointer'/>
</button>
</div>
<div className='absolute w-full bottom-0 left-0 '>
<div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] max-md:mb-1 mb-4 max-md:min-h-0 min-h-7'>
<div className='px-7 py-3 max-md:py-1 max-md:px-1'>
<p className='text-[28px] max-md:text-[7px] font-medium text-white font-["Quicksand"]'>{list[0]?.video_link}</p>
</div>
</div>
</div>
     </div>}
   {(list&&list[1])&& <div className='relative rounded-md w-[34%] h-[100%]'>

<img alt='' src={getImageFromVideo(list[1]?.video_link)} onError={(e)=>handleImageError(e,list[1]?.video_link)} className='w-full h-full'/>
<div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
<button onClick={()=>redirectToYouTube(list[1]?.video_link)}>

<PauseIcon  className='cursor-pointer'/>
</button>
</div>
<div className='absolute w-full bottom-0 left-0 '>
<div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] max-md:mb-1 mb-4 max-md:min-h-0 min-h-7'>
<div className='px-7 py-3 max-md:py-1 max-md:px-1'>
<p className='text-[22px] font-normal max-md:text-[6px] text-white font-["Quicksand"]'>{list[1]?.description?.slice(0,55)}{list[1]?.description?.length>55?"...":""}</p>
</div>
</div>
</div>
     </div>}
     </div>
       </div>
  )
}

const MobileRightBigScreen = ({list,getImageFromVideo,redirectToYouTube,handleImageError}) => {
  return (
    <div className='my-5'>

    <div className='w-full  flex justify-between max-md:h-[120px] h-[415px]'>
  {(list&&list[0] )&& <div className='relative rounded-md w-[34%] h-[100%]'>

<img alt='' src={getImageFromVideo(list[0]?.video_link)} onError={(e)=>handleImageError(e,list[0]?.video_link)} className='w-full h-full'/>
<div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
<button onClick={()=>redirectToYouTube(list[0]?.video_link)}>

<PauseIcon  className='cursor-pointer'/>
</button>
</div>
<div className='absolute w-full bottom-0 left-0 '>
<div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] max-md:mb-1 mb-4 max-md:min-h-0 min-h-7'>
<div className='px-7 py-3 max-md:py-1 max-md:px-1'>
<p className='text-[22px] font-normal max-md:text-[6px] text-white font-["Quicksand"]'>{list[0]?.description?.slice(0,55)}{list[0]?.description?.length>55?"...":""}</p>
</div>
</div>
</div>
     </div>}
 {(list&&list[1]) &&  <div className='relative rounded-md w-[65%] h-[100%]'>

<img alt='' src={getImageFromVideo(list[1]?.video_link)} onError={(e)=>handleImageError(e,list[1]?.video_link)} className='w-full h-full'/>
<div className='absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
<button onClick={()=>redirectToYouTube(list[1]?.video_link)}>

<PauseIcon  className='cursor-pointer'/>
</button>
</div>
<div className='absolute w-full bottom-0 left-0 '>
<div className='bg-[rgba(1,1,1,0.4)] rounded-r-md w-[80%] max-md:mb-1 mb-4 max-md:min-h-0 min-h-7'>
<div className='px-7 py-3 max-md:py-1 max-md:px-1'>
<p className='text-[28px] max-md:text-[7px] font-medium text-white font-["Quicksand"]'>{list[1]?.description?.slice(0,55)}{list[1]?.description?.length>55?"...":""}</p>
</div>
</div>
</div>
     </div>}
    
     </div>
       </div>
  )
}
import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import { getCoachProfiles, getRefreeeProfiles, updateCoachProfiles, updateRefreeeProfiles } from '../../service/apiService'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

const RefreeList = () => {
    const { status } = useParams();
    const dispatch = useDispatch();
    const { councilMembers, colorBeltRegistredPlayers, blackBeltRegistredPlayers, colorBeltRegistredPlayersLoading, blackBeltRegistredPlayersLoading, promotionTestList, promotionTestListLoading, totalpromotionTestList, totalCouncilMember, totalcolorBeltRegistredPlayers, totalblackcolorBeltRegistredPlayers, medaLists, eventResults, totalEventResult, coachProfiles, TotalCoachProfiles, refreeProfiles, TotalRefreeProfiles, technicalCommitiesList, TotaltechnicalCommitiesList, calendarEventsList, refreeProfilesLoading, } = useSelector((state) => state.about)
    const [page, setpage] = useState(1)
    useEffect(() => {
        dispatch(getRefreeeProfiles(1,5,status))
      },[status])

      const loadMore = async () => {
          if (refreeProfiles?.length < TotalRefreeProfiles && !refreeProfilesLoading) {
                dispatch(updateRefreeeProfiles(page + 1,5,status))
              setpage(page+1)
          }
        }
  return (
      <Template >
          <p className="text-[50px] max-md:text-[34px] text-center py-5   font-['Quicksand'] text-[#002642] font-bold">{status==="0"?"International ":status==="1"?"National ":"State " }Referee Profile</p>
          <div className="md:p-7 my-4  max-md:p-0 md:w-[100%] ">
              <div className="flex md:hidden flex-col gap-3 px-3 m-7 bg-blue-950 rounded-lg">
                  {refreeProfiles?.length ? <>
                    <InfiniteScroll
                dataLength={refreeProfiles?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden"}}
                next={loadMore}
                hasMore={true}
                loader={refreeProfilesLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                      >
                          
                          {
            refreeProfiles?.map((refree,i)=>
        <div key={i} className="flex   py-3 justify-start items-center  px-7  border-white/[0.18] border-b">
            <div className="h-20 items-center flex justify-center w-20 border-dashed border-2 rounded-lg">
               <img alt="refree" src={refree?.image} className="object-cover h-16 w-16 rounded-lg"/>
            </div>
            <div className="  flex items-center  gap-3  border-l-0">
                <div className="ml-5 h-full  flex flex-col justify-around ">
                <div className="flex gap-2 w-full items-center">
                      <p className="text-[#FFF] whitespace-nowrap  text-[9px] font-bold font-['Quicksand'] ">Referee Name :</p>
                      <p className="text-[#E3E3E3]  text-[8px] font-medium font-['Quicksand'] w-full self-start ">{refree?.referee_name}</p>
                </div>
                {
                  refree?.event&&
                <div className="flex gap-2 w-full items-center">
                      <p className="text-[#FFF]  whitespace-nowrap  text-[9px] font-bold font-['Quicksand'] ">Event :</p>
                      <p className="text-[#E3E3E3]  text-[8px] font-medium font-['Quicksand'] self-start w-full  ">{refree?.event}</p>
                </div>
                }
                <div className="flex gap-2 w-full items-center">
                      <p className="text-[#FFF] whitespace-nowrap  text-[9px] font-bold font-['Quicksand'] ">District :</p>
                      <p className="text-[#E3E3E3]  text-[8px] font-medium font-['Quicksand'] capitalize self-start w-full ">{refree?.city_name}</p>
                </div>
                {
                  refree?.grade&&
                <div className="flex gap-2 w-full items-center">
                      <p className="text-[#FFF]  whitespace-nowrap  text-[9px] font-bold font-['Quicksand'] ">Grade :</p>
                      <p className="text-[#E3E3E3]  text-[8px] font-medium font-['Quicksand'] w-full self-start">{refree?.grade}</p>
                </div>
                }
                </div>
            </div>
        </div>
            )
              }
                          </InfiniteScroll>
                          
            </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No referee found
                </span>
              </div>}
              </div>
              <div className="flex max-md:hidden flex-col justify-start items-start self-stretch flex-grow overflow-hidden w-full m-7 bg-blue-950 rounded-lg">
                  <div className="flex flex-col  items-start self-stretch flex-grow min-h-[50vh] justify-between w-full">
                      {refreeProfiles?.length ? <>
                          <InfiniteScroll
                            
                dataLength={refreeProfiles?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden",width:"100%"}}
                next={loadMore}
                hasMore={true}
                loader={refreeProfilesLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                 
                        >
                              
         {
            refreeProfiles?.map((refree)=>
        <div className="flex top-[15%]  py-8 justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-7  border-white/[0.18] border-b">
            <div className="h-44 items-center flex justify-center w-44 border-dashed border-2 rounded-lg">
               <img alt="refree" src={refree?.image} className="object-cover h-40 w-40 rounded-lg"/>
            </div>
            <div className="  flex gap-7 min-h-48 justify-center items-center border-l-0">
                <div className="ml-5 h-full  flex flex-col justify-around ">
                    <p className="text-[#FFF]  text-[30px] font-bold font-['Quicksand'] ">Referee Name : </p>
                    {
                      refree?.event&&
                    <p className="text-[#FFF]  text-[30px] font-bold font-['Quicksand'] ">Event : </p>
                    }
                    <p className="text-[#FFF]  text-[30px] font-bold font-['Quicksand'] ">District : </p>
                    {
                      refree?.grade&&
                    <p className="text-[#FFF]  text-[30px] font-bold font-['Quicksand'] ">Grade : </p>
                    }
                </div>
                <div className="flex flex-col justify-around ">
                    <p className="text-[#E3E3E3]  text-[30px]  font-medium capitalize font-['Quicksand'] ">{refree?.referee_name}</p>
                    {
                      refree?.event&&
                    <p className="text-[#E3E3E3]  text-[30px] font-medium capitalize font-['Quicksand'] ">{ refree?.event}</p>
                    }
                    <p className="text-[#E3E3E3]  text-[30px] font-medium capitalize font-['Quicksand'] ">{refree?.city_name}</p>
                    {
                      refree?.grade&&
                    <p className="text-[#E3E3E3]  text-[30px] font-medium capitalize font-['Quicksand'] ">{ refree?.grade}</p>
                    }
                </div>
            </div>
        </div>
            )
              }
                      </InfiniteScroll>
                          
            </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No referee found
                </span>
              </div>}
        </div>
        </div>
        </div>
    </Template>
  )
}

export default RefreeList
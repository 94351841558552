import React, { Fragment, useEffect, useState } from "react";
import Template from "../../components/Template";
import { Tab } from "@headlessui/react";
import {ReactComponent as RightNavigation} from "../../assets/icons/right_navigation.svg"
import ReactangleFrame from "../../assets/Taekwondo Images/Images/Rectangle Frame.png"
import BackFrame from "../../assets/Taekwondo Images/Images/backframe.png"
import {ReactComponent as PrintIcon} from "../../assets/icons/about_print.svg"
import {ReactComponent as DownloadIcon} from "../../assets/icons/download.svg"
import {ReactComponent as StandLady} from "../../assets/icons/stand_lady.svg"
import EventCalendarImage from "../../assets/Taekwondo Images/Images/evenet_calendar.png"
import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import MobileView from "./MobileView";
import { useDispatch, useSelector } from "react-redux";
import { getBlackBeltRegistredPlayers, getCalendarEvents, getCoachProfiles, getColorBeltPromotionTestList, getColorBeltRegistredPlayers, getCouncilMemberList, getEventsResults, getMedalists, getRefreeeProfiles, getTechnicalCommitiesList } from "../../service/apiService";
import { getDistrictList } from "../../service/api";
import { downloadImage } from "../../components/helper/downloadImage";
import ColorBeltCertificate, { BlackBeltHolderIdCardPrint, ColorBeltHolderIdCardPrint } from "./ColorBeltCertificate";
import { Link } from "react-router-dom";
import * as routesKey from "../../constants/routes"
import Select from "react-select";
import SelectBox from "./SelectBox";

const About = () => {
    const [selectedTab, setselectedTab] = useState(0)
    const [eventTab, seteventTab] = useState(1)
    const [coachTab, setcoachTab] = useState(0)
  const [refreeTab, setrefreeTab] = useState(0)
  const [medalistTab, setmedalistTab] = useState(1)
  const { districtList } = useSelector(state => state.home)
  const [selectedDistrictRegistredPlayer, setselectedDistrictRegistredPlayer] = useState("")
  const [promotionSelectedDistrict, setpromotionSelectedDistrict] = useState("")
    const [committeeTab, setcommitteeTab] = useState(0)
    const [eventCalendarTab, seteventCalendarTab] = useState(0)
  const dispatch = useDispatch();
const {councilMembers,colorBeltRegistredPlayers,blackBeltRegistredPlayers,colorBeltRegistredPlayersLoading,blackBeltRegistredPlayersLoading,promotionTestList,promotionTestListLoading,totalpromotionTestList,totalCouncilMember,totalcolorBeltRegistredPlayers,totalblackcolorBeltRegistredPlayers,medaLists,eventResults,totalEventResult,coachProfiles,TotalCoachProfiles,refreeProfiles,TotalRefreeProfiles,technicalCommitiesList,TotaltechnicalCommitiesList,calendarEventsList}=useSelector((state)=>state.about)
  useEffect(() => {
    dispatch(getCouncilMemberList(1,10))
    dispatch(getDistrictList())
  }, [dispatch])
  
  useEffect(() => {
    if (selectedTab===0) {
      dispatch(getColorBeltRegistredPlayers(1,10,selectedDistrictRegistredPlayer))
    } else {
      dispatch(getBlackBeltRegistredPlayers(1,10,selectedDistrictRegistredPlayer))
    }
  },[selectedTab,selectedDistrictRegistredPlayer])

  useEffect(() => {
    dispatch(getColorBeltPromotionTestList(1,10,promotionSelectedDistrict))
  }, [promotionSelectedDistrict])
  
  useEffect(() => {
    if (medalistTab === 1) {
      dispatch(getMedalists(1,10,"international"))
    } else if (medalistTab === 2) {
      dispatch(getMedalists(1,10,"national"))
    } else if (medalistTab === 3) {
      dispatch(getMedalists(1,10,"state"))
    }
  },[medalistTab])
  useEffect(() => {
    if (eventTab === 1) {
      dispatch(getEventsResults(1,10,"international"))
    } else if (eventTab === 2) {
      dispatch(getEventsResults(1,10,"national"))
    } else if (eventTab === 3) {
      dispatch(getEventsResults(1,10,"state"))
    }
  }, [eventTab])
  
  useEffect(() => {
    dispatch(getCoachProfiles(1,2,coachTab))
  },[coachTab])
  useEffect(() => {
    dispatch(getRefreeeProfiles(1,2,refreeTab))
  },[refreeTab])
  useEffect(() => {
    dispatch(getTechnicalCommitiesList(1,2,committeeTab))
  }, [committeeTab])
  useEffect(() => {
    if (eventCalendarTab === 0) {
      dispatch(getCalendarEvents("world"))
    } else if (eventCalendarTab === 1) {
      dispatch(getCalendarEvents("national"))
    } else {
      dispatch(getCalendarEvents("state"))
    }
  },[eventCalendarTab])

  
  return (
    <Template>
      <div className="max-md:hidden">

      <div className="pl-7 w-full flex justify-between py-7">
        <div className=" w-[73%] flex flex-col gap-9">
        <div className="flex flex-col gap-4">

        <p className="text-[#002642] text-[50px] font-bold font-['Quicksand'] " >Odisha Taekwondo Association</p>
        <p className='text-[#444444] text-[22px] font-normal font-["Quicksand"] '>Odisha Taekwondo Association (OTA) is the Apex body for running & managing TAEKWONDO in the State of Odisha .OTA is also promoting the Para Taekwondo in the State. OTA leads the most complete and reachable combat sport. Taekwondo  evolves on a solid base, mixing the traditional and the modern. The values recognized by practitioners and partners are the strength of our sport. They are distilled from those found in our society: the search for pleasure, surpassing oneself, perseverance, moral and physical strength, and respect for others. 
OTA is regularly conducting Official, Open State Taekwondo Championships in Kyorugi & Poomsae event in different weight category & division every year.
OTA is sending Team as “Odisha Taekwondo Team” to National Championships every year.
OTA is conducting/organising National Referee Seminar in Kyorugi & Poomsae every year.
OTA is conducting/organising Kukkiwon Black Belt Promotion Test two times in year.

.</p>
        <p className='text-[#444444] text-[22px] font-normal font-["Quicksand"] '>Vision of OTA 
Taekwondo for All.

Mission of OTA
Develop and grow

Objectives  of  OTA
OTA  is responsible for the development, growth, and administration of Taekwondo around the State.
.</p>
        </div>
        <div className="flex flex-col gap-5">
            <p className="text-[#002642] text-[50px] font-bold font-['Quicksand'] ">Executive Council Members</p>
                
              <Swiper
          slidesPerView={2}
          spaceBetween={10}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 2,
            },
            1500: {
              slidesPerView: 2,
            },
          }}
          centeredSlides={false}
          modules={[Pagination, Autoplay]}
                className=" w-full flex gap-4"
                style={{display:"flex",paddingBottom:"35px"}}
              >
                
     
                {
                  councilMembers?.map((member, i) => (
                    i < 10 &&
                    <SwiperSlide className="max-w-[49%] min-w-[49%] p-4 shadow-md rounded-xl h-[170px] bg-white" style={{marginRight:"0px !important"}}>
                    <div className="max-w-full min-w-full">
                <div className=" h-full border-[1px] border-[#154162] rounded-xl ">
                  <div className="m-3 items-center ">
                     <div className="flex gap-2">
                        <img alt="im" src={member?.image} className="w-28 h-28 rounded-full bg-black object-cover"/>
                        <div className="flex flex-col justify-between">
                            <p className='text-[#002642] text-[24px] font-bold font-["Quicksand"] break-all'>{member?.name}</p>
                            <p className='text-[#002642] text-[24px] font-normal font-["Quicksand"] break-all'>{member?.post}</p>
                            <p className='text-[#002642] text-[24px] font-normal font-["Quicksand"] '>Dist- {member?.city_name}</p>
                        </div>
                     </div>

                  </div>
                </div>
                 
                        </div> 
                    </SwiperSlide>   
                  )
                    )
                }
                </Swiper>
        </div>
        </div>

        {/* left img */}
        <div className="w-[25%] rounded-l-[20px] min-h-[400px] ">
          <StandLady className="w-[100%] h-[100%]"/>
        </div>
      </div>

      <div className="p-7 items-center flex flex-col gap-4 justify-center">
      <p className="text-[#002642] text-[50px] font-bold font-['Quicksand'] ">OTA Registered Players List</p>
       {/* new select add */}
       {/* <Select
                          name="reporting_employee"
                          options={[
                            { label: "Not Assign", value: "0" },
                           
                          ]}
                          styles={{ cursor: "pointer" }}
                          // className={
                          //   errors.reporting_employee &&
                          //   touched.reporting_employee
                          //     ? "error-border"
                          //     : "cursor-pointer"
                          // }
                          // styles={customStyles}
                          // onChange={(selectedOption) =>
                          //   setFieldValue("reporting_employee", selectedOption)
                          // }
                          // onInputChange={handleSearch}
                          // isClearable
                          // onBlur={handleBlur}
                          // value={values.reporting_employee}
                          
                        /> */}

<div className="py-2 px-2 w-max border-2 border-[#002642] rounded-full" >
                        <SelectBox setValue={setselectedDistrictRegistredPlayer} />
      {/* <select className="custom-select font-['Quicksand'] font-medium border-none bg-transparent" onChange={(e) => setselectedDistrictRegistredPlayer(e.target.value)} value={selectedDistrictRegistredPlayer}>
              <option value="">All</option>
              {
                districtList?.map((district) => (
                  <option value={district?.city_id} key={district?.city_id} src={district?.city_image}>{ district?.city_name}</option>
                ))
              }
      </select> */}
      {/* <div className="custom-icon">
      <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="22.5" cy="23.1279" r="22.5" fill="#002947" fill-opacity="0.74"/>
<path d="M14 20.6279L21.8146 27.9829C22.1997 28.3453 22.8003 28.3453 23.1854 27.9829L31 20.6279" stroke="white" stroke-width="2" stroke-linecap="round"/>
</svg>

      </div> */}
    </div>
      </div>
      

      {/*PLAYER LIST table 1 */}
       
      <Tab.Group selectedIndex={selectedTab}  onChange={(val)=>setselectedTab(val)} >
        <Tab.List className="flex top-[15%]  justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-7  border-black/[0.08] border-b">
          <Tab as={Fragment}>
            {({ selected }) => (
              /* Use the `selected` state to conditionally style the selected tab. */
              <button
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                  selected
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal font-['Quicksand']  text-[#002642]"
                } 
                `}
              >
                Color Belt Athletes 
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={`flex justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1 py-4  text-center  outline-none transition-all ${
                    selected
                      ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                      : " outline-none  text-[32px] font-normal  text-[#002642]"
                  } 
                  `}
              >
                Black Belt Holder
              </button>
            )}
          </Tab>
          
        </Tab.List>
        <Tab.Panels>
        <Tab.Panel className="md:p-7 my-4 shadow-md  shadow-slate-600 max-md:p-0 md:w-[100%] ">
          <div className="flex md:hidden flex-col gap-3">
          {/* {
            colorBeltRegistredPlayersLoading? <span className="text-lg text-[#808080]">Loading.....</span>:
             <>
             
            {colorBeltRegistredPlayers?.length ? 
           <></>
          :
          <div className="h-[60vh] w-[100%]  flex justify-center items-center">

          <span className="text-center h-full flex justify-center items-center w-full text-xl text-[#7b8794]">
          No News Found
        </span>
          </div>
        }
          </>
            } */}
            </div>
        <div className="flex max-md:hidden flex-col justify-start items-start self-stretch flex-grow overflow-hidden ">
          <div className="flex flex-col  items-start self-stretch flex-grow min-h-[50vh] justify-between w-full">
            {
              colorBeltRegistredPlayersLoading? <span className="text-lg text-[#808080]">Loading.....</span>:
             <>
             
            {colorBeltRegistredPlayers?.length ? (
              <>
                  <div className="flex flex-col w-full">
                    <table>
                    <thead>
          <tr className="">
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
              Sr no
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
              OTA ID No.
            </th>
            
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3  ">
            Athlete Name
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-11 py-3 text-center w-0">
            Gender
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0">
            Club Name
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0 ">
            ID Card
            </th>
           
          </tr>
                    </thead>
                      <tbody className="">
                        {colorBeltRegistredPlayers?.map((category,i) => (
                           <tr   key={category.color_belt_id} className={`group/item  ${i%2===0?"bg-white":"bg-[#e7e7e7]"}    transition-all border-b border-black/5 cursor-pointer`}>    
                           <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">{i+1}</td>
                           <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">{category?.id_number}</td>
                          
                           <td className="   text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  leading-tight capitalize  text-center text-[#444444]">{category?.athlete_name}</td>
                           <td className=" text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap leading-tight capitalize p-4  text-[#444444] text-center">{category?.gender}</td>
                           <td className="  text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap text-center  leading-tight  capitalize text-[#444444] ">{category?.club_name}</td>
                           <td className="text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap leading-tight capitalize text-[#444444] items-center text-center">
                           <ColorBeltHolderIdCardPrint details={category} /></td>
                           </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
              </>
            ) : (
              <div className="flex justify-center items-center h-[10vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Registered Player Found
                </span>
              </div>
            )}
            </>
            }
            <div className="flex justify-center w-full p-4 mb-4">
              
            </div>
          </div>
        </div>
      
      </Tab.Panel>
      <Tab.Panel className="md:p-7 my-4 shadow-md  shadow-slate-600 max-md:p-0 md:w-[100%] ">
        <div className="flex max-md:hidden flex-col justify-start items-start self-stretch flex-grow overflow-hidden ">
          <div className="flex flex-col  items-start self-stretch flex-grow min-h-[50vh] justify-between w-full">
            {
              blackBeltRegistredPlayersLoading? <span className="text-lg text-[#808080]">Loading.....</span>:
             <>
             
            {blackBeltRegistredPlayers?.length ? (
              <>
                  <div className="flex flex-col w-full">
                    <table>
                    <thead>
          <tr className="">
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
              Sr no
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
              OTA ID No.
            </th>
            
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3  ">
            Athlete Name
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-11 py-3 text-center w-0">
            Gender
            </th>
            <th className="border-b  border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0">
            Dan Grade
            </th>
            <th className="border-b  border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0">
            Kukkiwon Dan Cert  No.
            </th>
            <th className="border-b  border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0">
            Issuance Date
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0 ">
            ID Card
            </th>
           
          </tr>
                    </thead>
                      <tbody className="">
                        {blackBeltRegistredPlayers?.map((player,i) => (
                           <tr   key={player.black_belt_id} className={`group/item  ${i%2===0?"bg-white":"bg-[#e7e7e7]"}    transition-all border-b border-black/5 cursor-pointer`}>    
                           <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">{i+1}</td>
                           <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">{player.ota_id_number} </td>
                          
                            <td className="   text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  leading-tight capitalize  text-center text-[#444444]">{player.athlete_name}</td>
                           <td className=" text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap leading-tight capitalize p-4  text-[#444444] text-center">{player.gender}</td>
                           <td className="  text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap text-center  leading-tight  capitalize text-[#444444] ">{player.dan_grade}</td>
                           <td className="  text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  text-center leading-tight  capitalize text-[#444444] ">{player.kukkiwn_cert_no}</td>
                           <td className="  text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  text-center leading-tight  capitalize text-[#444444] ">{player.issuance_date}</td>
                           <td className="text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap leading-tight capitalize text-[#444444] items-center text-center">
                           <BlackBeltHolderIdCardPrint details={player} /></td>
                           </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
              </>
            ) : (
              <div className="flex justify-center items-center h-[10vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Registered Players Found
                </span>
              </div>
            )}
            </>
            }
            <div className="flex justify-center w-full p-4 mb-4">
              
            </div>
          </div>
        </div>
      
      </Tab.Panel>
      
        </Tab.Panels>
      </Tab.Group>

        {selectedTab === 0 ? <>
 {totalcolorBeltRegistredPlayers>10 &&         <Link to={`/about/playerlist/${selectedTab}&${selectedDistrictRegistredPlayer}`} className='w-full cursor-pointer justify-center items-center gap-5 flex pr-7 text-2xl font-bold text-[#002642]'>
      <div className=' text-2xl font-bold text-sky-950 font-["Quicksand"]'>View More</div>
      <div className=''><RightNavigation/>
      </div>
        </Link>}
 </> : <>
{totalblackcolorBeltRegistredPlayers>10 &&        <Link to={`/about/playerlist/${selectedTab}&${selectedDistrictRegistredPlayer}`} className='w-full cursor-pointer justify-center items-center gap-5 flex pr-7 text-2xl font-bold text-[#002642]'>
      <div className=' text-2xl font-bold text-sky-950 font-["Quicksand"]'>View More</div>
      <div className=''><RightNavigation/>
      </div>
      </Link>}
      </>}

      {/* color belt promotion list */}
      <div className="py-9 mx-20 items-center flex flex-col gap-4 justify-center">
      <p className="text-[#002642] text-[50px] font-bold font-['Quicksand'] ">Color Belt Promotion Test List</p>
      
   <p className="text-[#444444] text-[30px] font-normal text-center font-['Quicksand'] ">Promotion test is the opportunity for Master and/or Instructors to assess how you are doing in learning the art sport of Taekwondo</p>
<div className=" border-2 border-[#002642] rounded-full" >
            <SelectBox setValue={setpromotionSelectedDistrict} />
    </div>
      </div>
      <div className="m-7">

      <div className="flex flex-col border-2 shadow-md  w-full rounded-md  ">
               {promotionTestList?.length ?    <table>
                    <thead>
          <tr className="">
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
              Sr no
            </th>
            
            
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3  ">
            Athlete Name
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
            Present Belt
            </th>
            
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0">
            Club Name
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-11 py-3 text-center w-0">
            Exam Date
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold px-6 py-3 text-center w-0 ">
            Certificate
            </th>
           
          </tr>
                    </thead>
                      <tbody className="">
                        {promotionTestList?.map((promotion,i) => (
                           <tr   key={promotion.id} className={`group/item  ${i%2===0?"bg-white":"bg-[#e7e7e7]"}    transition-all border-b border-black/5 cursor-pointer`}>    
                           <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">0{i+1}</td>
                           
                          
                            <td className="   text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  leading-tight capitalize  text-center text-[#444444]">{ promotion?.player_name}</td>
                           <td className="whitespace-nowrap text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">{promotion?.player_present_belt}</td>
                           
                            <td className="  text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  leading-tight  capitalize text-[#444444]  text-center">{ promotion?.ota_club_name}</td>
                           <td className=" text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap leading-tight capitalize p-4  text-[#444444] text-center">{promotion?.exam_date}</td>
                           <td className="text-primary  font-normal font-['Quicksand'] text-[24px] leading-tight capitalize text-[#444444] mx-auto my-auto items-center text-center">
                           <ColorBeltCertificate details={promotion} />
                           </td>
                           </tr>
                        ))}
                      </tbody>
                    </table>: <div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No promotion test available
                </span>
              </div>}
                  </div>
      </div>
 {   totalpromotionTestList>10 &&  <Link to={`/promotion-test-list/${promotionSelectedDistrict ? promotionSelectedDistrict:"all"}`} className='w-full cursor-pointer justify-center items-center gap-5 flex pr-7 text-2xl font-bold text-[#002642]'>
      <div className=' text-2xl font-bold text-sky-950 font-["Quicksand"]'>View More</div>
      <div className=''><RightNavigation/>
      </div>
        </Link>}
           {/* Medalist Profile */}
      <div className="p-5">

<div className="p-0 shadow-xl rounded-md border-2  bg-white">
  <div>
      <p className="text-[#002642] text-center text-[50px] font-bold font-['Quicksand'] ">Medalists Profile</p>
  </div>
  <div className="flex top-[15%]  justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-7  border-black/[0.08] border-b">
  <button onClick={()=>setmedalistTab(1)}
          className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
          ${
            medalistTab===1
              ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
              : " outline-none  text-[32px] font-normal  text-[#002642]"
          } 
          `}
        >
          International Medalists 
        </button>
  <button onClick={()=>setmedalistTab(2)}
          className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
          ${
            medalistTab===2
              ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
              : " outline-none  text-[32px] font-normal  text-[#002642]"
          } 
          `}
        >
          National Medalists
        </button>
  <button onClick={()=>setmedalistTab(3)}
          className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
          ${
            medalistTab===3
              ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
              : " outline-none  text-[32px] font-normal  text-[#002642]"
          } 
          `}
        >
          State Medalists
        </button>
  </div>
  {/* data */}
  <div className="mx-5">
{medaLists?.length ?  <Swiper
          slidesPerView={1}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            576: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1200: {
              slidesPerView: 4,
            },
            1500: {
              slidesPerView: 4,
            },
          }}
          centeredSlides={false}
          modules={[Pagination, Autoplay]}
          className="team-slider"
        >
         {
           medaLists?.map((medal,i) => (
              <SwiperSlide className="single-team" key={i}>
              <label
                   key={medal.otaPridesId}
                   className="flex flex-col relative justify-center items-center shadow-lg gap-1 rounded-lg p-6 group/item bg-white hover:bg-slate-100 transition-all border-b border-black/5 cursor-pointer"
                 >
                   {/* Add background image to the card */}
                   <div   className="absolute top-6 left-0 p-1 w-full h-full bg-cover bg-center rounded-lg">
        
                   <img
                   alt=""
                   src={ReactangleFrame}
                 />
                 </div>
                   <div   className="absolute top-14 left-2 w-full h-full bg-cover bg-center rounded-lg">
                   <img
                   alt=""
                   src={BackFrame}
                 />
                 </div>
                   <img src={medal?.image} alt="pattern_image" className="h-[200px] w-[200px] relative z-10" />
                   <span className="text-base font-semibold">{medal?.player_name}</span>
                   <span className="text-base font-semibold">{medal?.results}</span>
                 </label>
          </SwiperSlide>
          ))
         }
        </Swiper>: <div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No medalist available
                </span>
              </div>}

  </div>

  <div>

  </div>
</div>
</div>

      {/* event result */}
      <div>
      <div className="p-5">

      <div className="p-0 shadow-xl rounded-md border-2  bg-white">
        <div>
            <p className="text-[#002642] text-center text-[50px] font-bold font-['Quicksand'] ">Event Results</p>
        </div>
        <div className="flex top-[15%]  justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-7  border-black/[0.08] border-b">
        <button onClick={()=>seteventTab(1)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                  eventTab===1
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#002642]"
                } 
                `}
              >
                International Event 
              </button>
        <button onClick={()=>seteventTab(2)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                  eventTab===2
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#002642]"
                } 
                `}
              >
                National Event
              </button>
        <button onClick={()=>seteventTab(3)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                  eventTab===3
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#002642]"
                } 
                `}
              >
                State Event
              </button>
        </div>
        {/* data */}
        <div className="mx-5">
   {eventResults?.length ?           <>
        {
            eventResults.map((v,i)=>
        <div key={i} className="flex gap-5 py-4" onClick={()=>downloadImage(v?.card)}>
        <svg width="48" height="42" viewBox="0 0 48 42" fill="none" xmlns="http://www.w3.org/2000/svg" >
<path d="M44.9574 33.554H42.4631V36.033C42.4631 38.7719 40.2303 41.011 37.4544 41.011H9.99723C7.24146 41.011 5.00867 38.7919 5.00867 36.033V33.554H2.49428C1.10633 33.554 0 32.4345 0 31.075V16.1811C0 14.8017 1.12645 13.7021 2.49428 13.7021H4.98856V5.29587C4.98856 2.55699 7.24146 0.337891 9.99723 0.337891H29.9716H32.4659L42.4631 10.2738V12.7528V13.7021H44.9574C46.3453 13.7021 47.4517 14.8216 47.4517 16.1811V31.075C47.4718 32.4545 46.3453 33.554 44.9574 33.554ZM7.50295 36.033C7.50295 37.4124 8.6294 38.512 9.99723 38.512H37.4745C38.8625 38.512 39.9688 37.3924 39.9688 36.033V33.554H7.50295V36.033ZM29.9716 23.2582C29.9716 20.6793 29.0463 18.88 27.5779 17.7405C26.2101 16.6809 24.4801 16.1811 21.7847 16.1811C20.1755 16.1811 18.6468 16.301 17.46 16.481V30.8551C18.2646 30.9551 19.4312 31.075 21.0807 31.075C23.8365 31.075 26.1095 30.4753 27.598 29.1958C28.9859 27.9963 29.9716 26.0571 29.9716 23.2582ZM4.98856 16.481V31.075H8.10641V25.7772C8.38802 25.8172 8.77021 25.8372 9.17251 25.8372C11.0231 25.8372 12.6122 25.3573 13.6984 24.2778C14.5231 23.4381 14.9858 22.2186 14.9858 20.7592C14.9858 19.3198 14.3823 18.0803 13.4973 17.3406C12.572 16.5609 11.184 16.1611 9.25297 16.1611C7.32192 16.1811 5.9742 16.301 4.98856 16.481ZM39.9688 12.7528H34.9601C32.2245 12.7528 29.9716 10.5337 29.9716 7.79485V2.81688H9.99723C8.60929 2.81688 7.50295 3.93642 7.50295 5.29587V13.6821H39.9889L39.9688 12.7528ZM42.4631 16.1811H32.4659V31.075H36.1671V25.0775H42.0407V22.3386H36.1671V18.94H42.443V16.1811H42.4631ZM11.8679 20.8792C11.8679 22.3986 10.8421 23.2982 9.1524 23.2982C8.68975 23.2982 8.36791 23.2782 8.10641 23.2182V18.82C8.32768 18.76 8.77021 18.7001 9.41389 18.7001C10.9829 18.7001 11.8679 19.5197 11.8679 20.8792ZM20.6985 18.84C20.96 18.78 21.4226 18.7001 22.1468 18.7001C24.9026 18.7001 26.6124 20.2994 26.5922 23.3382C26.5922 26.8367 24.7014 28.4761 21.8048 28.4561C21.4025 28.4561 20.96 28.4561 20.6985 28.3961V18.84Z" fill="#002642"/>
          </svg>
          <p className="text-[32px] font-['Quicksand'] font-normal text-[#444444]">{v?.event_name}) [{v.year}]</p>
        </div>
            )
                }
                </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Events found
                </span>
              </div>}
        </div>

        <div>

        </div>
      </div>
      </div>
  {totalEventResult>10 &&    <Link to={`/event-list/${eventTab}`} className='w-full cursor-pointer justify-center items-center gap-5 flex pb-5 text-2xl font-bold text-[#002642]'>
      <div className=' text-2xl font-bold text-sky-950 font-["Quicksand"]'>View More</div>
      <div className=''><RightNavigation/>
      </div>
      </Link>}
      </div>

 {/* coaches profile */}
      <div>
      <p className="text-[#002642] text-center text-[50px] font-bold font-['Quicksand'] ">Coaches Profile</p>
      <div className="flex top-[15%]  justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-7  border-black/[0.08] border-b">
        <button onClick={()=>setcoachTab(0)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                    coachTab===0
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#002642]"
                } 
                `}
              >
                International Coach 
              </button>
        <button onClick={()=>setcoachTab(1)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                    coachTab===1
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#002642]"
                } 
                `}
              >
                National Coach
              </button>
        <button onClick={()=>setcoachTab(2)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                  coachTab===2
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#002642]"
                } 
                `}
              >
                State Coach
              </button>
        </div>

        <div className="p-7 ">
      {coachProfiles?.length ?      <>
            {
                coachProfiles.map((coach,i)=>
            <div key={i} className="flex items-center">

            <div className="h-52 w-52 border-[#fff] rounded-tl-[50%] rounded-bl-[50%] rounded-tr-[50%] rounded-br-[50%] border-4 border-l-[#002642] border-t-[#002642]  transform -rotate-45  ">
              <img alt="im" src={coach?.image} className="h-full transform rotate-45 w-full object-cover rounded-full bg-slate-500"/>
            </div>
            <div className="w-[80%]  flex gap-3 h-48 border-4 border-[#002642] border-l-0">
                <div className="ml-5 flex flex-col justify-around ">
                    <p className="text-[#000000]  text-[30px] font-bold font-['Quicksand'] ">Coach Name : </p>
                    {
                      coach?.grade&&
                    <p className="text-[#000000]  text-[30px] font-bold font-['Quicksand'] ">Grade : </p>
                    }
                    <p className="text-[#000000]  text-[30px] font-bold font-['Quicksand'] ">District : </p>
                </div>
                <div className="flex flex-col justify-around ">
                    <p className="text-[#1A1A1A]  text-[30px] font-medium font-['Quicksand'] ">{coach?.coach_name}</p>
                    {
                      coach?.grade&&
                        <p className="text-[#1A1A1A]  text-[30px] font-medium font-['Quicksand'] ">{ coach?.grade}</p>
                    }
                    <p className="text-[#1A1A1A]  text-[30px] font-medium font-['Quicksand'] ">{coach?.city_name}</p>
                </div>
            </div>

            </div>
                )
            }
            </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No coaches found
                </span>
              </div>}
        </div>
  {TotalCoachProfiles>2 &&      <Link to={`/coaches-list/${coachTab}`} className='w-full cursor-pointer justify-center items-center gap-5 flex pb-5 text-2xl font-bold text-[#002642]'>
      <div className=' text-2xl font-bold text-sky-950 font-["Quicksand"]'>View More</div>
      <div className=''><RightNavigation/>
      </div>
      </Link>}
      </div>

      {/* refree profile */}

      <div className="m-7 bg-blue-950 rounded-lg">
        <p className="text-[#fff] text-center text-[50px] font-bold font-['Quicksand'] py-2">Referee Profiles</p>
        <div className="flex top-[15%]   justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-7  border-white/[0.18] border-b">
        <button onClick={()=>setrefreeTab(0)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                    refreeTab===0
                    ? "text-[32px]  border-b-2 border-b-[#fff] font-['Quicksand'] text-[#fff] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#fff]"
                } 
                `}
              >
                International Referee 
              </button>
        <button onClick={()=>setrefreeTab(1)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                    refreeTab===1
                    ? "text-[32px]  border-b-2 border-b-[#fff] font-['Quicksand'] text-[#fff] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#fff]"
                } 
                `}
              >
                National Referee
              </button>
        <button onClick={()=>setrefreeTab(2)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                  refreeTab===2
                    ? "text-[32px]  border-b-2 border-b-[#fff] font-['Quicksand'] text-[#fff] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#fff]"
                } 
                `}
              >
                State Referee
              </button>
        </div>
        <div className="py-3 flex flex-col gap-3">
       {refreeProfiles?.length?     <>       
         {
            refreeProfiles?.map((refree)=>
        <div className="flex top-[15%]  py-8 justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-7  border-white/[0.18] border-b">
            <div className="h-44 items-center flex justify-center w-44 border-dashed border-2 rounded-lg">
               <img alt="refree" src={refree?.image} className="object-cover h-40 w-40 rounded-lg"/>
            </div>
            <div className="  flex gap-7  min-h-48 justify-center items-center border-l-0">
                <div className="ml-5 h-full  flex flex-col  justify-around ">
                    <p className="text-[#FFF]  text-[30px] font-bold font-['Quicksand'] ">Referee Name : </p>
                    {
                      refree?.event&&
                    <p className="text-[#FFF]  text-[30px] font-bold font-['Quicksand'] ">Event : </p>
                    }
                    <p className="text-[#FFF]  text-[30px] font-bold font-['Quicksand'] ">District : </p>
                    {
                      refree?.grade&&
                    <p className="text-[#FFF]  text-[30px] font-bold font-['Quicksand'] ">Grade : </p>
                    }
                </div>
                <div className="flex flex-col justify-around h-full">
                    <p className="text-[#E3E3E3]  text-[30px] font-medium font-['Quicksand'] ">{refree?.referee_name}</p>
                    {
                      refree?.event&&
                    <p className="text-[#E3E3E3]  text-[30px] font-medium font-['Quicksand'] ">{ refree?.event}</p>
                    }
                    <p className="text-[#E3E3E3]  text-[30px] font-medium font-['Quicksand'] ">{refree?.city_name}</p>
                    {
                      refree?.grade&&
                    <p className="text-[#E3E3E3]  text-[30px] font-medium font-['Quicksand'] ">{ refree?.grade}</p>
                    }
                </div>
            </div>
        </div>
            )
              }
        </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Referee found
                </span>
              </div>}
         {/* view more */}
{TotalRefreeProfiles>2 &&         <Link to={`/refree-list/${refreeTab}`} className='w-full cursor-pointer justify-center items-center gap-5 flex pb-5 text-2xl font-bold text-[#002642]'>
      <div className=' text-2xl font-bold text-white font-["Quicksand"]'>View More</div>
      <div className=''><svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="21.0651" cy="20.8288" r="20.7145" fill="white"/>
<path d="M18.7634 30.6104L27.2666 21.0444L18.7634 11.4783" stroke="#002642" stroke-width="2.1258" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

      </div>
      </Link>}

        </div>

      </div>

      {/* techinical commitieee */}
      <div>
      <p className="text-[#002642] text-center text-[50px] font-bold font-['Quicksand'] py-2">Technical Committee</p>
        <div className="flex top-[15%]   justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-7  border-black/[0.08] border-b">
        <button onClick={()=>setcommitteeTab(0)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                    committeeTab===0
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#002642]"
                } 
                `}
              >
                Kyorugi
              </button>
        <button onClick={()=>setcommitteeTab(1)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                    committeeTab===1
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#002642]"
                } 
                `}
              >
                Poomsae
              </button>
        </div>
        <div className="flex flex-col gap-3 px-7">
{technicalCommitiesList?.length>0 ?            <>
{
    technicalCommitiesList.map((commitee)=>
        <div className="bg-white my-3 rounded-lg shadow-lg py-2 gap-4 flex w-full">
            <div className="border-dashed h-52 w-[20%] rounded-r-full border-l-0 border-2 border-[#002642] ">
              <img alt="va" src={commitee?.image} className="h-full w-full rounded-r-full"/>.
            </div>
            <div>
            <div className="  flex gap-7 min-h-48 border-l-0">
                <div className="ml-5  flex flex-col justify-around ">
                    <p className="text-[#000000]  text-[30px] font-bold font-['Quicksand'] ">Name : </p>
                    <p className="text-[#000]  text-[30px] font-bold font-['Quicksand'] ">Post : </p>
                    <p className="text-[#000]  text-[30px] font-bold font-['Quicksand'] ">District : </p>
                </div>
                <div className="flex flex-col justify-around ">
                    <p className="text-[#1a1a1a]  text-[30px] font-medium capitalize font-['Quicksand'] ">{commitee?.name}</p>
                    <p className="text-[#1a1a1a]  text-[30px] font-medium capitalize font-['Quicksand'] ">{commitee?.post}</p>
                    <p className="text-[#1a1a1a]  text-[30px] font-medium capitalizefont-['Quicksand'] ">{commitee?.city_name}</p>
                </div>
            </div>
            </div>
        </div>
    )
              }
              </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Technical Committee found
                </span>
              </div>}
{TotaltechnicalCommitiesList>2 &&<Link to={`/technical-commitee-list/${committeeTab}`} className='w-full cursor-pointer justify-center items-center gap-5 flex pb-5 text-2xl font-bold text-[#002642]'>
      <div className=' text-2xl font-bold text-sky-950 font-["Quicksand"]'>View More</div>
      <div className=''><RightNavigation/>
      </div>
      </Link>}
        </div>

      </div>
   {/* Event Calendar */}
   <div>
      <p className="text-[#002642] text-center text-[50px] font-bold font-['Quicksand'] py-2">Event Calendar</p>
       
      <div className="flex top-[15%]   justify-start items-center self-stretch flex-grow-0 flex-shrink-0 gap-8 px-7  border-black/[0.08] border-b">
        <button onClick={()=>seteventCalendarTab(0)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                    eventCalendarTab===0
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#002642]"
                } 
                `}
              >
                International Calendar
              </button>
        <button onClick={()=>seteventCalendarTab(1)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                    eventCalendarTab===1
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#002642]"
                } 
                `}
              >
                National Calendar
              </button>
        <button onClick={()=>seteventCalendarTab(2)}
                className={`flex justify-start items-center  relative gap-1 py-4  text-center  outline-none transition-all 
                ${
                    eventCalendarTab===2
                    ? "text-[32px]  border-b-2 border-b-[#002642] font-['Quicksand'] text-[#002642] font-bold"
                    : " outline-none  text-[32px] font-normal  text-[#002642]"
                } 
                `}
              >
                State Calendar
              </button>
          </div>
        {calendarEventsList?.length ?  <div className="flex justify-between px-5 py-2">
            <div className="w-[60%]">
            <table className="w-full">
                    <thead>
          <tr className="">
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
              Sr no
            </th>
            
            
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3  ">
            Event Year
            </th>
            <th className="border-b whitespace-nowrap border-black/10 text-[28px] font-['Quicksand'] text-[#002642] font-bold  py-3 ">
           PDF File
            </th>
          </tr>
                    </thead>
                      <tbody className="">
                        {calendarEventsList?.map((promotion,i) => (
                           <tr   key={promotion.id} className={`group/item  ${i%2===0?"bg-white":"bg-[#e7e7e7]"}  transition-all border-b border-black/5 cursor-pointer`}>    
                           <td className="whitespace-nowrap py-2 text-primary font-normal font-['Quicksand'] text-[24px] leading-tight capitalize px-2 text-[#444444] text-center">0{i+1}</td>
                           
                          
                            <td className="   text-primary font-normal font-['Quicksand'] text-[24px] whitespace-nowrap  leading-tight capitalize  text-center text-[#444444]">{ promotion?.event_year}</td>
                           <td className="text-primary  font-normal font-['Quicksand'] text-[24px] leading-tight capitalize text-[#444444] mx-auto my-auto items-center text-center">
                           <DownloadIcon className="w-full flex justify-center" onClick={()=>downloadImage(promotion?.pdf_file)}/>
                           </td>
                           </tr>
                        ))}
                      </tbody>
                    </table>
            </div>
            <div className="w-[38%]">
              <img src={EventCalendarImage} alt="event_calendar_image" className="w-full h-[350px]" />
            </div>
          </div>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No Events found
                </span>
              </div>}
      </div>
      </div>
{/* mobile view */}
  <div className="md:hidden">
        <MobileView promotionSelectedDistrict={promotionSelectedDistrict} setpromotionSelectedDistrict={setpromotionSelectedDistrict} selectedDistrictRegistredPlayer={selectedDistrictRegistredPlayer} setselectedDistrictRegistredPlayer={ setselectedDistrictRegistredPlayer} playerTab={selectedTab} setplayerTab={setselectedTab} />
  </div>
    </Template>
  );
};

export default About;

import {createSlice} from "@reduxjs/toolkit"
const aboutSlice = createSlice({
    name: "about",
    initialState: {
        councilMembers: [],
        councilMemberLoading: false,
        totalCouncilMember: 0,
        colorBeltRegistredPlayers:[],
        colorBeltRegistredPlayersLoading:false,
        totalcolorBeltRegistredPlayers:0,
        blackBeltRegistredPlayers:[],
        blackBeltRegistredPlayersLoading:false,
        totalblackcolorBeltRegistredPlayers:0,
        promotionTestList:[],
        promotionTestListLoading:false,
        totalpromotionTestList: 0,
        eventResultLoading: false,
        eventResults: [],
        totalEventResult:0,
        MedalistsLoading: false,
        medaLists: [],
        totalMedalLists: 0,
        coachProfiles:[],
        coachProfilesLoading:false,
        TotalCoachProfiles:0,
        refreeProfiles:[],
        refreeProfilesLoading:false,
        TotalRefreeProfiles:0,
        technicalCommitiesList:[],
        technicalCommitiesListLoading:false,
        TotaltechnicalCommitiesList: 0,
        calendarEventsList:[],
        calendarEventsListLoading:false,
    },
    reducers: {
        getCouncilMembersRequest: (state) => {
            state.councilMemberLoading = true;
        },
        getCouncilMembersSuccess: (state,{payload}) => {
            state.councilMemberLoading = false;
            state.councilMembers = payload?.executive_council_member_data;
            state.totalCouncilMember = payload?.total_elements;
        },
        getCouncilMembersFail: (state) => {
            state.councilMemberLoading = false;
        },
        getColorBeltRegistredPlayerRequest: (state) => {
            state.colorBeltRegistredPlayersLoading = true;
        },
        getColorBeltRegistredPlayerSuccess: (state,{payload}) => {
            state.colorBeltRegistredPlayersLoading = false;
            state.colorBeltRegistredPlayers = payload?.color_belt_List
            state.totalcolorBeltRegistredPlayers = payload?.total_elements;
        },
        updateColorBeltRegistredPlayerSuccess: (state,{payload}) => {
            state.colorBeltRegistredPlayersLoading = false;
            state.colorBeltRegistredPlayers = [...state.colorBeltRegistredPlayers, ...payload?.color_belt_List];
            state.totalcolorBeltRegistredPlayers = payload?.total_elements
        },
        getColorBeltRegistredPlayerFail: (state) => {
            state.colorBeltRegistredPlayersLoading = false;
        },
        getBlackBeltRegistredPlayerRequest: (state) => {
            state.blackBeltRegistredPlayersLoading = true;
        },
        getBlackBeltRegistredPlayerSuccess: (state,{payload}) => {
            state.blackBeltRegistredPlayersLoading = false;
            state.blackBeltRegistredPlayers = payload?.black_belt_List
            state.totalblackcolorBeltRegistredPlayers = payload?.total_elements;
        },
        updateBlackBeltRegistredPlayerSuccess: (state,{payload}) => {
            state.blackBeltRegistredPlayersLoading = false;
            state.blackBeltRegistredPlayers = [...state.blackBeltRegistredPlayers,...payload?.black_belt_List]
            state.totalblackcolorBeltRegistredPlayers = payload?.total_elements;
        },
        getBlackBeltRegistredPlayerFail: (state) => {
            state.blackBeltRegistredPlayersLoading = false;
        },
        getPromotionTestListRequest: (state) => {
            state.promotionTestListLoading = true;
        },
        getPromotionTestListSuccess: (state,{payload}) => {
            state.promotionTestListLoading = false;
            state.promotionTestList = payload?.colour_belt_promotion_data
            state.totalpromotionTestList = payload?.total_elements;
        },
        updatePromotionTestListSuccess: (state,{payload}) => {
            state.promotionTestListLoading = false;
            state.promotionTestList = [...state.promotionTestList,...payload?.colour_belt_promotion_data]
            state.totalpromotionTestList = payload?.total_elements;
        },
        getPromotionTestListFail: (state) => {
            state.promotionTestListLoading = false;
        },
        getEventResultRequest: (state) => {
            state.eventResultLoading = true;
        },
        getEventResultSuccess: (state,{payload}) => {
            state.eventResultLoading = false;
            state.eventResults = payload?.event_result_List
            state.totalEventResult=payload?.total_elements
        },
        updateEventResultSuccess: (state,{payload}) => {
            state.eventResultLoading = false;
            state.eventResults = [...state.eventResults,...payload?.event_result_List]
            state.totalEventResult=payload?.total_elements
        },
        getEventResultFail: (state) => {
            state.eventResultLoading = false;
        },
        getMedalistRequest: (state) => {
            state.MedalistsLoading = true;
        },
        getMedalistSuccess: (state,{payload}) => {
            state.MedalistsLoading = false;
            state.medaLists = payload
        },
        getMedalListFail: (state) => {
            state.MedalistsLoading = false;
        },
        getCoachesProfilesRequest: (state) => {
            state.coachProfilesLoading = true;
        },
        getCoachesProfilesSuccess: (state,{payload}) => {
            state.coachProfilesLoading = false;
            state.coachProfiles = payload?.coach_profile_data
            state.TotalCoachProfiles=payload?.total_elements
        },
        updateCoachesProfilesSuccess: (state,{payload}) => {
            state.coachProfilesLoading = false;
            state.coachProfiles = [...state.coachProfiles,...payload?.coach_profile_data]
            state.TotalCoachProfiles=payload?.total_elements
        },
        getCoachesProfilesFail: (state) => {
            state.coachProfilesLoading = false;
        },
        getRefreeProfilesRequest: (state) => {
            state.refreeProfilesLoading = true;
        },
        getRefreeProfilesSuccess: (state,{payload}) => {
            state.refreeProfilesLoading = false;
            state.refreeProfiles = payload?.referee_profile_data
            state.TotalRefreeProfiles=payload?.total_elements
        },
        updateRefreeProfilesSuccess: (state,{payload}) => {
            state.refreeProfilesLoading = false;
            state.refreeProfiles = [...state.refreeProfiles,...payload?.referee_profile_data]
            state.TotalRefreeProfiles=payload?.total_elements
        },
        getRefreeProfilesFail: (state) => {
            state.refreeProfilesLoading = false;
        },
        getTechnicalCommitiesListRequest: (state) => {
            state.technicalCommitiesListLoading = true;
        },
        getTechnicalCommitiesListSuccess: (state,{payload}) => {
            state.technicalCommitiesListLoading = false;
            state.technicalCommitiesList = payload?.technical_committee_data
            state.TotaltechnicalCommitiesList=payload?.total_elements
        },
        updateTechnicalCommitiesListSuccess: (state,{payload}) => {
            state.technicalCommitiesListLoading = false;
            state.technicalCommitiesList = [...state.technicalCommitiesList,...payload?.technical_committee_data]
            state.TotaltechnicalCommitiesList=payload?.total_elements
        },
        getTechnicalCommitiesListFail: (state) => {
            state.technicalCommitiesListLoading = false;
        },
        getCalendarEventListRequest: (state) => {
            state.calendarEventsListLoading = true;
        },
        getCalendarEventListSuccess: (state,{payload}) => {
            state.calendarEventsListLoading = false;
            state.calendarEventsList = payload
        },
        getCalendarEventListFail: (state) => {
            state.calendarEventsListLoading = false;
        },
    }
})

export default aboutSlice.reducer;
export const { getCouncilMembersFail,getCouncilMembersRequest,getCouncilMembersSuccess,getBlackBeltRegistredPlayerFail,getBlackBeltRegistredPlayerRequest,getBlackBeltRegistredPlayerSuccess,getColorBeltRegistredPlayerFail,getColorBeltRegistredPlayerRequest,getColorBeltRegistredPlayerSuccess,getPromotionTestListFail,getPromotionTestListRequest,getPromotionTestListSuccess,getEventResultFail,getEventResultRequest,getEventResultSuccess,getMedalListFail,getMedalistRequest,getMedalistSuccess,getCoachesProfilesFail,getCoachesProfilesRequest,getCoachesProfilesSuccess,getRefreeProfilesFail,getRefreeProfilesRequest,getRefreeProfilesSuccess,getTechnicalCommitiesListFail,getTechnicalCommitiesListRequest,getTechnicalCommitiesListSuccess,getCalendarEventListFail,getCalendarEventListRequest,getCalendarEventListSuccess,updateBlackBeltRegistredPlayerSuccess,updateColorBeltRegistredPlayerSuccess,updatePromotionTestListSuccess,updateEventResultSuccess,updateCoachesProfilesSuccess,updateRefreeProfilesSuccess,updateTechnicalCommitiesListSuccess} = aboutSlice.actions;
import React, { useEffect, useRef } from 'react'
import Template from '../../components/Template'
import { useDispatch, useSelector } from 'react-redux'
import { getRegisterClub } from '../../service/api'
import { useParams } from 'react-router-dom'
import certificateImage from "../../assets/Taekwondo Images/Images/club_certificate_1279x904.jpeg"
const RegisterClub = () => {

   const {registerClubs} = useSelector(state=>state.registerClub)
  const dispatch = useDispatch()
 const router=  useParams()
   useEffect(()=>{
      if(router?.city_id){

         dispatch(getRegisterClub(router?.city_id?.split("&")[0]))
      }
   },[dispatch,router])
  return (
    <Template>
      
      <div className='p-7 max-md:hidden'>
         <p className='font-bold  text-[50px] py-3 font-["Quicksand"] text-center text-[#002642]'>OTA Registered Club</p>
         <p className='font-bold text-[40px] py-3 font-["Quicksand"] text-center text-[#002642]'>District - {router?.city_id?.split("&")[1]}</p>

         {/* detatils card */}
         {
            registerClubs?.length?
         <div className=''>

         {
          registerClubs?.map((club,i)=>
         <div key={i} className='h-[495px] max-md:min-h-[495px] my-7 w-full border-[3px] border-[#002642] rounded-[20px]'>
          <div className='p-5 h-full'>
            <div className='flex max-md:flex-col h-full w-full  justify-between'>

            <img alt='' src={club?.logo} className='bg-black rounded-md w-[49%] max-md:h-[200px] max-md:w-full h-full'/>
            <div className='flex flex-col rounded-md max-md:  md:justify-between max-md:w-full w-[49%]'>
         <p className='font-bold text-[33px]  font-["Quicksand"] max-md:text-[24px]  text-[#002642]'>{club?.club_name}</p>
         <div>
            <div className='flex items-center gap-2'>
              <div><svg width="22" height="24" viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.5931 0.837721C10.356 0.859446 10.0011 0.906516 9.83275 0.937293C7.32353 1.41343 5.33752 3.38315 4.84509 5.88331C4.75457 6.34315 4.73828 6.53686 4.73828 7.10895C4.73828 7.66474 4.75457 7.85303 4.83785 8.28571C5.24338 10.3894 6.69533 12.1491 8.67409 12.9348C9.51955 13.2697 10.3686 13.411 11.2992 13.3711C14.1506 13.2462 16.5765 11.1733 17.1468 8.37623C17.2482 7.87475 17.2626 7.71181 17.2626 7.0999C17.2626 6.51514 17.25 6.36488 17.1631 5.91409C16.8788 4.44766 16.0859 3.13331 14.9164 2.20095C13.9985 1.46774 12.9213 1.01876 11.7337 0.870308C11.5182 0.843152 10.7651 0.823237 10.5931 0.837721Z" fill="#002642"/>
<path d="M6.0395 15.1417C4.15306 15.2159 2.41327 16.1248 1.2709 17.6328C0.678901 18.4149 0.264319 19.3618 0.101382 20.3068C0.0144832 20.8101 0.0144832 20.801 0.00724161 22.4376L0 24H11H22L21.9928 22.4376C21.9855 20.7793 21.9855 20.7902 21.8914 20.267C21.6252 18.8096 20.8232 17.4663 19.6537 16.5249C19.0165 16.0125 18.2995 15.6341 17.5265 15.4024C16.8313 15.1942 16.2936 15.129 15.2436 15.129H14.7077L14.6697 15.234C14.6154 15.3807 14.4651 15.6251 14.3402 15.7699C13.8188 16.3728 12.9245 16.7349 11.6698 16.8489C11.3892 16.8743 10.6108 16.8743 10.3302 16.8489C8.8945 16.7186 7.91689 16.2569 7.46248 15.4947C7.41178 15.4078 7.35204 15.2902 7.33032 15.234L7.2923 15.129L6.72926 15.1327C6.41968 15.1345 6.11011 15.1381 6.0395 15.1417Z" fill="#002642"/>
</svg>

</div>
         <p className='font-bold text-[30px]  font-["Quicksand"]  text-[#002642]'>Coach Name</p>


            </div>
            <p className='font-normal text-[24px]  font-["Quicksand"]  text-[#444444]'>{club?.coach_name}</p>
         </div>
                         <div className="w-full flex gap-4">
                            <div className='flex flex-col gap-4 justify-start w-[50%]'>
                            {/* Contact */}
                            <div>
           <div className='flex items-center gap-2'>
              <div>
              <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.00293568 5.514C0.13589 4.84353 0.218002 4.163 0.411567 3.52046C1.14966 1.06207 3.02568 -0.264348 5.27515 0.0440695C5.37443 0.06482 5.46917 0.107772 5.55389 0.170479C5.63861 0.233185 5.71165 0.314401 5.76884 0.409465C6.68127 2.12289 7.58068 3.84527 8.46704 5.57658C8.77205 6.1733 8.68796 6.90635 8.21089 7.47625C7.83061 7.92323 7.37603 8.29981 6.95469 8.70545C6.73179 8.92224 6.49619 9.12449 6.28991 9.35916C5.99663 9.69439 5.94873 10.071 6.16673 10.518C7.75143 13.7586 9.90215 16.3511 12.8095 18.0809C13.1937 18.3044 13.5007 18.291 13.7715 17.9513C14.144 17.4864 14.4705 16.9735 14.8391 16.4986C15.0804 16.173 15.3518 15.8781 15.6485 15.6191C15.8699 15.4234 16.1382 15.3095 16.4176 15.2925C16.6971 15.2755 16.9744 15.3563 17.2127 15.5241C18.5139 16.3656 19.8043 17.2271 21.1045 18.0708C21.6275 18.4061 21.8524 18.9648 22 19.5894V20.3717C21.9707 20.4767 21.9335 20.5784 21.912 20.6845C21.5884 22.3395 20.7751 23.5441 19.3908 24.19C17.4776 25.084 15.5097 25.2873 13.5252 24.5509C10.9052 23.5765 8.62931 21.8903 6.57439 19.8308C4.10007 17.3523 2.04612 14.4804 0.851489 10.9314C0.448716 9.73462 0.277639 8.43613 0 7.18458L0.00293568 5.514Z" fill="#002642"/>
</svg>

</div>
         <p className='font-bold text-[30px]  font-["Quicksand"]  text-[#002642]'>Contact</p>


            </div>
            <p className='font-normal text-[24px]  font-["Quicksand"]  text-[#444444]'>{club?.contact_no}</p>
                               </div>
                               {/* Training Time */}
                               <div>
           <div className='flex items-center gap-2'>
              <div>
              <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5 0C6.5414 0 0.0649567 6.47667 0.000514478 14.4033C-0.0317066 18.27 1.45046 21.9433 4.18926 24.6822C6.89583 27.4533 10.5368 28.9678 14.4034 29H14.5C22.4586 29 28.9351 22.5233 28.9995 14.5967C29.064 6.60556 22.5875 0.0644444 14.5 0ZM21.3309 15.9822H14.3711C13.8556 15.9822 13.4367 15.5633 13.4367 15.0478V5.76778C13.4367 5.25222 13.8556 4.83333 14.3711 4.83333C14.8867 4.83333 15.3055 5.25222 15.3055 5.76778V14.1133H21.3309C21.8464 14.1133 22.2653 14.5322 22.2653 15.0478C22.2653 15.5633 21.8464 15.9822 21.3309 15.9822Z" fill="#002642"/>
</svg>


</div>
         <p className='font-bold text-[30px]  font-["Quicksand"]  text-[#002642]'>Training Time</p>


            </div>
            <p className='font-normal text-[24px]  font-["Quicksand"]  text-[#444444]'>{club?.from_training_time}</p>
            <p className='font-normal text-[24px]  font-["Quicksand"]  text-[#444444]'>{club?.to_training_time}</p>
           </div>
                            </div>
                            <div className='flex flex-col gap-4 justify-start w-[50%]'>
                               {/* Training Place */}
                               <div>
           <div className='flex items-center gap-2'>
              <div>
              <svg width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.50323 27C9.45271 26.9673 9.43275 26.9167 9.40455 26.8736C8.97194 26.2121 8.53987 25.5501 8.10835 24.8875C6.40499 22.2639 4.71127 19.6339 3.06007 16.9785C2.41655 15.9431 1.79136 14.8971 1.16711 13.8499C0.750549 13.1502 0.473782 12.4031 0.277133 11.6215C0.0470543 10.7111 -0.0405777 9.7713 0.0172809 8.83475C0.132404 6.96371 0.748903 5.2671 1.89121 3.7681C3.20863 2.03924 4.92719 0.888992 7.04687 0.317346C7.58709 0.173293 8.13958 0.078572 8.69737 0.0343822C9.16777 -0.0058099 9.64058 -0.0107724 10.1117 0.019538C12.0221 0.147567 13.7482 0.775187 15.2672 1.92791C16.9705 3.22057 18.1069 4.89252 18.6762 6.94377C18.8213 7.47226 18.9175 8.01264 18.9638 8.55828C19.0072 9.05137 19.0116 9.54705 18.977 10.0408C18.9275 10.7411 18.7977 11.4336 18.5902 12.105C18.5296 12.3024 18.4669 12.4993 18.3988 12.6944C18.3043 12.9621 18.1864 13.2211 18.0463 13.4686C17.5201 14.4052 16.9632 15.3241 16.3998 16.2388C14.9345 18.619 13.4271 20.9731 11.9093 23.321C11.1479 24.499 10.3833 25.6749 9.6153 26.8488C9.58194 26.8979 9.55492 26.9548 9.50323 27ZM13.6808 9.38467C13.6907 7.10403 11.7984 5.2562 9.51263 5.24947C7.23224 5.24321 5.32847 7.07319 5.31978 9.3617C5.31085 11.6453 7.20123 13.4948 9.48185 13.505C11.7796 13.515 13.6827 11.6616 13.6808 9.38467Z" fill="#002642"/>
</svg>


</div>
         <p className='font-bold text-[30px]  font-["Quicksand"]  text-[#002642]'>Training Place</p>


            </div>
            <p className='font-normal text-[20px]  font-["Quicksand"]  text-[#444444]'>{club?.training_place}</p>
                               </div>
                               {/* Validity Period */}
                               <div>
           <div className='flex items-center gap-2'>
              <div>
              <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M26 3.75V5H0V3.75C0 2.65 0.9 1.75 2 1.75H24C25.1 1.75 26 2.65 26 3.75Z" fill="#002642"/>
<path d="M3 3.5C2.5859 3.5 2.25 3.1641 2.25 2.75V0.75C2.25 0.3359 2.5859 0 3 0C3.4141 0 3.75 0.3359 3.75 0.75V2.75C3.75 3.1641 3.4141 3.5 3 3.5Z" fill="#002642"/>
<path d="M8 3.5C7.5859 3.5 7.25 3.1641 7.25 2.75V0.75C7.25 0.3359 7.5859 0 8 0C8.4141 0 8.75 0.3359 8.75 0.75V2.75C8.75 3.1641 8.4141 3.5 8 3.5Z" fill="#002642"/>
<path d="M13 3.5C12.5859 3.5 12.25 3.1641 12.25 2.75V0.75C12.25 0.3359 12.5859 0 13 0C13.4141 0 13.75 0.3359 13.75 0.75V2.75C13.75 3.1641 13.4141 3.5 13 3.5Z" fill="#002642"/>
<path d="M18 3.5C17.5859 3.5 17.25 3.1641 17.25 2.75V0.75C17.25 0.3359 17.5859 0 18 0C18.4141 0 18.75 0.3359 18.75 0.75V2.75C18.75 3.1641 18.4141 3.5 18 3.5Z" fill="#002642"/>
<path d="M23 3.5C22.5859 3.5 22.25 3.1641 22.25 2.75V0.75C22.25 0.3359 22.5859 0 23 0C23.4141 0 23.75 0.3359 23.75 0.75V2.75C23.75 3.1641 23.4141 3.5 23 3.5Z" fill="#002642"/>
<path d="M13 11.8701C10.1 11.8701 7.75 14.2301 7.75 17.1301C7.75 20.0201 10.1 22.3701 13 22.3701C15.9 22.3701 18.25 20.0201 18.25 17.1301C18.25 14.2301 15.9 11.8701 13 11.8701ZM14.71 17.8701H13C12.59 17.8701 12.25 17.5401 12.25 17.1301V14.5501C12.25 14.1401 12.59 13.8001 13 13.8001C13.41 13.8001 13.75 14.1401 13.75 14.5501V16.3701H14.71C15.13 16.3701 15.46 16.7101 15.46 17.1301C15.46 17.5401 15.13 17.8701 14.71 17.8701Z" fill="#002642"/>
<path d="M0 6.5V25.75C0 26.85 0.9 27.75 2 27.75H24C25.1 27.75 26 26.85 26 25.75V6.5H0ZM13 23.87C9.28 23.87 6.25 20.85 6.25 17.13C6.25 13.4 9.28 10.37 13 10.37C16.72 10.37 19.75 13.4 19.75 17.13C19.75 20.85 16.72 23.87 13 23.87Z" fill="#002642"/>
</svg>



</div>
         <p className='font-bold text-[30px]  font-["Quicksand"]  text-[#002642]'>Validity Period</p>


            </div>
            <p className='font-normal text-[24px]  font-["Quicksand"]  text-[#444444]'>{club?.from_validity} to {club?.to_validity}</p>
           </div>
                            </div>
</div>


         {/* certificate */}
         <div className='flex gap-3 items-center'>
            <p className='text-[30px] text-[#002642] font-bold font-["Quicksand"] '>Certificate :</p>

            {/* print button */}
            <PrintCertificate details={club} />
           </div>
            </div>
            </div>

          </div>

         </div>
          )
         }
         </div>
         :
         <div className='text-center font-medium font-["Quicksand"] text-[30px] py-11 mx-auto'>
            No Data Available
         </div>
         }
         {/* view more */}
         

      </div>
      
      {/* mobile view */}
      <div className='md:hidden'>
         <div className='p-5'>
         <p className='font-bold  text-[30px] py-3 font-["Quicksand"] text-center text-[#002642]'>OTA Registered Club</p>
         <p className='font-bold text-[24px] py-0 font-["Quicksand"] text-center text-[#002642]'>District - {router?.city_id?.split("&")[1]}</p>
         {
            registerClubs?.length?
         <>
         {
            registerClubs?.map((v,i)=>
            <MobileView club={v} key={i} />
            )
         }
         </>:
         
         <div className='text-center font-medium font-["Quicksand"] text-[24px] py-11 mx-auto'>
             No Data Available
         </div>
         }
         </div>
      </div>
    </Template>
  )
}

export default RegisterClub

const MobileView=({club})=>{
   return(
      <div className='py-2'>
      <div className='min-h-[389px]  my-5 w-full border-[1px] border-[#002642] rounded-[20px]'>
          <div className='p-4 h-full'>
            <div className='flex max-md:flex-col h-full w-full  gap-4'>
              <div className='relative h-[200px] rounded-md max-md:w-full'>
                <img alt='' src={club?.logo} className='bg-black rounded-md  h-full max-md:w-full '/>

         {/* <p className='font-bold text-[20px] absolute bottom-2 left-0 w-full text-center  font-["Quicksand"]   text-[#EEEEEE]'>{club?.club_name}</p> */}
              </div>
            <div className='flex flex-col  rounded-md gap-2 max-md:w-full '>
         <div>
         <p className='font-bold text-[20px] w-full text-center  font-["Quicksand"]   text-[#002642]'>{club?.club_name}</p>

            <div className='flex items-center gap-2'>
              <div><svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.51719 0.498226C8.32649 0.515694 8.04118 0.553541 7.90581 0.578287C5.88826 0.961127 4.29139 2.54489 3.89545 4.55516C3.82267 4.9249 3.80957 5.08065 3.80957 5.54064C3.80957 5.98753 3.82267 6.13892 3.88963 6.48683C4.2157 8.17831 5.38314 9.59321 6.97418 10.225C7.65398 10.4943 8.33668 10.6078 9.08489 10.5758C11.3776 10.4753 13.3282 8.80861 13.7867 6.55961C13.8682 6.15639 13.8799 6.02538 13.8799 5.53337C13.8799 5.06319 13.8697 4.94237 13.7998 4.57991C13.5712 3.40082 12.9337 2.34401 11.9933 1.59434C11.2553 1.0048 10.3892 0.643792 9.43425 0.524428C9.26103 0.502593 8.65547 0.486581 8.51719 0.498226Z" fill="#002642"/>
<path d="M4.85609 11.999C3.33929 12.0587 1.9404 12.7894 1.02188 14.002C0.545874 14.6309 0.212527 15.3922 0.0815171 16.152C0.0116453 16.5567 0.0116453 16.5494 0.00582265 17.8653L0 19.1216H8.84461H17.6892L17.6834 17.8653C17.6776 16.532 17.6776 16.5407 17.6019 16.12C17.3879 14.9482 16.743 13.8681 15.8027 13.1111C15.2903 12.6992 14.7138 12.395 14.0923 12.2086C13.5333 12.0412 13.101 11.9888 12.2567 11.9888H11.8258L11.7952 12.0733C11.7516 12.1912 11.6308 12.3877 11.5303 12.5041C11.1111 12.9889 10.392 13.28 9.38321 13.3717C9.15758 13.3921 8.53164 13.3921 8.30602 13.3717C7.15167 13.2669 6.36562 12.8957 6.00024 12.2829C5.95949 12.213 5.91145 12.1184 5.89398 12.0733L5.86341 11.9888L5.4107 11.9917C5.16178 11.9932 4.91286 11.9961 4.85609 11.999Z" fill="#002642"/>
</svg>


</div>
         <p className='font-bold text-[24px]  font-["Quicksand"]  text-[#002642]'>Coach Name</p>


            </div>
            <p className='font-normal text-[18px]  font-["Quicksand"]  text-[#444444]'>{club?.coach_name}</p>
         </div>

         {/* contact and training place */}
         <div className='flex max-md:flex-col w-full gap-2 md:justify-between '>
           <div>
           <div className='flex items-center gap-2'>
              <div>
              <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.00236045 5.08737C0.109263 4.54827 0.175286 4.00109 0.330923 3.48446C0.924389 1.50777 2.43281 0.441258 4.24151 0.689243C4.32134 0.705927 4.39752 0.740463 4.46564 0.790883C4.53376 0.841303 4.59249 0.906604 4.63847 0.983041C5.37211 2.36073 6.09528 3.74562 6.80797 5.13769C7.05321 5.61749 6.98561 6.2069 6.60201 6.66513C6.29624 7.02453 5.93074 7.32732 5.59195 7.65347C5.41274 7.82778 5.22329 7.99041 5.05744 8.17909C4.82162 8.44864 4.78311 8.75143 4.9584 9.11083C6.23258 11.7165 7.96188 13.801 10.2996 15.1918C10.6085 15.3715 10.8553 15.3608 11.0731 15.0876C11.3725 14.7138 11.6351 14.3014 11.9314 13.9196C12.1255 13.6578 12.3437 13.4207 12.5823 13.2125C12.7603 13.0551 12.976 12.9635 13.2007 12.9498C13.4254 12.9362 13.6484 13.0012 13.8399 13.1361C14.8862 13.8126 15.9238 14.5054 16.9692 15.1838C17.3897 15.4533 17.5705 15.9025 17.6892 16.4048V17.0337C17.6656 17.1182 17.6358 17.2 17.6185 17.2853C17.3583 18.616 16.7043 19.5846 15.5913 20.1039C14.053 20.8227 12.4707 20.9862 10.875 20.3941C8.76837 19.6106 6.93844 18.2548 5.28617 16.5989C3.29668 14.606 1.64519 12.2969 0.684645 9.44327C0.360793 8.48098 0.223238 7.43693 0 6.43061L0.00236045 5.08737Z" fill="#002642"/>
</svg>


</div>
         <p className='font-bold text-[24px]  font-["Quicksand"]  text-[#002642]'>Contact</p>


            </div>
            <p className='font-normal text-[18px]  font-["Quicksand"]  text-[#444444]'>{club?.contact_no}</p>
           </div>
           <div>
           <div className='flex items-center gap-2'>
              <div>
              <svg width="16" height="23" viewBox="0 0 16 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.64112 22.4086C7.60051 22.3823 7.58445 22.3416 7.56178 22.307C7.21394 21.7751 6.86653 21.2428 6.51957 20.71C5.14997 18.6005 3.78812 16.4859 2.46046 14.3507C1.94304 13.5183 1.44035 12.6772 0.93842 11.8352C0.603483 11.2726 0.380947 10.6719 0.22283 10.0434C0.0378343 9.3114 -0.0326267 8.55576 0.0138948 7.80272C0.106461 6.29831 0.60216 4.93414 1.52064 3.72886C2.57992 2.33876 3.96173 1.4139 5.66607 0.954261C6.10044 0.838434 6.54467 0.762273 6.99316 0.726742C7.37139 0.694425 7.75156 0.690435 8.1304 0.714806C9.66642 0.817749 11.0543 1.32239 12.2756 2.24924C13.6452 3.28861 14.5589 4.63296 15.0167 6.28227C15.1334 6.70721 15.2108 7.1417 15.2479 7.58043C15.2828 7.9769 15.2864 8.37545 15.2585 8.77247C15.2187 9.33554 15.1144 9.89235 14.9476 10.4322C14.8988 10.5909 14.8484 10.7493 14.7936 10.9061C14.7177 11.1213 14.6229 11.3296 14.5103 11.5286C14.0871 12.2816 13.6394 13.0205 13.1864 13.756C12.0081 15.6698 10.7961 17.5627 9.57575 19.4505C8.96355 20.3976 8.34872 21.3431 7.73123 22.287C7.70441 22.3265 7.68268 22.3722 7.64112 22.4086ZM11.0001 8.24489C11.0081 6.41114 9.48658 4.92537 7.64868 4.91997C5.81512 4.91493 4.28439 6.38633 4.2774 8.22643C4.27022 10.0626 5.79019 11.5497 7.62393 11.5579C9.47147 11.5659 11.0016 10.0757 11.0001 8.24489Z" fill="#002642"/>
</svg>



</div>
         <p className='font-bold text-[24px]  font-["Quicksand"]  text-[#002642]'>Training Place</p>


            </div>
            <p className='font-normal text-[18px]  font-["Quicksand"]  text-[#444444]'>{club?.training_place}</p>
           </div>
           
         </div>

         {/* time and validty */}
         <div className=' flex flex-col gap-2'>
           <div>
           <div className='flex items-center gap-2'>
              <div>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.6588 0.54895C5.25965 0.54895 0.0522288 5.75655 0.000413669 12.13C-0.0254939 15.239 1.16625 18.1926 3.3684 20.3948C5.54463 22.623 8.47219 23.8407 11.5811 23.8666H11.6588C18.058 23.8666 23.2654 18.659 23.3172 12.2855C23.369 5.86018 18.1616 0.600767 11.6588 0.54895ZM17.1512 13.3995H11.5552C11.1407 13.3995 10.8039 13.0627 10.8039 12.6482V5.18656C10.8039 4.77203 11.1407 4.43522 11.5552 4.43522C11.9697 4.43522 12.3065 4.77203 12.3065 5.18656V11.8969H17.1512C17.5657 11.8969 17.9025 12.2337 17.9025 12.6482C17.9025 13.0627 17.5657 13.3995 17.1512 13.3995Z" fill="#002642"/>
</svg>



</div>
         <p className='font-bold text-[24px]  font-["Quicksand"]  text-[#002642]'>Training Time</p>


            </div>
            <p className='font-normal text-[16px]  font-["Quicksand"]  text-[#444444]'>{club?.from_training_time} To {club?.to_training_time}</p>
            {/* <p className='font-normal text-[24px]  font-["Quicksand"]  text-[#444444]'>5:00 PM To 7:00 PM</p> */}
           </div>
           <div>
           <div className='flex items-center gap-2'>
              <div>
              <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M20.9054 3.15865V4.16372H0V3.15865C0 2.27419 0.72365 1.55054 1.60811 1.55054H19.2973C20.1818 1.55054 20.9054 2.27419 20.9054 3.15865Z" fill="#002642"/>
<path d="M2.41164 2.95799C2.07868 2.95799 1.80859 2.68791 1.80859 2.35495V0.74684C1.80859 0.413881 2.07868 0.143799 2.41164 0.143799C2.74459 0.143799 3.01468 0.413881 3.01468 0.74684V2.35495C3.01468 2.68791 2.74459 2.95799 2.41164 2.95799Z" fill="#002642"/>
<path d="M6.43214 2.95799C6.09918 2.95799 5.8291 2.68791 5.8291 2.35495V0.74684C5.8291 0.413881 6.09918 0.143799 6.43214 0.143799C6.7651 0.143799 7.03518 0.413881 7.03518 0.74684V2.35495C7.03518 2.68791 6.7651 2.95799 6.43214 2.95799Z" fill="#002642"/>
<path d="M10.4527 2.95799C10.1197 2.95799 9.84961 2.68791 9.84961 2.35495V0.74684C9.84961 0.413881 10.1197 0.143799 10.4527 0.143799C10.7856 0.143799 11.0557 0.413881 11.0557 0.74684V2.35495C11.0557 2.68791 10.7856 2.95799 10.4527 2.95799Z" fill="#002642"/>
<path d="M14.4732 2.95799C14.1402 2.95799 13.8701 2.68791 13.8701 2.35495V0.74684C13.8701 0.413881 14.1402 0.143799 14.4732 0.143799C14.8061 0.143799 15.0762 0.413881 15.0762 0.74684V2.35495C15.0762 2.68791 14.8061 2.95799 14.4732 2.95799Z" fill="#002642"/>
<path d="M18.4927 2.95799C18.1597 2.95799 17.8896 2.68791 17.8896 2.35495V0.74684C17.8896 0.413881 18.1597 0.143799 18.4927 0.143799C18.8256 0.143799 19.0957 0.413881 19.0957 0.74684V2.35495C19.0957 2.68791 18.8256 2.95799 18.4927 2.95799Z" fill="#002642"/>
<path d="M10.4527 9.68799C8.12098 9.68799 6.23145 11.5856 6.23145 13.9173C6.23145 16.241 8.12098 18.1306 10.4527 18.1306C12.7845 18.1306 14.674 16.241 14.674 13.9173C14.674 11.5856 12.7845 9.68799 10.4527 9.68799ZM11.8277 14.5123H10.4527C10.1231 14.5123 9.8497 14.247 9.8497 13.9173V11.8429C9.8497 11.5132 10.1231 11.2398 10.4527 11.2398C10.7824 11.2398 11.0558 11.5132 11.0558 11.8429V13.3062H11.8277C12.1654 13.3062 12.4307 13.5796 12.4307 13.9173C12.4307 14.247 12.1654 14.5123 11.8277 14.5123Z" fill="#002642"/>
<path d="M0 5.37024V20.8483C0 21.7328 0.72365 22.4564 1.60811 22.4564H19.2973C20.1818 22.4564 20.9054 21.7328 20.9054 20.8483V5.37024H0ZM10.4527 19.3367C7.46164 19.3367 5.02535 16.9084 5.02535 13.9173C5.02535 10.9182 7.46164 8.48193 10.4527 8.48193C13.4438 8.48193 15.8801 10.9182 15.8801 13.9173C15.8801 16.9084 13.4438 19.3367 10.4527 19.3367Z" fill="#002642"/>
</svg>




</div>
         <p className='font-bold text-[24px]  font-["Quicksand"]  text-[#002642]'>Validity Period</p>


            </div>
            <p className='font-normal text-[18px]  font-["Quicksand"]  text-[#444444]'>{club?.from_validity} To {club?.to_validity}</p>
           </div>
           <div className='flex gap-3 items-center'>
            <p className='text-[22px] text-[#002642] font-bold font-["Quicksand"] '>Certificate :</p>

            {/* print button */}
            <PrintCertificate details={club}/>
           </div>
           
         </div>
            </div>
            </div>

          </div>

         </div>
      </div>
   )
}


const PrintCertificate = ({ details }) => {
   const canvasRef3 = useRef();
   const handleDownload3 = (district,regd_id,regd_date,club_name,club_head_name,coach_name,address,mobile,registartion_period) => {
      const canvas = canvasRef3.current;
      const context = canvas.getContext('2d');
    
      // Load your certificate image - Replace with the actual path or URL
      const backgroundImage = new window.Image();
      backgroundImage.onload = async () => {
        canvas.width = backgroundImage.width;
        canvas.height = backgroundImage.height;
        // Draw the background image on the canvas
        context.drawImage(backgroundImage, 0, 0);
        //Club Name
        context.fillStyle = "blue";
        context.font = `bold 28px Arial`; // Set font size for each text
        context.fillText(club_name?.toUpperCase(), 395, 265);
        //Regd No
        context.fillStyle = "blue";
        context.font = `bold 18px Arial`; // Set font size for each text
        context.fillText(regd_id, 186, 330);
        context.fontWeight="700"
        //District Name
        context.fillStyle = "blue";
        context.font = `bold 40px Arial`; // Set font size for each text
        context.fillText(district?.toUpperCase(), 615, 325);
         //Regd Date
        context.fillStyle = "blue";
        context.font = `bold 20px Arial`; // Set font size for each text
        context.fillText(regd_date, 1125, 320);
         //Club Name
        context.fillStyle = "blue";
        context.font = `bold 28px Arial`; // Set font size for each text
        context.fillText(club_name?.toUpperCase(), 340, 415);
         //Club Head Name
        context.fillStyle = "blue";
        context.font = `bold 28px Arial`; // Set font size for each text
        context.fillText(club_head_name?.toUpperCase(), 340, 465);
         //Coach Name
        context.fillStyle = "blue";
        context.font = `bold 28px Arial`; // Set font size for each text
        context.fillText(coach_name?.toUpperCase(), 340, 515);
         //Address
        context.fillStyle = "blue";
        context.font = `bold 23px Arial`; // Set font size for each text
        context.fillText(address?.toUpperCase(), 340, 565);
         //Mobile
        context.fillStyle = "blue";
        context.font = `bold 23px Arial`; // Set font size for each text
        context.fillText(mobile, 340, 615);
         //Registration Period
        context.fillStyle = "#8B0000";
        context.font = `23px Arial`; // Set font size for each text
        context.fillText(registartion_period, 340, 665);
           // Trigger download
           const link = document.createElement('a');
            link.href = canvas.toDataURL('image/png');
          link.download = `${club_name}-registration-certificate.png`;
          link.click();
      };
    
      // Replace this path with the actual path or URL to your background image
      backgroundImage.src = certificateImage;
   }
   const handleDownloadCertificate = () => {
   handleDownload3(details?.city_name,details?.reg_no,details?.from_validity,details?.club_name,details?.head_name,details?.coach_name,details?.training_place,details?.contact_no,`${details?.from_validity} To ${details?.from_validity}`)
   }
   return (
      <>
      <div className='max-md:hidden'>

<button className='items-center p-0 rounded-[90px] flex justify-center bg-gradient-to-b from-[#06416D] to-[#013256]' onClick={handleDownloadCertificate}>
               <div className='gap-4 items-center pl-4 pr-2 py-2  flex   '>

            <p className='text-[30px] text-white font-bold font-["Quicksand"] '>Print</p>
             <div className='h-9  w-9 flex items-center justify-center bg-white rounded-full'>
             <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8002 1.80873C13.8002 1.20706 13.3119 0.71875 12.7102 0.71875H3.99037C3.3887 0.71875 2.90039 1.20706 2.90039 1.80873V2.89871H13.8002V1.80873Z" fill="#002642"/>
<path d="M14.8895 3.98853H1.80971C1.20804 3.98853 0.719727 4.47684 0.719727 5.07851V11.6184C0.719727 12.2201 1.20804 12.7084 1.80971 12.7084H2.89969V14.8883C2.89969 15.49 3.388 15.9783 3.98967 15.9783H12.7095C13.3112 15.9783 13.7995 15.49 13.7995 14.8883V12.7084H14.8895C15.4911 12.7084 15.9794 12.2201 15.9794 11.6184V5.07851C15.9794 4.47684 15.4911 3.98853 14.8895 3.98853ZM12.7095 14.8883H3.98967V9.43842H12.7095V14.8883ZM13.7995 7.25846H11.6195V6.16848H13.7995V7.25846Z" fill="#002642"/>
<path d="M8.35002 12.7084H5.08008V13.7984H8.35002V12.7084Z" fill="#002642"/>
<path d="M11.62 10.5282H5.08008V11.6182H11.62V10.5282Z" fill="#002642"/>
</svg>
             </div>
               </div>
         </button>
      </div>
      <div className='md:hidden'>
      <button onClick={handleDownloadCertificate} className='items-center p-0 rounded-[90px] flex justify-center bg-gradient-to-b from-[#06416D] to-[#013256]'>
               <div className='gap-4 items-center pl-4 pr-2 py-1  flex   '>

            <p className='text-[22px] text-white font-bold font-["Quicksand"] '>Print</p>
             <div className='h-7  w-7 flex items-center justify-center bg-white rounded-full'>
             <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8002 1.80873C13.8002 1.20706 13.3119 0.71875 12.7102 0.71875H3.99037C3.3887 0.71875 2.90039 1.20706 2.90039 1.80873V2.89871H13.8002V1.80873Z" fill="#002642"/>
<path d="M14.8895 3.98853H1.80971C1.20804 3.98853 0.719727 4.47684 0.719727 5.07851V11.6184C0.719727 12.2201 1.20804 12.7084 1.80971 12.7084H2.89969V14.8883C2.89969 15.49 3.388 15.9783 3.98967 15.9783H12.7095C13.3112 15.9783 13.7995 15.49 13.7995 14.8883V12.7084H14.8895C15.4911 12.7084 15.9794 12.2201 15.9794 11.6184V5.07851C15.9794 4.47684 15.4911 3.98853 14.8895 3.98853ZM12.7095 14.8883H3.98967V9.43842H12.7095V14.8883ZM13.7995 7.25846H11.6195V6.16848H13.7995V7.25846Z" fill="#002642"/>
<path d="M8.35002 12.7084H5.08008V13.7984H8.35002V12.7084Z" fill="#002642"/>
<path d="M11.62 10.5282H5.08008V11.6182H11.62V10.5282Z" fill="#002642"/>
</svg>


             </div>
               </div>
            </button>
      </div>
         <canvas ref={canvasRef3} style={{ display:"none" }} />
      </>
   )
}
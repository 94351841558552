import React, { useEffect, useState } from 'react'
import Template from '../../components/Template'
import { getCoachProfiles, updateCoachProfiles } from '../../service/apiService'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroll-component'

const CoachesList = () => {
    const { status } = useParams();
    const dispatch = useDispatch();
    const { councilMembers, colorBeltRegistredPlayers, blackBeltRegistredPlayers, colorBeltRegistredPlayersLoading, blackBeltRegistredPlayersLoading, promotionTestList, promotionTestListLoading, totalpromotionTestList, totalCouncilMember, totalcolorBeltRegistredPlayers, totalblackcolorBeltRegistredPlayers, medaLists, eventResults, totalEventResult, coachProfiles, TotalCoachProfiles, refreeProfiles, TotalRefreeProfiles, technicalCommitiesList, TotaltechnicalCommitiesList, calendarEventsList, coachProfilesLoading } = useSelector((state) => state.about)
    const [page, setpage] = useState(1)
    useEffect(() => {
        dispatch(getCoachProfiles(1,5,status))
      },[status])

      const loadMore = async () => {
          if (coachProfiles?.length < TotalCoachProfiles && !coachProfilesLoading) {
                dispatch(updateCoachProfiles(page + 1,5,status))
              setpage(page+1)
          }
        }
  return (
      <Template >
          <p className="text-[50px] max-md:text-[34px] text-center py-5   font-['Quicksand'] text-[#002642] font-bold">{status==="0"?"International ":status==="1"?"National ":"State " }Coaches List</p>
          <div className="md:p-7 my-4  max-md:p-0 md:w-[100%] ">
              <div className="flex md:hidden flex-col gap-3 px-3">
                  {coachProfiles?.length ? <>
                    <InfiniteScroll
                dataLength={coachProfiles?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden"}}
                next={loadMore}
                hasMore={true}
                loader={coachProfilesLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                      >
                          
            {
                coachProfiles?.map((coach,i)=>
            <div key={i} className="flex justify-between w-full items-center">
           <div className='w-[19%]'>

            <div className="h-32 w-32 border-[#fff] rounded-tl-[50%] rounded-bl-[50%] rounded-tr-[50%] rounded-br-[50%] border-4 border-l-[#002642] border-t-[#002642]  transform -rotate-45  ">
              <img alt="im" src={coach?.image} className="h-full transform rotate-45 w-full object-cover rounded-full bg-slate-500"/>
            </div>
           </div>
            <div className="w-[65%]  flex gap-2  h-28 border-4 border-[#002642] border-l-0">
                <div className="ml-5 flex flex-col justify-around ">
                    <p className="text-[#000000]  text-[10px] font-bold font-['Quicksand'] ">Coach Name : </p>
                    <p className="text-[#000000]  text-[10px] font-bold font-['Quicksand'] ">Grade : </p>
                    <p className="text-[#000000]  text-[10px] font-bold font-['Quicksand'] ">District : </p>
                </div>
                <div className="flex flex-col justify-around ">
                    <p className="text-[#1A1A1A]  text-[10px] font-medium font-['Quicksand'] ">{coach?.coach_name}</p>
                        <p className="text-[#1A1A1A]  text-[10px] font-medium font-['Quicksand'] ">{ coach?.grade}</p>
                    <p className="text-[#1A1A1A]  text-[10px] font-medium font-['Quicksand'] ">{coach?.city_name}</p>
                </div>
            </div>

            </div>
                )
                          }
                          </InfiniteScroll>
                          
            </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No coaches found
                </span>
              </div>}
              </div>
              <div className="flex max-md:hidden flex-col justify-start items-start self-stretch flex-grow overflow-hidden w-full">
                  <div className="flex flex-col  items-start self-stretch flex-grow min-h-[50vh] justify-between w-full">
                      {coachProfiles?.length ? <>
                          <InfiniteScroll
                            
                dataLength={coachProfiles?.length} //This is important field to render the next data
                style={{scrollbarColor:"transparent",height:"100%",overflow:"hidden",width:"100%"}}
                next={loadMore}
                hasMore={true}
                loader={coachProfilesLoading&&<h4>Loading...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Yay! You have seen it all</b>
                  </p>
                 }
                 
                        >
                              
            {
                coachProfiles.map((coach)=>
            <div className="flex items-center w-full">

            <div className="h-52 w-52 border-[#fff] rounded-tl-[50%] rounded-bl-[50%] rounded-tr-[50%] rounded-br-[50%] border-4 border-l-[#002642] border-t-[#002642]  transform -rotate-45  ">
              <img alt="im" src={coach?.image} className="h-full transform rotate-45 w-full object-cover rounded-full bg-slate-500"/>
            </div>
            <div className="w-[80%]  flex gap-3 h-48 border-4 border-[#002642] border-l-0">
                <div className="ml-5 flex flex-col justify-around ">
                    <p className="text-[#000000]  text-[30px] font-bold font-['Quicksand'] ">Coach Name : </p>
                    <p className="text-[#000000]  text-[30px] font-bold font-['Quicksand'] ">Grade : </p>
                    <p className="text-[#000000]  text-[30px] font-bold font-['Quicksand'] ">District : </p>
                </div>
                <div className="flex flex-col justify-around ">
                    <p className="text-[#1A1A1A]  text-[30px] font-medium font-['Quicksand'] ">{coach?.coach_name}</p>
                        <p className="text-[#1A1A1A]  text-[30px] font-medium font-['Quicksand'] ">{ coach?.grade}</p>
                    <p className="text-[#1A1A1A]  text-[30px] font-medium font-['Quicksand'] ">{coach?.city_name}</p>
                </div>
            </div>

            </div>
                )
                          }
                      </InfiniteScroll>
                          
            </>:<div className="flex justify-center items-center h-[20vh] w-full">
                <span className="text-center w-full text-xl text-[#7b8794]">
                  No coaches found
                </span>
              </div>}
        </div>
        </div>
        </div>
    </Template>
  )
}

export default CoachesList
import { createSlice } from "@reduxjs/toolkit"

const homeSlice = createSlice({
  name: "home",
  initialState: {
    prideList: [],
    prideListLoading: false,
    latestInfos: [],
    latestInfoLoading: false,
    galleryList: [],
    galleryListLoading: false,
    ownerInfoLoading: false,
    ownerInfo: {},
    districtAssociationListLoading: false,
    districtAssociationList: [],
    formList: [],
    formListLoading: false,
    districtList: [],
    districtListLoading: false,
    contactList: [],
    contactListLoading: false,
    videoList: [],
    videoListLoading: false,
    dtaList: [],
    dtaListLoading: false,
    newsList: [],
    newsListLoading: false,
    visitor:0

  },
  reducers: {
    getPrideListLoading: (state) => {
      state.prideListLoading = true
    },
    getPrideListSuccess: (state, { payload }) => {
      state.prideListLoading = false
      state.prideList = payload
    },
    getPrideListFail: (state) => {
      state.prideListLoading = false
    },
    getLatestInfoLoading: (state) => {
      state.latestInfoLoading = true
    },
    getLatestInfoSuccess: (state, { payload }) => {
      state.latestInfoLoading = false
      state.latestInfos = payload
    },
    getLatestInfoFail: (state) => {
      state.latestInfoLoading = false
    },
    getGalleryListLoading: (state, { payload }) => {
      state.galleryListLoading = true
    },
    getGalleryListSuccess: (state, { payload }) => {
      state.galleryListLoading = false
      state.galleryList = payload
    },
    getGalleryListFail: (state, { payload }) => {
      state.galleryListLoading = false
    },
    getOwnerInfoLoading: (state, { payload }) => {
      state.ownerInfoLoading = true
    },
    getOwnerInfoSuccess: (state, { payload }) => {
      state.ownerInfoLoading = false
      state.ownerInfo = payload
    },
    getOwnerInfoFail: (state, { payload }) => {
      state.ownerInfoLoading = false
    },
    getDistrictAssociationListLoading: (state, { payload }) => {
      state.districtAssociationListLoading = true
    },
    getDistrictAssociationListSuccess: (state, { payload }) => {
      state.districtAssociationListLoading = false
      state.districtAssociationList = payload
    },
    getDistrictAssociationListFail: (state, { payload }) => {
      state.districtAssociationListLoading = false
    },
    getFormListLoading: (state, { payload }) => {
      state.formListLoading = true
    },
    getFormListSuccess: (state, { payload }) => {
      state.formListLoading = false
      state.formList = payload
    },
    getFormListFail: (state, { payload }) => {
      state.formListLoading = false
    },
    getDistrictListLoading: (state, { payload }) => {
      state.districtListLoading = true
    },
    getDistrictListSuccess: (state, { payload }) => {
      state.districtListLoading = false
      state.districtList = payload?.sort((a, b) => a.city_name.localeCompare(b.city_name, undefined, { sensitivity: 'base' }))
    },
    getDistrictListFail: (state, { payload }) => {
      state.districtListLoading = false
    },
    getContactListLoading: (state, { payload }) => {
      state.contactListLoading = true
    },
    getContactListSuccess: (state, { payload }) => {
      state.contactListLoading = false
      state.contactList = payload
    },
    getContactListFail: (state, { payload }) => {
      state.contactListLoading = false
    },
    getVideoListLoading: (state, { payload }) => {
      state.videoListLoading = true
    },
    getVideoListSuccess: (state, { payload }) => {
      state.videoListLoading = false
      state.videoList = payload
    },
    getVideoListFail: (state, { payload }) => {
      state.videoListLoading = false
    },
    getDtaListLoading: (state, { payload }) => {
      state.dtaListLoading = true
    },
    getDtaListSuccess: (state, { payload }) => {
      state.dtaListLoading = false
      state.dtaList = payload
    },
    getDtaListFail: (state, { payload }) => {
      state.dtaListLoading = false
    },
    getNewsListLoading: (state, { payload }) => {
      state.newsListLoading = true
    },
    getNewsListSuccess: (state, { payload }) => {
      state.newsListLoading = false
      state.newsList = payload
    },
    getNewsListFail: (state, { payload }) => {
      state.newsListLoading = false
    },
    getVisitiorsSuccess:(state,{payload})=>{
      state.visitor=payload
    }
  }
})

export default homeSlice.reducer;
export const { getPrideListFail,getVisitiorsSuccess, getNewsListFail, getNewsListLoading, getNewsListSuccess, getPrideListLoading, getPrideListSuccess, getLatestInfoFail, getLatestInfoLoading, getLatestInfoSuccess, getGalleryListFail, getGalleryListLoading, getGalleryListSuccess, getOwnerInfoFail, getOwnerInfoLoading, getOwnerInfoSuccess, getDistrictAssociationListFail, getDistrictAssociationListLoading, getDistrictAssociationListSuccess, getFormListFail, getFormListLoading, getFormListSuccess, getDistrictListFail, getDistrictListLoading, getDistrictListSuccess, getContactListFail, getContactListLoading, getContactListSuccess, getVideoListFail, getVideoListLoading, getVideoListSuccess, getDtaListFail, getDtaListLoading, getDtaListSuccess } = homeSlice.actions
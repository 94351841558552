import {configureStore} from "@reduxjs/toolkit"
import homeSlice from "./slices/homeSlice"
import registerClubSlice from "./slices/registerClubSlice"
import aboutSlice from "./slices/aboutSlice"

export const store= configureStore({
    reducer:{
        home:homeSlice,
    registerClub: registerClubSlice,
        about:aboutSlice
    },
    middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})
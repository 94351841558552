import React, { useEffect } from 'react'
import {ReactComponent as Facebook} from "../../assets/icons/facebook_icon.svg"
import {ReactComponent as Instagram} from "../../assets/icons/instagram_icon.svg"
import {ReactComponent as X} from "../../assets/icons/x_icon.svg"
import Logo from "../../assets/Taekwondo Images/Images/Logo.png"
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getVisitors } from '../../service/api'

const Footer = () => {
  const {visitor} = useSelector(state=>state.home)
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(getVisitors())
  },[])
  return (
    <div >
      <div className="px-16 max-md:hidden py-10 rounded-t-3xl   bg-[#002642] max-md:px-5">
    <div className="flex gap-5 max-md:flex-col max-md:gap-0 max-md:items-stretch">
      <div className="flex flex-col items-stretch w-[27%] max-md:ml-0 max-md:w-full">
        <div className="flex flex-col grow items-start max-md:mt-10">
        <div className='w-40 h-28'>
           <img alt='' src={Logo} className='w-full h-full object-cover'/>
          </div>
          <div className="self-stretch mt-7 text-lg font-['Quicksand'] text-zinc-100">
          Taekwondo is a Korean martial art emphasizing kicks, punches, and blocks. It promotes self-defense, discipline, and physical fitness through sparring and forms training.
          </div>
          <div className='flex gap-3 justify-center py-7'>
          <Link to={`https://www.facebook.com/odishaota`} target='_blank'>      
            <Facebook/>
                </Link>
                <Link to={`https://twitter.com/otaodisha`} target='_blank'>
            <X/>
                </Link>
                <Link to={`https://www.instagram.com/odishataekwondoassociation`} target='_blank'>
            <Instagram/>
                </Link>

          </div></div>
      </div>
      <div>
        <div className='flex gap-24'>
      {/* left side 1 */}
      <div>

      <div className='w-[240px] h-[171px] border-b-[.5px] border-zinc-400'>
       <p className='text-[24px] text-white font-medium'>Head Office Address</p>
       <p className='text-[16px] mt-9 font-["Quicksand"] text-zinc-400 '>Kalpana Sagar Complex Flat- C-103, Sipasarubali ,Baliapanda ,  Post- Puri, PIN- 752001 , Odisha </p>
      </div>
      <p className='text-[24px] font-normal text-white text-center py-3 font-["Quicksand"]'>Total Visitors :  {visitor}</p>

      </div>
      {/* left side 2 */}
      <div>
        <div className='w-[240px] h-[171px] border-b-[.5px] border-zinc-400'>
       <p className='text-[24px] font-["Quicksand"] text-white font-medium'>Get in Touch</p>
         <div className='mt-8 flex flex-col gap-2'>
          <p className='text-[18px] font-["Quicksand"] text-white'>EMAIL SUPPORT</p>
          <p className='text-[18px] font-["Quicksand"] text-zinc-400 '>info@odishataekwondoassociation.com</p>
         </div>
        </div>
        <p className='text-lg font-["Quicksand"] text-white mt-5'>Contact No.</p>
        <p className='text-lg font-["Quicksand"] text-zinc-400'>9338449446 / 7008130771</p>

      </div>
      {/* left side 3 */}
      <div>
        <div className='w-[240px] h-[171px] border-b-[.5px] border-zinc-400'>
       <p className='text-[24px] font-["Quicksand"] text-white font-medium'>Working Hours</p>
         <div className='mt-8 flex flex-col gap-2'>
          <p className='text-[18px] font-["Quicksand"] text-white'>MONDAY - SATURDAY</p>
          <p className='text-[18px] font-["Quicksand"] text-zinc-400 '>09 AM - 09 PM</p>
         </div>
        </div>
        <p className='text-lg font-["Quicksand"] text-white mt-5'>MONDAY - SATURDAY</p>
        <p className='text-lg font-["Quicksand"] text-zinc-400'>09 AM - 09 PM </p>

      </div>


        </div>
      </div>
    </div>
  </div>
  <div className='md:hidden'>
  <div className='rounded-t-[20px] bg-sky-950 w-full'>
      <div className='px-3 py-7'>

        <div className='flex flex-col items-center justify-center'>
          <div className='w-28 h-24'>
           <img alt='' src={Logo}/>
          </div>
          <p className='text-[14px] text-center font-["Quicksand"] text-[#F2F2F2]'>Taekwondo is a Korean martial art emphasizing kicks, punches, and blocks. It promotes self-defense, discipline, and physical fitness through sparring and forms training.</p>
          <div className='flex gap-3 justify-center py-7'>
                <Link to={`https://www.facebook.com/odishaota`} target='_blank'>      
            <Facebook/>
                </Link>
                <Link to={`https://twitter.com/otaodisha`} target='_blank'>
            <X/>
                </Link>
                <Link to={`https://www.instagram.com/odishataekwondoassociation`} target='_blank'>
            <Instagram/>
                </Link>

          </div>
          <div className='flex flex-col w-full gap-5'>
            <p className='text-xl text-center font-["Quicksand"] text-white'>Head Office Address</p>
            <div className='items-center flex flex-col justify-center'>
              <p className='text-lg text-[#F2F2F2] font-["Quicksand"]'>Kalpana Sagar Complex </p>
              <p className='text-lg text-[#F2F2F2] font-["Quicksand"]'> Flat- C-103, Sipasarubali ,Baliapanda  </p>
              <p className='text-lg text-[#F2F2F2] font-["Quicksand"]'>  Post/Dist- Puri,  </p>
              <p className='text-lg text-[#F2F2F2] font-["Quicksand"]'>PIN- 752001 , Odisha</p>
            </div>
            <div className='w-full h-[1px] bg-[#fff]'></div>
          </div>
         
        </div>
         {/* timing */}
         <div>

         <div className='flex justify-between py-7'>
           <div className='flex flex-col gap-5'>
            <p className='text-[20px] text-white font-medium font-["Quicksand"]'>Get in Touch</p>
            <div className='flex flex-col gap-5'>

            <div >
              <p className='text-white text-base font-["Quicksand"]'>EMAIL SUPPORT</p>
              <p className='text-[#f2f2f2] text-[12px] font-normal font-["Quicksand"]'>info@odishataekwondoassociation.com</p>
            </div>
            <div >
              <p className='text-white text-base font-["Quicksand"]'>Contact No.</p>
              <p className='text-[#f2f2f2] text-[12px] font-normal font-["Quicksand"]'>9338449446 / 7008130771</p>
            </div>
            
            </div>
            
           </div>
           {/* WORKING HOUR */}
           <div className='flex flex-col gap-5'>
            <p className='text-[20px] text-white font-medium font-["Quicksand"]'>Working Hours</p>
            <div className='flex flex-col gap-5'>

            <div >
              <p className='text-white text-base'>MONDAY - SATURDAY</p>
              <p className='text-[#f2f2f2] text-[12px] font-normal font-["Quicksand"]'>09 AM - 09 PM </p>
            </div>
            <div >
              <p className='text-white text-base font-["Quicksand"]'>MONDAY - SATURDAY</p>
              <p className='text-[#f2f2f2] text-[12px] font-normal font-["Quicksand"]'>09 AM - 09 PM</p>
            </div>
            
            </div>
            
           </div>

          </div>
          <div className='w-full h-[1px] bg-[#FFF]'></div>

         </div>
         <p className='text-[24px] font-normal text-white text-center py-3 font-["Quicksand"]'>Total Visitors :  {visitor}</p>
      </div>
    </div>
  </div>
  </div>
  )
}

export default Footer